import React, { Component } from "react";
import { Label, Icon, Transition } from "semantic-ui-react";

export class Notification extends Component {
  state = {
    visible: false
  };

  componentDidMount() {
    this.setTimer();
    this.setState({ visible: true });
  }

  componentWillUnmount() {
    this.setState({ visible: false });
    clearTimeout(this._timer);
  }

  setTimer = () => {
    if (this._timer != null) {
      clearTimeout(this._timer);
    }
    this._timer = setTimeout(
      function() {
        this.props.hide();
        this._timer = null;
      }.bind(this),
      3000
    );
  };

  render() {
    return (
      <Transition
        visible={this.state.visible}
        animation="fade"
        duration={500}
        unmountOnHide
      >
        <Label
          style={{
            position: "absolute",
            top: "17.25rem",
            left: "15%",
            padding: ".7rem",
            boxShadow: ".2rem .2rem 1rem rgba(0,0,0,0.3)"
          }}
          color="red"
        >
          <Icon name="envelope outline" /> New Updates
        </Label>
      </Transition>
    );
  }
}

export default Notification;
