import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  Comment,
  Header,
  Button,
  Loader,
  Accordion,
  Icon,
  Label,
  Embed,
  Checkbox,
  Message
} from "semantic-ui-react";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";
import withContext from "../Context/withContext";
import Moment from "react-moment";
import { colors } from "../css-config";

import AddModerators from "./AddModerators";
import OriginEventInfo from "./OriginEventInfo";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import Notification from "./Notification";

const audio = new Audio("https://assets.online-days.com/audio/pop.mp3");

export class Chat extends Component {
  state = {
    input: "",
    loading: true,
    activeIndex: 1,
    event: {},
    messages: [],
    reply: null,
    replyText: "",
    stream: "",
    streamInput: "",
    editStream: false,
    allowMessages: false,
    newMessage: false,
    autoScroll: true,
    moderators: [],
    active: false
  };

  chatRef = React.createRef();

  async componentDidMount() {
    //this.setState({loading: true})

    await this.fetchMessages();
    if (this.props.match.params.chatId !== "zula") {
      this.createMessageListener = API.graphql(
        graphqlOperation(subscriptions.ON_CREATE_MESSAGE, {
          data: this.state.event.entityId
        })
      ).subscribe({
        next: data => {
          audio.play();
          this.setState({ newMessage: true });
          this.fetchMessages();
        }
      });
      this.createStreamListener = API.graphql(
        graphqlOperation(subscriptions.ON_UPDATE_STREAM)
      ).subscribe({
        next: stream => {
          this.fetchMessages();
        }
      });
      this.createEventUpdateListener = API.graphql(
        graphqlOperation(subscriptions.ON_EVENT_ACTIVE_TOGGLE)
      ).subscribe({
        next: data => {
          this.setState({
            active: data.value.data.onUpdateEventActivation.active
          });
        }
      });
    } else {
      // this.createFlagListener = API.graphql(
      //   graphqlOperation(subscriptions.ON_FLAG_MESSAGE)
      // ).subscribe({
      //   next: data => {
      //     this.fetchMessages();
      //   }
      // });
    }
  }

  async componentWillUnmount() {
    if (this.props.match.params.chatId !== "zula") {
      this.createMessageListener.unsubscribe();
      this.createStreamListener.unsubscribe();

      this.createEventUpdateListener.unsubscribe();
    } else {
      // this.createFlagListener.unsubscribe();
    }
  }

  scrollToBottom = () => {
    if (this.chatRef.current !== null) {
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    }
  };

  fetchMessages = async () => {
    if (this.props.match.params.chatId !== "zula") {
      let response = await API.graphql(
        graphqlOperation(queries.GET_EVENT_BY_ID, {
          entityId: this.props.match.params.chatId
        })
      );

      const moderators =
        response.data.getEventById.moderators !== null
          ? await Promise.all(
              response.data.getEventById.moderators.map(mod => {
                return API.graphql(
                  graphqlOperation(queries.GET_USER_BY_ID, {
                    entityId: mod.entityId
                  })
                );
              })
            )
          : [];
      let sortedMessages = response.data.getEventById.messages.sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });
      if (response.data.getEventById.type === "Stream") {
        let allowMessages =
          response.data.getEventById.comments === null
            ? false
            : response.data.getEventById.comments;
        this.setState({
          event: response.data.getEventById,
          messages: response.data.getEventById.messages,
          loading: false,
          stream: response.data.getEventById.stream
            ? response.data.getEventById.stream
            : "ywf6RJuoqWM",
          streamInput: response.data.getEventById.stream
            ? response.data.getEventById.stream
            : "ywf6RJuoqWM",
          allowMessages,
          moderators,
          active: response.data.getEventById.active
        });
      } else {
        this.setState({
          event: response.data.getEventById,
          messages: response.data.getEventById.messages,
          loading: false,
          moderators,
          active: response.data.getEventById.active
        });
      }
    } else {
      let response = await API.graphql(
        graphqlOperation(queries.GET_FLAGGED_MESSAGES)
      );
      this.setState({ event: { department: { id: "zula" } } });
      this.setState({
        messages: response.data.getFlaggedMessages,
        loading: false
      });
    }
    if (this.state.autoScroll && this.state.reply === null) {
      this.scrollToBottom();
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  };

  postMessage = async () => {
    let input = {
      body: this.state.input,
      authorId: this.props.state.user.username,
      reply: "no"
    };
    let eventId = this.state.event.entityId;
    let response = await API.graphql(
      graphqlOperation(mutations.CREATE_MESSAGE, { eventId, input })
    );
    this.setState({ input: "" });
  };

  deleteMessage = async (e, message) => {
    if (message.reply !== "no" && message.reply !== null) {
      let deletedReply = await API.graphql(
        graphqlOperation(mutations.DELETE_MESSAGE, { entityId: message.reply })
      );
      let deletedMessage = await API.graphql(
        graphqlOperation(mutations.DELETE_MESSAGE, {
          entityId: message.entityId
        })
      );
    } else {
      let response = await API.graphql(
        graphqlOperation(mutations.DELETE_MESSAGE, {
          entityId: message.entityId
        })
      );
    }
  };

  setReplyState = async (e, messageId) => {
    if (this.state.reply !== null) {
      this.setState({ reply: null });
    } else {
      this.setState({ reply: messageId });
      let response = await API.graphql(
        graphqlOperation(mutations.CHANGE_REPLY_STATUS, {
          entityId: messageId,
          replied: true
        })
      );
    }
  };

  postReply = async (e, message) => {
    let input = {
      body: this.state.replyText,
      authorId:
        this.props.match.params.chatId !== "zula"
          ? this.props.state.user.username
          : "zula",
      reply: "no"
    };
    let reply = await API.graphql(
      graphqlOperation(mutations.CREATE_REPLY, {
        messageId: message.entityId,
        input
      })
    );

    /*
      I need to write to the copied message a reference from the reply, so that I can delete it together later
      Also, I need to sort the replies
    */

    //let messageBefore = `User ${message.authorId.substring(0,6)} wrote: ` + "\n" + message.body + "\n" + "-----------" + "\n" + "\n"
    let copyInput = {
      body: this.state.replyText,
      authorId:
        this.props.match.params.chatId !== "zula"
          ? this.props.state.user.username
          : "zula",
      reply: reply.data.createReply.entityId
    };
    let eventId =
      this.props.match.params.chatId !== "zula"
        ? this.state.event.entityId
        : message.data;

    let copyMessage = await API.graphql(
      graphqlOperation(mutations.CREATE_MESSAGE, { eventId, input: copyInput })
    );
    this.setState({ reply: null, replyText: "" });
  };

  deleteReply = async (e, reply) => {
    let response = await API.graphql(
      graphqlOperation(mutations.DELETE_MESSAGE, { entityId: reply.entityId })
    );
    window.location.reload();
  };

  flagMessage = async (e, message) => {
    let response = await API.graphql(
      graphqlOperation(mutations.FLAG_MESSAGE, {
        entityId: message.entityId,
        flag: !message.flag
      })
    );
  };

  addLike = async message => {
    let { createdAt, body, authorId, reply } = message;
    let input = {
      createdAt,
      body,
      authorId,
      reply
    };

    let response = await API.graphql(
      graphqlOperation(mutations.ADD_MESSAGE_LIKE, {
        userId: this.props.state.user.username,
        input
      })
    );

    alert("Message has been added to your likes");
  };

  modName = id => {
    if (this.state.moderators.length > 0) {
      let modNameArray = this.state.moderators.filter(mod => {
        return mod.data.getUserById.entityId === id;
      });
      let { firstName, lastName } = modNameArray[0].data.getUserById;
      if (firstName === null || lastName === null) {
        return "Moderator";
      } else {
        return `${firstName} ${lastName}`;
      }
    }
  };

  cancelReply = async message => {
    if (message.replies.length === 0) {
      await API.graphql(
        graphqlOperation(mutations.CHANGE_REPLY_STATUS, {
          entityId: message.entityId,
          replied: false
        })
      );
    }
    this.setState({ reply: null, replyText: "" });
  };

  renderStream = () => {
    return (
      <Right>
        <Embed
          source="youtube"
          id={this.state.stream !== null ? this.state.stream : "ywf6RJuoqWM"}
          icon="right circle arrow"
          placeholder="https://assets.online-days.com/video/UniMA_Frame.jpg"
          // url={this.state.stream !== null ? this.state.stream : 'https://assets.online-days.com/video/UniMA_Trailer_1080p.mp4'}
        />
      </Right>
    );
  };

  renderModeratorTag = (authorId, modArray) => {
    if (modArray.includes(authorId)) {
      return (
        <Comment.Metadata style={{ display: "block" }}>
          <Label
            style={{
              marginLeft: "-.7rem",
              marginTop: ".3rem"
            }}
            size={"tiny"}
            color={"yellow"}
          >
            <Icon name="star" />
            Moderator
          </Label>
        </Comment.Metadata>
      );
    } else if (authorId === "zula") {
      return (
        <Comment.Metadata style={{ display: "block" }}>
          <Label
            style={{
              marginLeft: "-.7rem",
              marginTop: ".3rem"
            }}
            size={"tiny"}
            color={"yellow"}
          >
            <Icon name="star" />
            Moderator
          </Label>
        </Comment.Metadata>
      );
    } else {
      return null;
    }
  };

  renderChat = () => {
    let modArray = this.state.event.moderators
      ? this.state.event.moderators.map(mod => {
          return mod.entityId;
        })
      : [];

    let messageStyle = {
      backgroundImage: `linear-gradient(to right bottom, rgb(${colors.primaryLight}, .8), rgba(${colors.primaryDark}, .5))`,
      padding: "1rem",
      borderRadius: "5px"
    };

    return (
      <Right>
        <Top ref={this.chatRef}>
          <Comment.Group
            size="large"
            style={{ width: "100%", maxWidth: "100%" }}
          >
            {this.state.messages.length > 0 ? (
              this.state.messages
                .sort((a, b) => {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                })
                .map(message => {
                  return (
                    <Comment
                      style={
                        message.authorId !== this.props.state.user.username
                          ? { minWidth: "50%", maxWidth: "70%", clear: "both" }
                          : {
                              float: "right",
                              marginRight: "0",
                              minWidth: "50%",
                              maxWidth: "70%",
                              clear: "both"
                            }
                      }
                    >
                      <Comment.Avatar
                        src={
                          modArray.includes(message.authorId) ||
                          message.authorId === "zula"
                            ? "https://assets.online-days.com/logos/uni_icon_small.png"
                            : "https://assets.online-days.com/images/user.png"
                        }
                      />

                      {/* <Comment.Avatar ><Icon name="user" /></Comment.Avatar> */}
                      <Comment.Content
                        style={
                          message.replied ? { ...messageStyle } : messageStyle
                        }
                      >
                        {message.authorId === this.props.state.user.username ? (
                          <Comment.Author as="a">Me</Comment.Author>
                        ) : (
                          <Comment.Author as="a">
                            {message.authorId !== "zula"
                              ? modArray.includes(message.authorId)
                                ? this.modName(message.authorId)
                                : "User " + message.authorId.substring(0, 5)
                              : this.props.state.lang === "de"
                              ? "Zulassungsstelle"
                              : "Admissions Office"}
                          </Comment.Author>
                        )}
                        <Comment.Metadata style={{ float: "right" }}>
                          <div>
                            <Moment format="HH:mm" date={message.createdAt} />
                          </div>
                        </Comment.Metadata>
                        <br />
                        {this.renderModeratorTag(message.authorId, modArray)}

                        {/* Here goes Event nugget in Zula */}
                        {this.props.match.params.chatId === "zula" && (
                          <OriginEventInfo id={message.data} />
                        )}

                        <Comment.Text style={{ whiteSpace: "pre-wrap" }}>
                          {message.body}
                        </Comment.Text>
                        <Comment.Actions>
                          {this.props.state.userGroup.includes(
                            "moderators"
                          ) && ( //must be changed to dept id
                            <>
                              {this.props.match.params.chatId !== "zula" && (
                                <>
                                  <Comment.Action
                                    onClick={e =>
                                      this.deleteMessage(e, message)
                                    }
                                  >
                                    Delete
                                  </Comment.Action>
                                </>
                              )}
                              <Comment.Action
                                onClick={e =>
                                  this.setReplyState(e, message.entityId)
                                }
                              >
                                Reply
                              </Comment.Action>

                              {/* Here is the Flag-Option! */}

                              {/* <Comment.Action
                                onClick={e => this.flagMessage(e, message)}
                              >
                                <Icon
                                  name={message.flag ? "flag" : "flag outline"}
                                  color={message.flag ? "red" : "grey"}
                                />
                              </Comment.Action> */}
                              {message.replied && (
                                <Comment.Action>
                                  <Icon name="check circle" inverted />
                                </Comment.Action>
                              )}
                            </>
                          )}
                          <Comment.Action onClick={() => this.addLike(message)}>
                            <Icon name="like" />
                          </Comment.Action>
                        </Comment.Actions>
                        {this.state.reply === message.entityId && (
                          <Form reply>
                            <textarea
                              style={{ whiteSpace: "pre-wrap" }}
                              value={this.state.replyText}
                              name="replyText"
                              onChange={this.handleChange}
                            />
                            <Button
                              content="Add Reply"
                              labelPosition="left"
                              icon="edit"
                              primary
                              disabled={this.state.replyText === ""}
                              onClick={e => this.postReply(e, message)}
                            />
                            <Button
                              content="Cancel"
                              labelPosition="left"
                              icon="cancel"
                              primary
                              onClick={() => this.cancelReply(message)}
                            />
                          </Form>
                        )}
                      </Comment.Content>
                      <Comment.Group>
                        {message.replies
                          .sort((a, b) => {
                            return (
                              new Date(a.createdAt).getTime() -
                              new Date(b.createdAt).getTime()
                            );
                          })
                          .map(reply => {
                            return (
                              <Comment>
                                {/* <Comment.Avatar src='https://semantic-ui.com/images/avatar/large/elliot.jpg' /> */}
                                <Comment.Avatar
                                  src={
                                    modArray.includes(reply.authorId) ||
                                    reply.authorId === "zula"
                                      ? "https://assets.online-days.com/logos/uni_icon_small.png"
                                      : "https://assets.online-days.com/images/user.png"
                                  }
                                />
                                <Comment.Content style={messageStyle}>
                                  {/* <Comment.Author as='a'>{"User " + reply.authorId.substring(0,6)}</Comment.Author> */}
                                  {reply.authorId ===
                                  this.props.state.user.username ? (
                                    <Comment.Author as="a">Me</Comment.Author>
                                  ) : (
                                    <Comment.Author as="a">
                                      {reply.authorId !== "zula"
                                        ? modArray.includes(reply.authorId)
                                          ? this.modName(reply.authorId)
                                          : "User " +
                                            reply.authorId.substring(0, 6)
                                        : this.props.state.lang === "de"
                                        ? "Zulassungsstelle"
                                        : "Admissions Office"}
                                    </Comment.Author>
                                  )}

                                  <Comment.Metadata style={{ float: "right" }}>
                                    <div>
                                      <Moment
                                        format="HH:mm"
                                        date={reply.createdAt}
                                      />
                                    </div>
                                  </Comment.Metadata>
                                  <br />
                                  {modArray.includes(reply.authorId) ||
                                    (reply.authorId === "zula" && (
                                      <Comment.Metadata
                                        style={{ display: "block" }}
                                      >
                                        <Label
                                          style={{
                                            marginLeft: "-.7rem",
                                            marginTop: ".3rem"
                                          }}
                                          size={"tiny"}
                                          color={"yellow"}
                                        >
                                          <Icon name="star" />
                                          Moderator
                                        </Label>
                                      </Comment.Metadata>
                                    ))}
                                  <Comment.Text
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {reply.body}
                                  </Comment.Text>
                                  <Comment.Actions>
                                    {this.props.state.userGroup.includes(
                                      "admins"
                                    ) && (
                                      <>
                                        <Comment.Action
                                          onClick={e =>
                                            this.deleteReply(e, reply)
                                          }
                                        >
                                          Delete
                                        </Comment.Action>
                                      </>
                                    )}
                                  </Comment.Actions>
                                </Comment.Content>
                              </Comment>
                            );
                          })}
                      </Comment.Group>
                    </Comment>
                  );
                })
            ) : (
              <Message as="div" style={{ textAlign: "left" }} icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>
                    {this.props.state.lang === "en"
                      ? "No messages"
                      : "Keine Nachrichten"}
                  </Message.Header>
                  {this.props.state.lang === "en"
                    ? "Currently, there are no messages yet."
                    : "Zurzeit gibt es noch keine Nachricht."}
                </Message.Content>
              </Message>
            )}

            {/* <Label style={{position: 'fixed', zIndex: '100', bottom: '1rem', left: '1rem'}}>Hello</Label> */}
          </Comment.Group>
        </Top>
        {this.state.active && (
          <Bottom>
            <Form reply style={{ paddingTop: "1rem" }}>
              <Form.TextArea
                value={this.state.input}
                onChange={this.handleChange}
                name="input"
              />
              <Button
                content="Post Message"
                labelPosition="left"
                icon="edit"
                primary
                disabled={this.state.input === ""}
                onClick={this.postMessage}
              />
            </Form>
          </Bottom>
        )}
        {this.state.newMessage && (
          <Notification
            hide={() => this.setState({ newMessage: false })}
            shown={this.state.newMessage}
          />
        )}
      </Right>
    );
  };

  renderMessageBoard = () => {
    return (
      <Container>
        <Left>
          <Header textAlign="left" as="h2">
            <Header.Content>
              {this.props.state.lang === "en" ? "Questions" : "Fragen"}
            </Header.Content>

            <Header.Subheader style={{ marginTop: ".5rem" }}>
              {this.props.state.lang === "en"
                ? "If you have any questions for the moderators, you may ask them here"
                : "Du kannst hier deine Fragen an die Moderierenden stellen"}
            </Header.Subheader>
          </Header>
        </Left>
        {this.renderChat()}
      </Container>
    );
  };

  setStream = async () => {
    let streamInput =
      this.state.streamInput !== "" ? this.state.streamInput : "ywf6RJuoqWM";
    let response = await API.graphql(
      graphqlOperation(mutations.SET_STREAM, {
        entityId: this.state.event.entityId,
        stream: streamInput,
        comments: this.state.allowMessages
      })
    );
    this.setState({ editStream: false });
  };

  render() {
    const { activeIndex } = this.state;

    if (!this.state.loading) {
      return (
        <Wrapper>
          <Container>
            {this.props.match.params.chatId !== "zula" && (
              <Left>
                <Header>
                  {this.state.event.type}:{" "}
                  {this.state.event.title[this.props.state.lang]}
                </Header>
                <Accordion style={{ width: "100%" }}>
                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    {this.props.state.lang === "en"
                      ? "Description"
                      : "Beschreibung"}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    {this.state.event.desc[this.props.state.lang]}
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    {this.props.state.lang === "en"
                      ? "Moderators"
                      : "Moderatoren"}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <AddModerators
                      event={this.state.event}
                      admin={this.props.state.userGroup.includes(
                        this.state.event.department.id
                      )}
                    />
                  </Accordion.Content>
                  {this.props.state.userGroup.includes(
                    this.state.event.department.id
                  ) || this.props.state.userGroup.includes("moderators")
                    ? this.state.event.type === "Stream" && (
                        <>
                          <Accordion.Title
                            active={activeIndex === 3}
                            index={3}
                            onClick={this.handleClick}
                          >
                            <Icon name="dropdown" />
                            {this.props.state.lang === "en"
                              ? "Stream"
                              : "Stream"}
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === 3}>
                            <Form>
                              <Form.Field>
                                <label>Youtube ID (not entire URL)</label>
                                <input
                                  disabled={!this.state.editStream}
                                  name="stream"
                                  value={this.state.streamInput}
                                  onChange={e =>
                                    this.setState({
                                      streamInput: e.target.value
                                    })
                                  }
                                  placeholder="URL"
                                />
                                <label>
                                  <a
                                    target="_blank"
                                    href="https://support.google.com/youtube/answer/2474026?hl=en&ref_topic=9257984"
                                  >
                                    Help for setting up a Youtube Livestream
                                  </a>
                                </label>
                              </Form.Field>
                              <Form.Field>
                                <Checkbox
                                  toggle
                                  disabled={!this.state.editStream}
                                  label="Allow Messages"
                                  checked={this.state.allowMessages}
                                  onChange={() =>
                                    this.setState(prevState => ({
                                      allowMessages: !prevState.allowMessages
                                    }))
                                  }
                                />
                              </Form.Field>
                              <Button
                                // disabled={this.state.editStream && this.state.streamInput === ""}
                                onClick={
                                  this.state.editStream
                                    ? this.setStream
                                    : () => this.setState({ editStream: true })
                                }
                                type="submit"
                              >
                                {this.state.editStream ? "Save" : "Edit"}
                              </Button>
                              {this.state.editStream && (
                                <Button
                                  onClick={() =>
                                    this.setState({ editStream: false })
                                  }
                                >
                                  Cancel
                                </Button>
                              )}
                            </Form>
                          </Accordion.Content>
                        </>
                      )
                    : null}
                </Accordion>
                <Checkbox
                  label="Autoscroll"
                  toggle
                  checked={this.state.autoScroll}
                  onChange={() =>
                    this.setState(prevState => ({
                      autoScroll: !prevState.autoScroll
                    }))
                  }
                  style={{ marginTop: "1rem" }}
                />
              </Left>
            )}
            {this.state.event.type === "Chat" ||
            this.props.match.params.chatId === "zula"
              ? this.renderChat()
              : this.renderStream()}
          </Container>
          {this.state.event.type === "Stream" &&
            this.state.allowMessages &&
            this.renderMessageBoard()}
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Container>
            <Loader active inverted size={"massive"} />
          </Container>
        </Wrapper>
      );
    }
  }
}

export default withContext(Chat);

const Wrapper = styled.div`
  padding-top: 10rem;
`;

const Container = styled.div`
  // background: white;
  border-radius: 5px;
  height: auto;
  display: flex;
  width: 90%;
  margin: 0 auto;
  overflow: auto;
  padding-bottom: 5rem;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 95%;
  }

  @media (min-width: 1500px) {
    width: 80%;
  }

  @media (min-width: 1700px) {
    width: 70%;
  }
`;

const Top = styled.div`
  flex: 5;
  overflow-y: scroll;
  position: relative;
  padding: 1rem;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Bottom = styled.div`
  flex: 1;
  @media (max-width: 800px) {
    width: 100%;
    padding: 1rem;
  }
`;

const Left = styled.div`
  flex: 1;
  padding: 3rem;
  background: white;
  max-height: 75vh;
  min-width: 20rem;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: 800px) {
    margin-right: 0.5rem;
    border-bottom-left-radius: 5px;
  }
`;

const Right = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  background: white;
  height: 75vh;
  border-bottom-right-radius: 5px;
  resize: both;
  @media (max-width: 800px) {
    width: 100%;
    padding: 0;
    margin-top: 0.5rem;
    height: 100vh;
    border-bottom-left-radius: 5px;
  }
`;

// const MessageBoard = styled.div`
//   background: white;
//   width: 80%;
//   margin: 0 auto;
//   max-height: 75vh;
//   display: flex;
// `;
