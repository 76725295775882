import React from "react";
import { Button, Icon } from "semantic-ui-react";
class BlockStyleButton extends React.Component {
  onToggle = e => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <Button icon onClick={this.onToggle} primary={this.props.active}>
        <Icon name={this.props.icon} />
      </Button>
    );
  }
}
export default BlockStyleButton;
