import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Button, Dropdown, Item } from "semantic-ui-react";

import ModeratorCard from "./ModeratorCard";

export class AddModerators extends Component {
  state = {
    moderators: [],
    add: false,
    moderatorOptions: [],
    input: ""
  };

  async componentDidMount() {
    let modEmails =
      this.props.event.moderators !== null
        ? this.props.event.moderators.map(mod => mod.email)
        : [];
    // let filteredModeratorOptions = modEmails.filter(mod => !modEmails.includes(mod.email))
    let response = await API.graphql(
      graphqlOperation(queries.GET_DEPT_STAFF, { poolId: "moderators" })
    );
    let moderatorOptions = response.data.getUsersFromPool
      .filter(mod => !modEmails.includes(mod.email))
      .map((user, index) => {
        return {
          key: index,
          value: user.entityId,
          text: user.email
        };
      });

    this.setState({ moderatorOptions });
  }

  handleChange = (e, data) => {
    this.setState({ input: data.value });
  };

  addModerator = async () => {
    let response = await API.graphql(
      graphqlOperation(mutations.ADD_MODERATOR, {
        eventId: this.props.event.entityId,
        userId: this.state.input
      })
    );
    window.location.reload();
  };

  render() {
    return (
      <div>
        <Item.Group>
          {this.props.event.moderators !== null &&
            this.props.event.moderators.map((moderator, index) => {
              return (
                <ModeratorCard
                  moderator={moderator}
                  event={this.props.event}
                  cardIndex={index}
                />
              );
            })}
        </Item.Group>
        {this.props.admin && (
          <div>
            {this.state.add && (
              <Dropdown
                options={this.state.moderatorOptions}
                fluid
                selection
                search
                placeholder="Select"
                onChange={(e, data) => this.handleChange(e, data)}
              />
            )}
            {!this.state.add ? (
              <Button
                onClick={() => this.setState({ add: true })}
                style={{ marginTop: "1rem" }}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  onClick={this.addModerator}
                  style={{ marginTop: "1rem" }}
                >
                  Add
                </Button>
                <Button
                  onClick={() => this.setState({ add: false })}
                  style={{ marginTop: "1rem" }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AddModerators;
