import React, { Component } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import myTheme from "./myTheme";
import AfterSignupMessage from "./AfterSignupMessage";

import { Authenticator, SignIn } from "aws-amplify-react";
import AWSConfig from "../config";
import SignupForm from "./SignupForm";

export class Landing extends Component {
  handleAuthStateChange = state => {};

  render() {
    return (
      <Grid
        columns={2}
        centered
        verticalAlign="top"
        relaxed
        padded
        stackable
        style={{ minHeight: "65vh" }}
      >
        <Grid.Row>
          <Grid.Column>
            <video
              width="100%"
              controls
              poster="https://assets.online-days.com/video/UniMA_Frame.jpg"
              src="https://assets.online-days.com/video/OED_2020_1080p.mp4"
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Authenticator
              theme={myTheme}
              authState="signUp"
              hideDefault={true}
              amplifyConfig={AWSConfig}
              hide={[SignIn]}
              onStateChange={this.handleAuthStateChange}
            >
              <SignupForm override={"SignUp"} />
              <AfterSignupMessage override={"ConfirmSignUp"} />
            </Authenticator>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Header as="p" icon textAlign="center">
            <Icon name="angle down" style={{ color: "white" }} circular />
          </Header>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Landing;
