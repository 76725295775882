import React, { Component } from 'react'
import {Embed, Loader, Header, Accordion, Icon} from 'semantic-ui-react'
import styled from 'styled-components'
import {API, graphqlOperation} from 'aws-amplify'
import withContext from '../Context/withContext'

import * as queries from '../graphql/queries'

export class Stream extends Component {


  state = {
    loading: true,
    event: {},
    activeIndex: 0
  }

  async componentDidMount() {
    let response = await API.graphql(graphqlOperation(queries.GET_EVENT_BY_ID, { entityId: this.props.match.params.streamId}))
    this.setState({event: response.data.getEventById, loading: false})
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  } 

  
  render() {
    const {activeIndex} = this.state
    if(this.state.loading) {
      return (
        <Loader size="massive" active inverted />
      )
    } else {
      return (
        <Wrapper>
          <Container>
            <Left>
              <Header>{this.state.event.title[this.props.state.lang]}</Header>
                <Accordion>
                  <Accordion.Title
                    active={activeIndex === 1}
                    index = {1}
                    onClick={this.handleClick}>
                    <Icon name="dropdown" />
                    {this.props.state.lang === "en" ? "Description" : "Beschreibung"}
                  </Accordion.Title>
                  <Accordion.Content 
                    active={activeIndex === 1}>
                   {this.state.event.desc[this.props.state.lang]} 
                  </Accordion.Content>
                </Accordion>
            </Left>
            <Right>
              <Embed
              icon='right circle arrow'
              placeholder='https://assets.online-days.com/video/UniMA_Trailer_1080p_Cover.jpg'
              url='https://assets.online-days.com/video/UniMA_Trailer_1080p.mp4'
            />
            </Right>
          </Container>
        </Wrapper>
      )
    }
  }
}

export default withContext(Stream)

const Wrapper = styled.div`
  padding-top: 10rem;
`

const Container = styled.div`
  background: white;
  border-radius: 5px;
  max-height: 75vh;
  display: flex;
  width: 80%;
  margin: 0 auto;
`

const Left = styled.div`
flex: 1;
padding: 3rem;
`

const Right = styled.div`
  flex: 3;
  padding: 3rem;
`