import React, { Component } from 'react'
import { Modal, Form, Button} from 'semantic-ui-react'

export class SelectionModal extends Component {
  render() {
    return (
      <Modal open={this.props.open} onUnmount={this.props.onUnmount}>
                  <Modal.Header>
                    {this.props.edit ? "Edit Item" : "Create new item"}
                  </Modal.Header>
                  <Modal.Content>
                  <Modal.Description>
                  <Form>
                    <Form.Field>
                      <label>Title English</label>
                      <input placeholder='First Name' value={this.props.titleEN} onChange={this.props.handleChange} name="titleEN"/>
                    </Form.Field>
                    <Form.Field>
                      <label>Title German</label>
                      <input placeholder='Last Name' value={this.props.titleDE} onChange={this.props.handleChange} name="titleDE"/>
                    </Form.Field>
                    <Button type='submit' onClick={(e) => this.props.addItem(e, this.props.entityId)}>{this.props.edit ? "Edit" : "Submit"}</Button>
                    <Button type='submit' onClick={this.props.close}>Cancel</Button>
                  </Form>
                  </Modal.Description>
                  </Modal.Content>
                  
                </Modal>
    )
  }
}

export default SelectionModal
