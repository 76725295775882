import React, { Component } from "react";
import styled from "styled-components";
import { Menu, Card } from "semantic-ui-react";
import withContext from "../Context/withContext";

export class FacultyList extends Component {
  renderColor = event => {
    if (event.entityId === "5c548b623df54832f894645c") {
      return {
        backgroundImage:
          "linear-gradient(to left top, rgb(151, 184, 98), #c6d8a9)",
        borderTop: "none",
        margin: "1rem 1rem",
        order: 2
      };
    }
    if (event.entityId === "5c5944e511f43b0004fee636") {
      return {
        backgroundImage: "linear-gradient(to left top, #d6585d, #e1868a)",
        margin: "1rem 1rem",
        order: 4
      };
    }
    if (event.entityId === "5c64057689535f00047f0adb") {
      return {
        backgroundImage:
          "linear-gradient(to left top, rgb(64, 136, 133), #89c7c5)",
        margin: "1rem 1rem",
        order: 5
      };
    }
    if (event.entityId === "5c64057689535f00047q3445") {
      return {
        backgroundImage: "linear-gradient(to left top, 	#DC8052, #e6a686)",
        margin: "1rem 1rem",
        order: 3
      };
    }
    if (
      event.entityId === "5c5cabb19363f60004c67145" ||
      event.entityId === "5c580ddc60344800048471f9"
    ) {
      return {
        backgroundImage:
          "linear-gradient(to left top, rgb(130, 152, 175), #bbc7d3)",
        margin: "1rem 1rem",
        order: 1
      };
    }
    return {
      backgroundImage:
        "linear-gradient(to right bottom, rgb(220, 220, 220), rgb(200, 200, 200))",
      margin: "1rem 1rem"
      // color: "white"
    };
  };

  render() {
    return (
      <Wrapper>
        {/* <Menu stackable widths={this.props.departments.length}>
          {this.props.departments.map((fac, index) => (
            <Menu.Item
              key={index}
              active={this.props.state.activeDept === fac.entityId}
              onClick={e => this.props.state.onActiveDepChange(e, fac.entityId)}
              style={{ margin: "0 1rem" }}
            >
              {fac.title[this.props.state.lang]}
            </Menu.Item>
          ))}
        </Menu> */}

        {this.props.departments.map((fac, index) => (
          <FacultyCard
            key={index}
            active={this.props.state.activeDept === fac.entityId}
            onClick={e => {
              this.props.state.onActiveDepChange(e, fac.entityId);
              this.props.scrollToInfo();
            }}
            style={this.renderColor(fac)}
          >
            <CardText>{fac.title[this.props.state.lang]}</CardText>
          </FacultyCard>
        ))}
      </Wrapper>
    );
  }
}

export default withContext(FacultyList);

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  min-height: 15vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 3rem;
`;

const FacultyCard = styled.div`
  width: 20rem;
  height: 6rem;
  padding: 1rem;
  text-align: center;
  background: white;
  border-radius: 5px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3);
  display: flex;
  font-size: 1.25rem;
  cursor: pointer;
  color: rgb(35, 46, 88);
  &:hover {
    transform: translate(-1%, -1%);
    transition: all 0.2s ease-in-out;
  }
`;

const CardText = styled.div`
  margin: auto;
`;
