import React, { useState } from "react";
import withContext from "../Context/withContext";
import { useFormFields } from "../libs/hookLibs";
import {
  Button,
  Checkbox,
  Form,
  Header,
  Segment,
  Message,
  Dropdown
} from "semantic-ui-react";
import { Auth } from "aws-amplify";
import { programs } from "../dummyContent";
import { Link } from "react-router-dom";
import { Analytics } from "aws-amplify";

const SignupForm = props => {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [programInterest, setProgramInterest] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const newUser = await Auth.signUp({
        "username": fields.email,
        "password": fields.password,
        "attributes": {
          "custom:interest": programInterest,
          "custom:day": process.env.REACT_APP_DAY
        }
      });
      Analytics.updateEndpoint({
        address: newUser.user.username,
        channelType: "EMAIL",
        optOut: "NONE",
        userId: newUser.userSub,
        userAttributes: {
          interests: programInterest ? programInterest.split(", ") : "na",
          day: [process.env.REACT_APP_DAY]
        }
      });
      setIsLoading(false);
      setNewUser(newUser);
      props.onStateChange("confirmSignUp");
    } catch (e) {
      setErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    return (
      fields.email.length > 0 && fields.password.length > 0 && privacyCheck
    );
  };

  const handleCheckbox = (e, data) => {
    setPrivacyCheck(data.checked);
  };

  const handleDropdown = (e, data) => {
    let interestSring = data.value.join(", ");
    setProgramInterest(interestSring);
  };

  const renderForm = () => {
    return (
      <Segment
        style={{
          marginTop: "1.5rem",
          padding: "3rem 4rem",
          overflow: "auto",
          minWidth: "23rem"
        }}
      >
        {errorMessage && <Message error content={errorMessage} />}
        <Header style={{ fontSize: "2rem" }} textAlign="left">
          {props.state.lang === "en"
            ? "Sign up for free"
            : "Jetzt kostenlos anmelden"}
        </Header>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Email*</label>
            <input
              name="email"
              value={fields.email}
              onChange={handleFieldChange}
              placeholder="Email"
            />
          </Form.Field>
          <Form.Field>
            <label>
              {props.state.lang === "en" ? "Password*" : "Passwort*"}
            </label>
            <input
              name="password"
              value={fields.password}
              onChange={handleFieldChange}
              placeholder={props.state.lang === "en" ? "Password" : "Passwort"}
              type="password"
            />
          </Form.Field>
          <Form.Field>
            <label>
              {props.state.lang === "en"
                ? "What topics are you interested in?"
                : "Welche Themen interessieren dich?"}
            </label>
            <Dropdown
              multiple
              scrolling
              upward={false}
              placeholder={
                props.state.lang === "en" ? "Choose topics" : "Themen"
              }
              options={programs[props.state.lang]}
              name="programs"
              onChange={(e, data) => handleDropdown(e, data)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={{
                children:
                  props.state.lang === "en" ? (
                    <p>
                      I agree to the{" "}
                      <Link to="/privacy">data privacy guidelines</Link> of this
                      platform
                    </p>
                  ) : (
                    <p>
                      Ich stimme den{" "}
                      <Link to="/privacy">Datenschutzrichtlinien</Link> der
                      Online Experience Days zu
                    </p>
                  )
              }}
              checked={privacyCheck}
              toggle
              onChange={(e, data) => handleCheckbox(e, data)}
            />
          </Form.Field>
          <Button loading={isLoading} disabled={!validateForm()} type="submit">
            {props.state.lang === "en"
              ? "Sign up for free"
              : "Kostenlos anmelden"}
          </Button>
        </Form>
      </Segment>
    );
  };

  return <div>{props.authState === "signUp" ? renderForm() : null}</div>;
};

export default withContext(SignupForm);
