import React, { Component } from 'react'
import {Header, Icon, Card, Loader} from 'semantic-ui-react'
import { API, graphqlOperation } from 'aws-amplify'
import { GET_ALL_GROUPS } from '../graphql/queries'

import GroupCard from './GroupCard'

export class StaffAdmin extends Component {
  state = {
    groups: [],
    loading: true
  }

  async componentDidMount() {
    let response = await API.graphql(graphqlOperation(GET_ALL_GROUPS))
    this.setState({groups: response.data.listAllGroups, loading: false})
  }
  
  render() {
    if(this.state.loading) {
      return <Loader active inverted size="massive" />
    } else {
      return (
        <div>
          <Header as='h2' icon textAlign='center'>
            <Icon name='users' circular />
            <Header.Content>Editors</Header.Content>
          </Header>
          <Card.Group centered>
          {this.state.groups.map(group => {
            return(
              <GroupCard group={group} users={this.props.users}/>
            )
          })

          }
          </Card.Group>
        </div>
      )   
    }
  }
}

export default StaffAdmin
