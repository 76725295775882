import React, { Component } from "react";

class MMMCard1 extends Component {
  state = {
    change: false
  };
  clicked = () => {
    this.setState({
      change: true
    });
    this.props.onClick();
  };
  render() {
    return (
      <div
        className="MMMCard"
        style={{ display: this.state.change ? "none" : "block" }}
      >
        <h3 className="heading-tertiary">{this.props.card.title}</h3>
        <button className="btn btn--blue btn--small" onClick={this.clicked}>
          Take back
        </button>
      </div>
    );
  }
}

export default MMMCard1;
