import React, { Component } from "react";
import { Dropdown, Menu, Button } from "semantic-ui-react";
import { options, types, times, programs } from "../dummyContent";
import withContext from "../Context/withContext";

export class FilterPanel extends Component {
  render() {
    return (
      <Menu
        style={{
          width: "100%",
          padding: ".5rem"
        }}
        stackable
      >
        <Menu.Item>
          {this.props.state.lang === "en" ? "Filter by" : "Filter anwenden"}
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            upward={false}
            onChange={(e, data) => this.props.setFilter(e, "department", data)}
            text={
              this.props.state.lang === "en" ? "Department/School" : "Fakultät"
            }
            icon="university"
            labeled
            button
            scrolling
            className="icon"
            options={options[this.props.state.lang]}
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            upward={false}
            text={this.props.state.lang === "en" ? "Program" : "Fach"}
            icon="book"
            labeled
            button
            scrolling
            options={programs[this.props.state.lang]}
            onChange={(e, data) => this.props.setFilter(e, "program", data)}
            className="icon"
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            upward={false}
            text={
              this.props.state.lang === "en" ? "Event Type" : "Art des Events"
            }
            icon="discussions"
            labeled
            button
            options={types}
            onChange={(e, data) => this.props.setFilter(e, "type", data)}
            className="icon"
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            upward={false}
            text={
              this.props.state.lang === "en" ? "Starting from" : "Beginn ab"
            }
            icon="clock"
            labeled
            button
            scrolling
            options={times}
            onChange={(e, data) => this.props.setFilter(e, "startTime", data)}
            className="icon"
          />
        </Menu.Item>
        <Menu.Item position={"right"}>
          <Button negative onClick={this.props.clearFilter}>
            {this.props.state.lang === "en"
              ? "Clear All Filters"
              : "Alle Filter löschen"}
          </Button>
        </Menu.Item>
      </Menu>
    );
  }
}

export default withContext(FilterPanel);
