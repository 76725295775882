import React, { Component } from "react";
import {
  Icon,
  Card,
  Transition,
  Button,
  Flag,
  Checkbox
} from "semantic-ui-react";
import Moment from "react-moment";
import withContext from "../Context/withContext";
import {
  languageTranslateEN,
  languageTranslateDE,
  timezone
} from "../dummyContent";
import { withRouter } from "react-router-dom";
import { API, graphqlOperation, Analytics } from "aws-amplify";
import sha1 from "sha1";
import axios from "axios";

import * as mutations from "../graphql/mutations";

export class EventCard extends Component {
  renderColor = event => {
    if (event.department.id === "5c548b623df54832f894645c") {
      return {
        backgroundImage:
          "linear-gradient(to left top, rgb(151, 184, 98), #c6d8a9)",
        borderTop: "none",
        minHeight: "5rem"
      };
    }
    if (event.department.id === "5c5944e511f43b0004fee636") {
      return {
        backgroundImage: "linear-gradient(to left top, #d6585d, #e1868a)",
        minHeight: "5rem"
      };
    }
    if (event.department.id === "5c64057689535f00047f0adb") {
      return {
        backgroundImage:
          "linear-gradient(to left top, rgb(64, 136, 133), #89c7c5)",
        minHeight: "5rem"
      };
    }
    if (event.department.id === "5c64057689535f00047q3445") {
      return {
        backgroundImage: "linear-gradient(to left top, 	#DC8052, #e6a686)",
        minHeight: "5rem"
      };
    }
    if (
      event.department.id === "5c5cabb19363f60004c67145" ||
      event.department.id === "5c580ddc60344800048471f9"
    ) {
      return {
        backgroundImage:
          "linear-gradient(to left top, rgb(130, 152, 175), #bbc7d3)",
        minHeight: "5rem"
      };
    }
    return {
      backgroundImage:
        "linear-gradient(to right bottom, rgb(220, 220, 220), rgb(200, 200, 200))",
      minHeight: "5rem"
    };
  };

  renderBackground = event => {
    if (event.type === "Chat") {
      return {
        backgroundImage:
          "linear-gradient(to right bottom, rgb(240, 240, 240), rgb(200,200,200))",
        boxShadow: ".5rem .5rem 1rem rgba(0,0,0,.2)"
      };
    } else {
      return {
        background: `repeating-linear-gradient(
        135deg,
        rgb(230,230,230),
        rgb(230,230,230) 15px,
        rgb(220,220,220) 15px,
        rgb(220,220,220) 30px
      )`,
        boxShadow: ".5rem .5rem 1rem rgba(0,0,0,.2)"
      };
    }
  };

  reference = () => {
    if (this.props.event.type === "Chat") {
      // this.props.history.push(`/inside/chat/${this.props.event.entityId}`);
      window.open(`/inside/chat/${this.props.event.entityId}`, "_blank");
    } else {
      // this.props.history.push(`/inside/stream/${this.props.event.entityId}`)
      // this.props.history.push(`/inside/chat/${this.props.event.entityId}`);
      window.open(`/inside/chat/${this.props.event.entityId}`, "_blank");
    }
  };

  toggleActive = async () => {
    this.setState({ selecting: true });
    const active = this.props.event.active ? false : true;
    let response = await API.graphql(
      graphqlOperation(mutations.TOGGLE_EVENT_ACTIVATION, {
        entityId: this.props.event.entityId,
        active
      })
    );
  };

  startConference = async event => {
    console.log(event.entityId);
    let response = await API.graphql(
      graphqlOperation(mutations.START_CONFERENCE, {
        eventId: event.entityId,
        userId: this.props.state.user.username,
        userType: this.props.state.userGroup.includes("moderators")
          ? "moderator"
          : "attendee",
        eventName: encodeURI(event.title.en)
      })
    );

    console.log(response.data.startConference);
    window.open(response.data.startConference.url, "_blank");

    // let createString = `createname=${event.title.en}&meetingID=${event.entityId}&attendeePW=attendee&moderatorPW=moderatornwuYzyrczzA8ZCZUAmB5y4BYDrvT9Z2DeAr7eFgo0c`;
    // let checksum = sha1(createString);
    // let url = `https://conferences.online-days.com/bigbluebutton/api/create?name=${event.title.en}&meetingID=${event.entityId}&attendeePW=attendee&moderatorPW=moderator&checksum=${checksum}`;

    // await window.open(url, "_blank");
    // ;
    // let joinString = `joinmeetingID=${event.entityId}&fullName=${this.props.state.user.username}&password=attendeenwuYzyrczzA8ZCZUAmB5y4BYDrvT9Z2DeAr7eFgo0c`;
    // let checksum2 = sha1(joinString);
    // let joinUrl = `https://conferences.online-days.com/bigbluebutton/api/join?meetingID=${event.entityId}&fullName=${this.props.state.user.username}&password=attendee&checksum=${checksum2}`;
    // window.open(joinUrl, "_blank");
  };

  render() {
    const {
      department,
      type,
      startTime,
      endTime,
      title,
      desc,
      language,
      programs
    } = this.props.event;
    const { state } = this.props;
    return (
      <Transition transitionOnMount animation="scale" duration={500}>
        <Card
          style={this.renderBackground(this.props.event)}
          fluid={this.props.fluid}
        >
          <Card.Content style={this.renderColor(this.props.event)}>
            <span style={{ color: "rgba(0,0,0,.5)" }}>
              <Icon name="university" />
              {department[state.lang]}
            </span>
          </Card.Content>

          <Card.Content>
            <Card.Header>{title[state.lang]}</Card.Header>
            <Card.Meta>
              <Moment format="HH:mm">{startTime}</Moment>-{" "}
              <Moment format="HH:mm">{endTime}</Moment> {timezone[state.lang]}
            </Card.Meta>
            {this.props.active && (
              <Button
                style={{ margin: "1rem auto", display: "block" }}
                onClick={
                  type !== "Conference"
                    ? () => {
                        this.reference();
                        Analytics.record({
                          name: "chatView",
                          attributes: {
                            chat: `${title.en} - ${this.props.event.department.en}`,
                            dept: this.props.event.department.en
                          }
                        });
                      }
                    : () => this.startConference(this.props.event)
                }
                positive
              >
                Join
              </Button>
            )}
            {this.props.archive && type !== "Conference" && (
              <Button
                style={{ margin: ".5rem auto", display: "block" }}
                onClick={this.reference}
                primary
              >
                See archived event
              </Button>
            )}
            {!this.props.active &&
            this.props.state.userGroup.includes(
              this.props.event.department.id
            ) ? (
              <>
                <Button
                  basic
                  style={{ display: "block" }}
                  primary
                  style={{ margin: ".5rem auto", display: "block" }}
                  onClick={
                    type !== "Conference"
                      ? this.reference
                      : () => this.startConference(this.props.event)
                  }
                  size={"mini"}
                >
                  Preview event
                </Button>
              </>
            ) : !this.props.active &&
              this.props.state.userGroup.includes("moderators") ? (
              <>
                <Button
                  style={{ display: "block" }}
                  basic
                  primary
                  style={{ margin: ".5rem auto", display: "block" }}
                  onClick={
                    type !== "Conference"
                      ? this.reference
                      : () => this.startConference(this.props.event)
                  }
                  size={"mini"}
                >
                  Preview event
                </Button>
              </>
            ) : (
              <></>
            )}
            {this.props.state.userGroup.includes(
              this.props.event.department.id
            ) ? (
              <Checkbox
                style={{ marginBottom: "1rem" }}
                label="Active"
                checked={this.props.event.active}
                toggle
                onClick={this.toggleActive}
              />
            ) : this.props.state.userGroup.includes("moderators") ? (
              <Checkbox
                style={{ marginBottom: "1rem" }}
                label="Active"
                checked={this.props.event.active}
                toggle
                onClick={this.toggleActive}
              />
            ) : (
              <></>
            )}
            <Card.Description content={desc[state.lang]} />
          </Card.Content>

          <Card.Content extra>
            <p>
              <Icon
                name={
                  type === "Chat"
                    ? "chat"
                    : type === "Stream"
                    ? "video"
                    : "users"
                }
              />
              {type}
            </p>
          </Card.Content>
          <Card.Content extra>
            <p>
              <Flag name={language === "de" ? "de" : "uk"} />

              {language === "de"
                ? languageTranslateDE[state.lang]
                : languageTranslateEN[state.lang]}
            </p>
          </Card.Content>

          {programs && (
            <Card.Content extra>
              <Icon name="book" />
              {programs
                .sort((a, b) => a.en < b.en)
                .map((program, i) => {
                  return (
                    <span key={i}>
                      {program[state.lang]}{" "}
                      {i == programs.length - 1 ? "" : "|"}{" "}
                    </span>
                  );
                })}
            </Card.Content>
          )}
          {this.props.fluid && (
            <Card.Content extra>
              <Button
                color="orange"
                onClick={(e, id) => this.props.onUpdate(e, this.props.event)}
              >
                Edit
              </Button>
              <Button
                color="red"
                onClick={id =>
                  this.props.deleteEvent(this.props.event.entityId)
                }
              >
                Delete
              </Button>
            </Card.Content>
          )}
        </Card>
      </Transition>
    );
  }
}

export default withContext(withRouter(EventCard));
