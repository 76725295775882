export default [
  //NEW ITEM
  {
    title: {
      en: `Start`,
      de: `Start`
    },
    content: {
      en: `Your Way to the University of Mannheim`,
      de: `Ihr Weg zum Masterstudium an der Uni Mannheim!`
    },
    subs: [
      {
        id: 1,
        text: {
          en: `Learn more about our master’s programs`,
          de: `Informieren Sie sich über unsere Masterstudiengänge`
        },
        modal: {
          en: `
          <p>
            You can find an overview of all master’s programs under
            <a href="https://www.uni-mannheim.de/en/academics/programs/" target="_blank">https://www.uni-mannheim.de/en/academics/programs/</a>.
            If you need more detailed information, please
            contact the program managers and/or
            academic advisors of the respective program
            of study.
          </p>
          <p> 
          <i class="fas fa-lightbulb"></i> Tip: Provided that you meet the respective
            admission requirements, you can apply for a
            maximum of three programs.
          </p>`,
          de: `
          <p>
            Eine Übersicht über alle
            Masterstudiengänge finden Sie unter
            <a href="https://www.uni-mannheim.de/studium/studienangebot/" target="_blank">https://www.uni-
            mannheim.de/studium/studienangebot/</a>
            Wenn Sie detaillierte Infos brauchen, wenden
            Sie sich am besten direkt an die
            Studienberatung des jeweiligen
            Studiengangs.
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tipp:
            Sie können sich insgesamt für drei
            Studiengänge bewerben, allerdings müssen
            die Zugangsvoraussetzungen erfüllt sein.
          </p>`
        }
      },
      {
        id: 2,
        text: {
          en: `Check the admission requirements`,
          de: `Prüfen Sie die Zugangsvoraussetzungen`
        },
        modal: {
          en: `
          <p>
            For your application to be considered in the
            selection process, you have to meet the
            admission requirements of the program you
            have selected. These can be found in the
            <a href="https://www.uni-
            mannheim.de/en/academics/applying/the-a-to-
            z-of-applying/selection-statutes/#c17206" target="_blank">selection statutes</a> of the respective program of
            study .
          </p>
          <p>
          <i class="fas fa-info-circle"></i> Please note:
            Admission requirements ≠ selection criteria!
            In addition to the mandatory admission
            requirements, other factors are also taken into
            account in the admission procedure of most
            programs. If you meet these additional
            selection criteria, you can increase your
            chances of being admitted to the desired
            program. For detailed information on the
            individual selection criteria please see the
            <a href="https://www.uni-
            mannheim.de/en/academics/applying/the-a-to-
            z-of-applying/selection-statutes/#c17206" target="_blank">selection statutes</a>.
          </p>`,
          de: `
          <p>
            Die Zugangsvoraussetzungen für unsere
            Masterstudiengänge müssen erfüllt sein,
            damit wir Ihre Bewerbung ins
            Vergabeverfahren aufnehmen können. Sie finden diese in der <a href="https://www.uni-
            mannheim.de/studium/bewerbung/bewerbu
            ng-von-a-bis-z/auswahlsatzungen/#c17206" target="_blank">Auswahlsatzung</a> des
            jeweiligen Studiengangs.
          </p>
          <p>
          <i class="fas fa-info-circle"></i> Achtung:
            Zugangsvoraussetzungen ≠
            Auswahlkriterien!
            Neben den Zugangsvoraussetzungen gibt
            es weitere Auswahlkriterien, mit denen Sie
            Ihre Chancen auf einen Studienplatz
            erhöhen können. Detaillierte Informationen
            zu den einzelnen Kriterien findesn Sie
            ebenfalls in der <a href="https://www.uni-
            mannheim.de/studium/bewerbung/bewerbu
            ng-von-a-bis-z/auswahlsatzungen/#c17206" target="_blank">Auswahlsatzung</a>.
            
          </p>`
        }
      }
    ]
  },
  //NEW ITEM
  {
    title: {
      en: `March 15`,
      de: `15. März`
    },
    content: {
      en: `The online application portal will open`,
      de: `Öffnung des Bewerbungsportals`
    },
    subs: [
      {
        id: 3,
        text: {
          en: `Start applying for your master’s program`,
          de: `Starten Sie Ihre Masterbewerbung`
        },
        modal: {
          en: `
          <p>
            Our online application portal will open on  March 15. So, you can get started. 
            First of all, you need to complete the <a href="https://onlinebewerbung.uni-mannheim.de/portal/register.php?lang=de" target="_blank">online application form</a>.
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tip: 
            After you have completed the online application form, you can download and print the PDF file. 
            Make sure that you do not forget to sign it.
          </p>
          `,
          de: `
          <p>
            Ab dem 15. März ist unser Bewerbungsportal geöffnet und Sie können loslegen! 
            Dafür müssen Sie zunächst die Online-Bewerbung durchlaufen. 
            Zum Portal kommen Sie <a href="https://onlinebewerbung.uni-mannheim.de/portal/register.php?lang=de" target="_blank">hier</a>.
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tipp: 
            Nachdem Sie Ihre Daten online eingetragen hast, wird Ihnen der Zulassungsantrag als PDF zum Download zur Verfügung gestellt. 
            Dann müssen Sie ihn noch ausdrucken und unbedingt daran denken ihn auch zu unterschreiben!
          </p>
          `
        }
      },
      {
        id: 4,
        text: {
          en: `Compile the required application documents`,
          de: `Stellen Sie Ihre Bewerbungsunterlagen zusammen`
        },
        modal: {
          en: `
          <p>
            Your application form contains a personalized checklist listing all documents that you need to submit together with the form.  
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tip: Please note that some documents need to be submitted as certified copies. These are also indicated on your checklist.
          </p>
          `,
          de: `
          <p>
            Welche Unterlagen Sie zusammen mit dem ausgedruckten Zulassungsantrag einreichen müssen, sehen Sie auf der Checkliste 
            am Ende des Antragsbogens.
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tipp: 
            Bitte beachten Sie, dass einige Dokumente in beglaubigter Kopie eingereicht werden müssen! 
            Welche das sind, steht in der Checkliste.
          </p>
          `
        }
      },
      {
        id: 5,
        text: {
          en: `Send your application by mail to the University of Mannheim or submit it in person`,
          de: `Schicken Sie Ihre Bewerbung per Post an die Uni Mannheim oder bringen Sie sie persönlich vorbei`
        },
        modal: {
          en: `
          <p>
            Once you have compiled all required documents, you need to mail them to the University of Mannheim or submit them 
            in person – only then will your application be completed.
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tip: 
            The earlier we receive your application, the more likely it is that the Admissions Office will be able to inform you 
            if any documents are missing.
          </p>
          `,
          de: `
          <p>
            Wenn Sie alle Unterlagen haben, müssen Sie sie per Post an die Uni Mannheim senden oder persönlich abgeben – erst dann 
            ist Ihre Bewerbung abgeschlossen!
          </p>
          <p>
          <i class="fas fa-lightbulb"></i> Tipp: 
            Je früher Ihre Bewerbung eingeht, desto wahrscheinlicher ist es, dass die Zulassungsstelle Sie benachrichtigen kann, 
            falls Dokumente fehlen.
          </p>
          `
        }
      }
    ]
  },
  //NEW ITEM
  {
    title: {
      en: `April 30`,
      de: `30. April`
    },
    content: {
      en: `
      <p>
        Deadline for the master’s programs in Intercultural German Studies, Political Science, Sociology, and Economics 
      </p>
      <p>
      <i class="fas fa-info-circle"></i> Please note:
        This deadline is definitive, meaning that the application for admission including all required documents must have 
        been received by the Admissions Office in hardcopy by this specific deadline. 
      </p>
      `,
      de: `
      <p>
        Deadline für die Masterstudiengänge Intercultural German Studies, Political Science, Sociology, Volkswirtschaftslehre
      </p>
      <p>
      <i class="fas fa-info-circle"></i> Achtung: Diese Frist ist eine Ausschlussfrist, d.h. der Zulassungsantrag muss zusammen mit allen erforderlichen Unterlagen 
        innerhalb der gegebenen Frist in Papierform bei uns eingegangen sein.
      </p>
      `
    },
    subs: []
  },
  //NEW ITEM
  {
    title: {
      en: `May 31`,
      de: `31. Mai`
    },
    content: {
      en: `
      <p>
        Deadline for all master’s programs except for the programs in Intercultural German Studies, Political Science, Sociology, and Economics
      </p>
      <p>
      <i class="fas fa-info-circle"></i> Please note:
        This deadline is definitive, meaning that the application for admission including all required documents must have been received by the Admissions Office in hardcopy by this specific deadline. 
      </p>
      `,
      de: `
      <p>
        Deadline für alle Masterstudiengänge außer Intercultural German Studies, Political Science, Sociology, Volkswirtschaftslehre
      </p>
      <p>
      <i class="fas fa-info-circle"></i> Achtung:
        Diese Frist ist eine Ausschlussfrist, d.h. der Zulassungsantrag muss zusammen mit allen erforderlichen Unterlagen innerhalb 
        der gegebenen Frist in Papierform bei uns eingegangen sein.
      </p>
      `
    },
    subs: [
      {
        id: 6,
        text: {
          en: `Check the status of your application`,
          de: `Checke Sie Ihren Bewerbungsstatus`
        },
        modal: {
          en: `
        <p>
          You can always obtain the current status of your application by logging into the online application portal.
        </p>
        `,
          de: `
        <p>
          Wenn Sie sich über den aktuellen Bearbeitungs¬status Ihrer Bewerbung informieren möchten, loggen Sie sich einfach 
          im Bewerbungs¬portal ein. 
        </p>
        `
        }
      },
      {
        id: 7,
        text: {
          en: `Make the most of your time`,
          de: `Die Zeit sinnvoll nutzen`
        },
        modal: {
          en: `
            <p>
              It seems to be a long time until you receive the letter of admission, so we have put together a few tips for you on how to use it wisely.
            </p>
            <p>
            <i class="fas fa-info-circle"></i> Please note: 
              If you need a visa to enter Germany in case you are admitted to the University of Mannheim, you should make an appointment 
              with the German Embassy or the Consulate as eraly as possible. We recommend that you also get in touch with our International 
              Office. The team for international degree-seeking students will be happy to provide advice and support. 
              You can find their contact details <a href="https://www.uni-mannheim.de/en/academics/coming-to-mannheim/international-students/" target="_blank">here</a>.
            </p>
        `,
          de: `
        <p>
          Die Zeit bis zum Erhalt des Bescheids erscheint lang, deshalb haben wir ein paar Tipps zusammengestellt, wie Sie sie 
          sinnvoll nutzen können.
        </p>
        <p>
        <i class="fas fa-info-circle"></i> Tipp:
          Brauchen Sie im Falle einer Zulassung für Ihre Einreise nach Deutschland ein Visum?
          Dann sollten Sie umgehend einen Termin bei der Deutschen Botschaft bzw. dem Konsulat vereinbaren! 
          Nehmen Sie am besten auch gleich Kontakt zum Akademischen Auslandsamt der Uni Mannheim auf. 
          Das Team für internationale Vollzeitstudierende unterstützt Sie gerne. Die Kontaktdaten finden Sie
          <a href="https://www.uni-mannheim.de/studium/vom-ausland-nach-mannheim/internationale-vollzeitstudierende/" target="_blank">hier</a>. 
        </p>
        `
        }
      },
      {
        id: 8,
        indented: true,
        text: {
          en: `Prepare for your studies`,
          de: `Bereite Sie sich auf das Studium vor`
        },
        modal: {
          en: `
            <p>
              Read the examination regulations and the module catalog for your program in order to be able to plan your degree. 
              For detailed information please see the 
              <a href="https://www.uni-mannheim.de/en/information-for/freshmen/information-on-your-program" target="_blank">respective school’s websites for freshmen</a>. 
            </p>
        `,
          de: `
            <p>
              Für jeden Studien¬gang gibt es wichtige Dokumente, wie die Prüfungsordnung oder das Modulhandbuch, 
              die Sie kennen müssen, um Ihr Studium gut zu planen. Alle Informationen dazu sind auf den 
              <a href="https://www.uni-mannheim.de/infos-fuer/erstsemester/infos-zu-ihrem-studiengang/" target="_blank">Erstsemesterseiten 
              der Fakultäten</a> zu finden. 
            </p>
        `
        }
      },
      {
        id: 9,
        indented: true,
        text: {
          en: `Find accommodation`,
          de: `Finden Sie eine Unterkunft`
        },
        modal: {
          en: `
            <p>
              The Studierendenwerk has around 3,000 rooms available in 17 student residence halls throughout the city. 
              Of course, you can also take a look on the private market. For more information please see: 
              <a href="https://www.uni-mannheim.de/en/information-for/freshmen/housing/#c66645" target="_blank">https://www.uni-mannheim.de/en/information-for/freshmen/housing/#c66645</a>.
            </p>
            <p>
            <i class="fas fa-lightbulb"></i> Tip: 
              You do not need to be enrolled in order to apply for a room in a student residence hall. It pays to be quick. 
              Find out more under <a href="https://www.stw-ma.de/en/Housing.html" target="_blank">https://www.stw-ma.de/en/Housing.html</a>.
              We generally recommend that you start looking for accommodation as early as possible. However, you should not sign a rental agreement 
              until you are sure that you have been admitted to the University of Mannheim.
            </p>
        `,
          de: `
            <p>
              Das Studierenden¬werk Mannheim verwaltet insgesamt rund 3.000 Wohnplätze in 17 Anlagen, die sich über das gesamte Stadtgebiet verteilen. Natürlich kann man auch über den privaten Wohnungsmarkt etwas finden. 
              Infos dazu gibt es unter <a href="https://www.uni-mannheim.de/infos-fuer/erstsemester/wohnen/" target="_blank">
              https://www.uni-mannheim.de/infos-fuer/erstsemester/wohnen/</a>
            </p>
            <p>
            <i class="fas fa-lightbulb"></i> Tipp: 
              Für eine Bewerbung um einen Wohnheimplatz muss man noch nicht eingeschrieben sein – schnell sein lohnt sich!
              Informieren Sie sich darüber unter <a href="https://www.stw-ma.de/Wohnen.html" target="_blank">https://www.stw-ma.de/Wohnen.html</a>
              Allgemein ist es ratsam schon früh mit der Suche nach einer Unterkunft zu beginnen. Allerdings sollten Sie keinen Mietvertrag unterschreiben, 
              bevor Sie eine definitive Zulassung zum Studium haben!
            </p>
        `
        }
      },
      {
        id: 10,
        indented: true,
        text: {
          en: `Check your financing options`,
          de: `Planen Sie die Finanzierung`
        },
        modal: {
          en: `
            <p>
              When it comes to financing your studies, there are many financial support options available such as BAföG, 
              various scholarships or student jobs. To find the right solution, you should look into the various options 
              early on and learn about the respective requirements and deadlines. 
              Check out our website under <a target="_blank" href="https://www.uni-mannheim.de/en/academics/advice-and-services/financing/">https://www.uni-mannheim.de/en/academics/advice-and-services/financing/</a>
            </p>
        `,
          de: `
            <p>
              BAföG, Stipendium oder Nebenjob - es gibt viele Möglichkeiten das Studium zu finanzieren. 
              Um die passende Finanzierung zu finden, sollte man sich frühzeitig über Voraussetzungen und 
              Fristen informieren. Schauen Sie doch mal auf unsere Webseite 
              unter <a href="https://www.uni-mannheim.de/studium/beratung-und-service/finanzierung/" target="_blank">https://www.uni-mannheim.de/studium/beratung-und-service/finanzierung/</a>
            </p>
        `
        }
      },
      {
        id: 11,
        text: {
          en: `The letter of admission/rejection arrives`,
          de: `Der Bescheid kommt`
        },
        modal: {
          en: `
            <p>
              You have received a letter of admission – congratulations!
            </p>
            <p>
            <i class="fas fa-lightbulb"></i> Tip:
              Read the letter of admission carefully. It contains important information such as the deadline for enrollment, 
              a list of the required documents, and the dates of the Welcome Week.
            </p>
            <p>
              You have received a letter of rejection – unfortunately, you have not been admitted to the University of Mannheim. 
              The letter of rejection states the reasons for your rejection; hence, it might be worth reading it carefully.
            </p>
            <p>
            <i class="fas fa-lightbulb"></i> Tip:
              If you have received a so-called “Rangablehnung” and places become available in your desired program of study, 
              you may get a place through clearing. For more information please see the letter of rejection.
            </p>
        `,
          de: `
            <p>
              Sie haben einen Zulassungsbescheid erhalten – Herzlichen Glückwunsch! 
            </p>
            <p>
            <i class="fas fa-lightbulb"></i> Tipp:
              Lesen Sie Ihren Zulassungsbescheid aufmerksam durch! Sie finden darin viele wichtige und nützliche Informationen, 
              z.B. die Frist für Ihre Immatrikulation, die Dokumente, die Sie dafür brauchen und den Termin der Einführungswoche 
              an der Universität Mannheim. 
            </p>
            <p>
              Sie haben einen Ablehnungsbescheid erhalten – dann hat es leider mit der Zulassung nicht geklappt. 
              Im Ablehnungsbescheid wird begründet, warum Sie nicht zugelassen werden konnten. Es lohnt sich also, 
              den Bescheid aufmerksam durchzulesen.
            </p>
            <p>
            <i class="fas fa-lightbulb"></i> Tipp:
              Falls Sie eine sogenannte Rangablehnung bekommen haben und in dem von Ihnen gewünschten Studiengang noch 
              Plätze frei werden sollten, kann ein Nachrückverfahren stattfinden. Infos zur Teilnahme finden Sie in Ihrem Bescheid.
            </p>
        `
        }
      },
      {
        id: 12,
        text: {
          en: `Enroll at the University of Mannheim`,
          de: `Immatrikulieren Sie sich an der Uni Mannheim`
        },
        modal: {
          en: `
            <p>
              Your letter of admission contains all important information regarding your enrollment such as the deadline 
              and the required documents as well as information on whether you have to enroll in person or by mail
            </p>
        `,
          de: `
            <p>
              Alle wichtigen Infos zur Immatrikulation, z.B. zur Frist, zu den Dokumenten, 
              die Sie dafür brauchsen und auch ob Sie sich per Post oder persönlich immatrikulieren müssen, 
              findesn Sie auf dem Zulassungsbescheid. 
            </p>
        `
        }
      }
    ]
  },
  //NEW ITEM
  {
    title: {
      en: `August 31 to September 4`,
      de: `31. August bis 4. September`
    },
    content: {
      en: `
      <p>
        <strong>
          Attend the Welcome Week
        </strong>
      </p>
      <p>
        The orientation events of the Welcome Week will help you find your way around the University and its campus. 
        They are also a good opportunity to get to know your fellow students. The dates of the individual events can be found 
        <a href="https://www.uni-mannheim.de/en/information-for/freshmen/information-on-your-program/" target="_blank">here</a>.
      </p>
      `,
      de: `
      <p>
        <strong>
          Seien Sie bei der Einführungswoche dabei!
        </strong>
      </p>
      <p>
        Die Einführungs¬veranstaltungen helfen bei der Orientierung an der Universität und sind eine gute Gelegenheit, 
        um erste Kontakte zu knüpfen. Die Termine für die einzelnen Veranstaltungen finden Sie 
        <a href="https://www.uni-mannheim.de/infos-fuer/erstsemester/infos-zu-ihrem-studiengang/" target="_blank">hier</a>.
      </p>
      `
    },
    subs: []
  },
  //NEW ITEM
  {
    title: {
      en: `September 7`,
      de: `7. September: Beginn der Vorlesungszeit`
    },
    content: {
      en: `
      <p>
        <strong>
          Start of the lecture period 
        </strong>
      </p>
      <p>
        On September 7, the lecture period of the University of Mannheim starts and seminars and exercise courses begin. 
        An overview of all important dates in the academic calendar of the University of Mannheim is provided 
        <a href="https://www.uni-mannheim.de/en/academics/dates/academic-calendar/" target="_blank">here</a>.
      </p>
      `,
      de: `
      <p>
        <strong>
          Es ist soweit – die Vorlesungszeit beginnt!
        </strong>
      </p>
      <p>
        Ab dem 7. September starten die Vorlesungen, Seminare und Übungen an der Uni Mannheim. 
        Einen Überblick über weitere wichtige Termine des Semesters finden Sie 
        <a href="https://www.uni-mannheim.de/studium/termine/semesterzeiten/#c955" target="_blank">hier</a>.
      </p>
      `
    },
    subs: []
  },
  //NEW ITEM
  {
    title: {
      en: `September 12: Mannheim Schlossfest`,
      de: `12. September: Mannheimer Schlossfest`
    },
    content: {
      en: `
      <p>
        <strong>
          Do not miss this event!  
        </strong>
      </p>
      <p>
        Traditionally, the University begins the fall semester with a big festival. 
        At the beginning of September, everyone is invited to the Schlossfest, including the freshmen. 
        On this day, the University opens its doors and presents the schools' current research activities. 
        In addition, a most varied program of music, art, and culture provides entertainment on numerous stages 
        throughout the palace grounds. Take a look <a href="https://www.uni-mannheim.de/en/the-schlossfest/" target="_blank">here</a>.
      </p>
      `,
      de: `
      <p>
        <strong>
          Ein Event, das Sie nicht verpassen sollten
        </strong>
      </p>
      <p>
        Traditionell beginnt die Universität das neue Semester mit einem großen Fest. 
        Zum Schlossfest Anfang September sind auch die Erstsemester¬studierenden herzlich eingeladen. 
        Wir öffnen Türen und Tore und zeigen, zu welchen Themen in den verschiedenen Fakultäten geforscht wird. 
        Darüber hinaus sorgt ein buntes Programm aus Musik, Kunst und Kultur für Unterhaltung. 
        Schau Sie es sich <a href="https://www.uni-mannheim.de/schlossfest/" target="_blank">hier</a> an.
      </p>
      `
    },
    subs: []
  }
];
