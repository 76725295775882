import React, { Component } from "react";
import styled from "styled-components";

import FacultyList from "./FacultyList";
import ModulePanel from "./ModulePanel";
import ContentPanel from "./ContentPanel";
import { Loader } from "semantic-ui-react";

import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
import withContext from "../Context/withContext";

export class Information extends Component {
  state = {
    loading: true,
    departments: []
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let departments = await API.graphql(
      graphqlOperation(queries.GET_ALL_DEPARTMENTS)
    );
    this.setState({
      departments: departments.data.allDepartments,
      loading: false
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <FacultyWrapper>
            <Loader active inverted size="massive" />
          </FacultyWrapper>
        </>
      );
    } else {
      return (
        <>
          <FacultyWrapper>
            <FacultyList
              departments={this.state.departments}
              activeDept={this.props.state.activeDept}
              scrollToInfo={this.props.scrollToInfo}
            />
          </FacultyWrapper>
          {this.props.state.activeDept && (
            <ContainerWrapper>
              <Bottom>
                <ModulePanel />
                <ContentPanel />
              </Bottom>
            </ContainerWrapper>
          )}
        </>
      );
    }
  }
}

export default withContext(Information);

const ContainerWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 65vh;
  height: 90vh;
  max-height: 90vh;
  @media (max-width: 800px) {
    max-height: 130vh;
    height: auto;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex: 10;
  overflow: hidden;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const FacultyWrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 1rem;
`;
