export const ON_CREATE_MESSAGE = `
  subscription onCreateMessage ($data: ID){
    onCreateNewMessage (data: $data){
      authorId
      createdAt
      body
    }
  }
`

export const ON_EVENT_ACTIVE_TOGGLE = `
  subscription onEventUpdateToggle {
    onUpdateEventActivation {
      active
    }
  }
`

export const ON_FLAG_MESSAGE = `
  subscription onFlagMessage {
    onFlagMessage {
      body
    }
  }
`

export const ON_UPDATE_STREAM = `
  subscription onUpdateStream {
    onUpdateStream{
      entityId
      stream
      comments
    }
  }
`