import React, { Component } from "react";
import MMMCard from "./MMMCard";
import MMMBucket from "./MMMBucket";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import MMMMessage from "./MMMMessage.js";
import "./MMM.scss";

class MMM extends Component {
  state = {
    chooseCards: [
      {
        id: "1",
        prio: "essential",
        title: "Five Core Courses",
        desc:
          "You have to choose five core courses: Decision Analysis, Applied Econometrics OR Empirical Methods, Corporate Social Responsibility, Business Economics I & II"
      },
      {
        id: "2",
        prio: "essential",
        title: "Master thesis",
        desc: (
          <>
            <p>
              At the end of your studies, you will write your master‘s thesis at
              one of our chairs in the area of your choosing. To give you an
              idea of what it's like to conduct scientific research, the
              different chairs give you the opportunity to write a seminar paper
              in one of the semesters leading up to your thesis. You can find
              each chair's prerequisites for the master thesis in our{" "}
              <a
                href="https://www.bwl.uni-mannheim.de/media/Fakultaeten/bwl/Dokumente/Studium/MMM/Modulkatalog_MMM_Englisch.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                module catalog
              </a>
              .
            </p>
          </>
        )
      },
      {
        id: "3",
        prio: "must",
        title: "Courses: Area Accounting and Taxation",
        desc: (
          <>
            <p>
              <a
                href="https://www.bwl.uni-mannheim.de/en/area-accounting-taxation/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Area Website
              </a>
            </p>
            <p>
              The course offer of the area of Accounting and Taxation might
              especially interest students who want to learn about accounting
              systems, auditing, capital markets and taxation. The chairs of
              this area cover interesting fields such as International Business
              Taxation, Corporate Valuation or Tax Harmonization in the EU.
              Students can choose from courses such as “IFRS Reporting and
              Capital Markets” or “Financial Statement Analysis and Equity
              Valuation”.{" "}
            </p>
            <p>Examples for topics of master theses in this area:</p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>International tax planning of multinational corporations</li>
              <li>Taxation of IT companies and e-commerce</li>
              <li>
                The impact of international tax law on investment and financing
                decisions
              </li>
              <li>Use of equity and debt capital and its tax consequences</li>
              <li>
                Public Perception of Accounting Issues/Lobbying for Accounting
                Standard Changes
              </li>
              <li>Capital Market Communication and Corporate Narratives</li>
              <li>
                Issues of cross-border activities of firm entities of different
                legal forms
              </li>
            </ul>
          </>
        )
      },
      {
        id: "4",
        prio: "must",
        title: "Courses: Area Banking, Finance and Insurance",
        desc: (
          <>
            <p>
              <a
                href="https://www.bwl.uni-mannheim.de/en/finance/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Area Website
              </a>
            </p>
            <p>
              This area is for students who have a good understanding of numbers
              and want to understand financial markets. Our chairs in this area
              cover a wide range of interesting topics in their research, such
              as “Gender Differences on Financial Markets” or “The Optimal
              Design of Stock Options”. In their courses, you can expect to
              learn about corporate finance, investments or behavioral finance.{" "}
            </p>
            <p>Past master theses in this area:</p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>Quantitative Investing with a Focus on Low-Risk Stocks</li>
              <li>
                The Acquisition of Shire Plc. by Takeda Pharmaceuticals Company
                Ltd
              </li>
              <li>
                Alternative forms of incorporation in Germany – the case of the
                KGaA
              </li>
              <li>Behavioral biases among mutual fund managers</li>
              <li>
                Market Liquidity and Funding Liquidity in the Corporate Bond
                Market
              </li>
              <li>
                Stock Return Predictability and International Financial
                Integration
              </li>
              <li>The Characteristics of Sin Stocks</li>
            </ul>
          </>
        )
      },
      {
        id: "5",
        prio: "must",
        title: "Courses: Area Information Systems",
        desc: (
          <>
            <p>
              <a
                href="https://www.bwl.uni-mannheim.de/en/information-systems/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Area Website
              </a>
            </p>
            <p>
              This area is for all the tech-savvy students out there (or those
              who want to become ones)! In the area of Information Systems, you
              can not only learn the fundamentals of programming, but can also
              for example also deepen your knowledge of databases, software
              development and e-businesses. Courses like “Management of IT in
              the Digital Age” or “Pervasive Computing” pave the way for your
              career in the IT sector.
            </p>
            <p>Examples for topics of master theses in this area:</p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>
                The role of trust in current applications and e-commerce use
              </li>
              <li>
                IT's for girls … or is it? Determinants of girls' adoption and
                rejection of gender-specific games
              </li>
              <li>Mobile Application Adoption</li>
              <li>Consumer Reactions to Data Security Breaches</li>
              <li>Smart Grid and Electromobility</li>
              <li>
                Gaze-based Human-Computer Interaction and Visual Input Systems
                for Mobile Devices
              </li>
            </ul>
          </>
        )
      },
      {
        id: "6",
        prio: "must",
        title: "Courses: Area Management",
        desc: (
          <>
            <p>
              <a
                href="https://management.bwl.uni-mannheim.de/home/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Area Website
              </a>
            </p>
            <p>
              The area of management covers a wide range of topics, from HR
              Management, Strategy Management and Entrepreneurship to Public and
              Nonprofit Management or Innovation. Since 2015, the area also
              includes the Chair of Corporate Social Responsibility. The course
              offer of the area is as diverse as its chairs – you can for
              example choose from courses like “Entrepreneurial Spirit”,
              “Leadership and Motivation”, “Mergers and Acquisition” or “CSR
              Video Documentaries”.{" "}
            </p>
            <p>Examples for topics of master theses in this area:</p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>
                Stakeholder Reactions to Corporate Social Responsibility – A
                Review and Analysis of Existing Measurement Instruments
              </li>
              <li>Is corporate philanthropy legitimate? An interview study</li>
              <li>Organizational behavior with a focus on teams</li>
              <li>Financing of innovation</li>
              <li>Business models in the sharing economy</li>
              <li>International entrepreneurship</li>
              <li>
                Entwicklung von psychologischem Kapital in
                Nonprofit-Organisationen
              </li>
              <li>Verwaltungen internationaler (Regierungs-) Organisationen</li>
              <li>Erfolgsmessung in gemeinnützigen Kulturbetrieben</li>
            </ul>
          </>
        )
      },
      {
        id: "7",
        prio: "must",
        title: "Courses: Area Marketing and Sales",
        desc: (
          <>
            <p>
              <a
                href="https://www.bwl.uni-mannheim.de/en/kuester/teaching/chairs-of-the-marketing-sales-area/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Area Website
              </a>
            </p>
            <p>
              As is the case for all our areas, the University of Mannheim is
              proud of its renowned professors in the field of Marketing and
              Sales. From Global Marketing to Services Marketing or B2B
              Marketing, you can learn all the relevant fundamentals you need to
              know to become successful in the field of Marketing and Sales. Our
              chairs also conduct exciting new research in the fields of Digital
              Marketing and Social Media, so that you will stay on top of the
              latest trends.
            </p>
            <p>Examples for topics of master theses in this area:</p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>
                The Role of Negative Emotions in Buying at the Low Price:
                Attributional Approach
              </li>
              <li>
                Customer retention and win-back in times of digital customer
                relationships
              </li>
              <li>
                The relationship between exciting store atmosphere and
                motivational shopping orientation in consumer behavior
              </li>
              <li>
                The effect of package size on self-regulation for luxurious
                products
              </li>
              <li>
                To coin or not to coin: Trust and Pricing of Cryptocurrencies
              </li>
              <li>
                How Netflix Changes the Way Movies Are Made: Innovation
                Development in the Film Industry
              </li>
              <li>
                The Seven Deadly Sins in Social Media – How Social Media
                Platforms Address our Abject Desires
              </li>
              <li>
                Fake but famous – An Investigation of the Employment of Virtual
                Influencers for Marketing Purposes
              </li>
            </ul>
          </>
        )
      },
      {
        id: "8",
        prio: "must",
        title: "Courses: Operations Management",
        desc: (
          <>
            <p>
              <a
                href="https://operations.bwl.uni-mannheim.de/en/chairs/area_operations_management/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Area Website
              </a>
            </p>
            <p>
              In this area, you can learn all you need to know about
              procurement, productions management and logistics. Whether you are
              interested in becoming a Supply Chain Manager or Production
              Planner, our chairs in this area will help you get there! With
              courses like “Cases on Current Issues in Supply Chain Management”
              or “Demand-Driven Adaptive Supply Chain Planning”, you can be sure
              to learn all about recent developments.{" "}
            </p>
            <p>Examples for topics of master theses in this area:</p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>
                Designing Fixed Delivery Areas for the Multi-Depot Vehicle
                Routing Problem
              </li>
              <li>Open Innovation in New Product Development</li>
              <li>Capacity Planning of Fitness Studio using Simulation</li>
              <li>
                Estimating Choice Data for the Itinerary Choice of Railway
                Passengers
              </li>
              <li>
                Variability analysis in digitalized semiconductor supply chains
              </li>
              <li>Robust optimization for assembly line balancing</li>
            </ul>
          </>
        )
      },
      {
        id: "9",
        prio: "noncomb",
        title: "Semester Abroad",
        desc: (
          <>
            <p>
              You’re keen to gain international experience and strengthen your
              intercultural skills? Then you might want to consider spending a
              semester abroad within the MMM! Our students can choose from over
              100 exchange destinations, many MBAs and specialized programs
              among them. Your semester abroad will take place in the third or
              fourth semester and will take you to one of our partner
              universities around the world for a time full of exciting new
              experiences. You can find further information{" "}
              <a
                href="https://www.bwl.uni-mannheim.de/en/international-affairs/study-abroad-outgoings/mannheim-master-in-management/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
          </>
        )
      },
      {
        id: "10",
        prio: "noncomb",
        title: "Double Degree",
        desc: (
          <>
            <p>
              Our seven international Mannheim Master in Management (MMM) double
              degree programs really allow you to make the most of your studies.
              You will spend half of your time studying in Mannheim, and the
              other half at one of our renowned partner universities abroad.
              This way, you will earn two degrees in two years! Here is the list
              of our partner universities:{" "}
            </p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>Università Bocconi, Italy</li>
              <li>Copenhagen Business School, Denmark</li>
              <li>ESSEC Business School, France</li>
              <li>National Chengchi University, Taiwan</li>
              <li>Norwegian School of Economics, Norway</li>
              <li>Queen’s University, Canada</li>
              <li>University of South Carolina, USA</li>
            </ul>
            <p>
              More information can be found{" "}
              <a
                href="https://www.bwl.uni-mannheim.de/en/international-affairs/mmm-double-degree-programs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </p>
          </>
        )
      },
      {
        id: "11",
        prio: "can",
        title: "Electives",
        desc: (
          <>
            <p>
              “Only” Business Administration is not enough for you? Then you
              should take a look at our electives! They allow you to broaden
              your horizon and learn about other academic fields such as
              politics, law or psychology. Each student can choose one of the
              following electives:
            </p>
            <ul style={{ marginLeft: "3rem" }}>
              <li>Business Education</li>
              <li>Computer Science</li>
              <li>Business Law</li>
              <li>Political Science</li>
              <li>Mathematics</li>
              <li>Psychology</li>
              <li>English and American Studies</li>
              <li>German Studies </li>
              <li>Romance Studies</li>
              <li>History</li>
              <li>Philosophy</li>
              <li>Sociology</li>
              <li>Economics</li>
              <li>Asian Studies </li>
            </ul>
            <p>
              You can find further information{" "}
              <a
                href="https://www.bwl.uni-mannheim.de/media/Fakultaeten/bwl/Dokumente/Studium/MMM/Wahlfachangebot.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </p>
          </>
        )
      }
    ],
    bucketCards: []
  };

  deleteFromBucket = id => {
    this.setState(prevState => {
      let currentItems = prevState.bucketCards;
      const index = currentItems.findIndex(item => item.id === id);
      let changingItem = currentItems.find(item => {
        return item.id === id;
      });
      prevState.chooseCards.push(changingItem);
      currentItems.splice(index, 1);
      return {
        bucketCards: currentItems
      };
    });
  };

  addItem = id => {
    this.setState(prevState => {
      let currentItems = prevState.chooseCards;
      const index = currentItems.findIndex(item => item.id === id);
      let changingItem = currentItems.find(item => {
        return item.id === id;
      });
      prevState.bucketCards.push(changingItem);
      currentItems.splice(index, 1);
      return {
        chooseCards: currentItems
      };
    });
  };

  validChoice = () => {
    if (this.state.bucketCards.length === 0) {
      return (
        <div>
          <h2
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "8rem",
              fontSize: "2.75rem"
            }}
          >
            Mannheim Master in Management
          </h2>
          <p
            style={{ color: "white", textAlign: "center", fontSize: "1.5rem" }}
          >
            Create your personal study plan!
          </p>
        </div>
      );
    }
    let essentials = this.state.bucketCards.filter(card => {
      return card.prio === "essential";
    });
    let musts = this.state.bucketCards.filter(card => {
      return card.prio === "must";
    });
    let noncomb = this.state.bucketCards.filter(card => {
      return card.prio === "noncomb";
    });
    if (noncomb.length > 1) {
      return (
        <MMMMessage
          success={false}
          title={"Oh no!"}
          text={
            "Unfortunately, you cannot combine a double degree and a semester abroad."
          }
        />
      );
    }

    if (essentials.length >= 2 && musts.length >= 1) {
      return (
        <MMMMessage
          success={true}
          title={"Great!"}
          text={"Your current selection of items works just fine."}
        />
      );
    } else if (essentials.length < 2) {
      return (
        <MMMMessage
          success={false}
          title={"Oh no!"}
          text={
            "Unfortunately, your current selection of items does not work out. You need to add the essentials first."
          }
        />
      );
    } else if (musts.length < 1) {
      return (
        <MMMMessage
          success={false}
          title={"Oh no!"}
          text={
            "Unfortunately, your current selection of items does not work out. You need to add at least courses from one area."
          }
        />
      );
    }
  };

  render() {
    return (
      <>
        <div style={{ paddingTop: "10rem" }}>{this.validChoice()}</div>

        <div className="mmm">
          <div className="cardlist">
            <h3 style={{ color: "white", textAlign: "center" }}>Options</h3>
            {this.state.chooseCards.map(card => {
              return (
                <MMMCard card={card} handleDrop={id => this.addItem(id)} />
              );
            })}
          </div>
          <MMMBucket
            cards={this.state.bucketCards}
            onDelete={this.deleteFromBucket}
          />
        </div>
      </>
    );
  }
}

export default DragDropContext(HTML5Backend)(MMM);
