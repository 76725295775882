export const GET_ALL_DEPARTMENTS = `
{
  allDepartments {
    title {
      en
      de
    }
    id
    entityId
  }
}
`;

export const GET_ALL_MODULES_ITEMS = `
  query GetDepartmentStartpage($deptId: String){
    getDepartment(deptId: $deptId) {
      modules {
        title {
          en
          de
        }
        day
        entityId
        sort
        items {
          sort
          title {
            en
            de
          }
          entityId
        }
      }
    }
  }
`;

export const GET_DEPARTMENT_STARTPAGE = `
  query GetDepartmentStartpage($deptId: String){
    getDepartment(deptId: $deptId) {
      title {
        en
        de
      }
      startpage {
        en
        de
      }
    }
  }
`;

export const GET_ITEM_EDITOR_STATE = `
query getItemEditorState($entityId: ID){
  getItem(entityId: $entityId) {
    editorState {
      en
      de
    }
    title {
      en
      de
    }
  }
}
`;

export const GET_ALL_EVENTS = `
  query getAllEvents {
    getAllEvents {
      title {
        en
        de
      }
      desc {
        en
        de
      }
      department {
        en
        de
        id
      }
      startTime
      endTime
      type
      language
      entityId
      active
      programs {
        en
        de
      }
      day
    }
  }
`;

export const GET_ALL_LEGACY_EVENTS = `
  query getAllLegacyEvents {
    getLegacyEvents {
      title {
        en
        de
      }
      desc {
        en
        de
      }
      eventId
      startTime
      endTime
      type
      language
      programs {
        en
        de
      }
      department {
        en
        de
        id
      }
    }
  }
`;

export const GET_EVENT_BY_ID = `
  query GetEventById($entityId: ID) {
    getEventById(entityId: $entityId) {
      title {
        en
        de
      }
      desc {
        en
        de
      }
      startTime
      endTime
      type
      language
      entityId
      active
      stream
      comments
      programs {
        en
        de
      }
      department {
        en
        de
        id
      }
      messages {
        data
        createdAt
        body
        authorId
        entityId
        reply
        replied
        replies {
          createdAt
          body
          authorId
          entityId
        }
        flag
      }
      moderators {
        email
        entityId
      }
      
    }
  }
`;

export const GET_LEGACY_USERS = `
  query LegacyUsers {
    getLegacyUsers {
      email
      interests
      userId
    }
  }
`;

export const GET_USER_BY_ID = `
  query GetUser($entityId: ID) {
    getUserById(entityId: $entityId) {
      entityId
      email
      firstName
      lastName
      position {
        de
        en
      }
      interests
      data
      image
      messageLikes {
        body
        authorId
        createdAt
      }
      likes {
        title {
          en
          de
        }
        deptId
        itemId
      }
    }
  }

`;

export const GET_ALL_USERS = `
  query AllUsers {
    getAllUsers {
      entityId
      firstName
      lastName
      position {
        en
        de
      }
      email
      data
      interests
      day
    }
  }
`;

export const GET_DEPT_STAFF = `
  query GetUserFromGroups ($poolId: ID){
    getUsersFromPool(poolId: $poolId) {
      email
      entityId
    }
  }
`;

export const GET_ALL_GROUPS = `
  query GetGroups {
    listAllGroups {
      Description
      GroupName
    }
  }
`;

export const GET_FLAGGED_MESSAGES = `
  query GetFlagged {
    getFlaggedMessages {
      createdAt
      data
      body
      authorId
      entityId
      reply
      replies {
        createdAt
        body
        authorId
        entityId
      }
      flag
    }
  }
`;

export const GET_SETTINGS = `
  query getSettings($eventName: String) {
    getSettings(eventName: $eventName) {
      loginStartDate
      loginEndDate
    }
  }
`;
