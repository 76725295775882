import React from 'react'
import styled from 'styled-components'
import { Header, Icon } from 'semantic-ui-react'
import withContext from '../Context/withContext'

const Wrapper = styled.div`
  min-height: 50vh;
  margin-top: 10rem;
`

const Bottom = (props) => (
      <Wrapper>
        <Header as='h2' icon textAlign='center' style={{cursor: "pointer"}} onClick={props.scrollToTop}>
          <Icon name='angle up' style={{color: "white"}} circular />
          <Header.Content style={{color: "white", marginBottom: '1rem'}}>
            {props.text[props.state.lang]}
          </Header.Content>
        </Header>
      </Wrapper>
    )
  
export default withContext(Bottom)
