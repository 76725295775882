import React, { Component } from "react";
import styled from "styled-components";
import { Header, Icon } from "semantic-ui-react";
import withContext from "../Context/withContext";
import timelinePoints from "./timelineData";

export class Timeline extends Component {
  state = {
    modal: ""
  };

  render() {
    return (
      <Wrapper>
        <Header
          as="h2"
          icon
          style={{ margin: "0 auto", marginBottom: "5rem", width: "95%" }}
          inverted
        >
          <Icon name="road" />
          {this.props.state.lang === "de" ? "Dein Weg" : "Your Way"}
          <Header.Subheader>
            {this.props.state.lang === "de"
              ? "an die Universität Mannheim"
              : "to the University of Mannheim"}
          </Header.Subheader>
        </Header>
        <TimeLineWrapper>
          <TimeLine>
            {timelinePoints.map(point => (
              <>
                <ContainerRight>
                  <Content>
                    <h2>{point.title[this.props.state.lang]}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: point.content[this.props.state.lang]
                      }}
                    ></div>
                  </Content>
                </ContainerRight>
                {point.subs.map(sub => (
                  <>
                    <Sub
                      style={sub.indented ? { paddingLeft: "4rem" } : {}}
                      onClick={
                        this.state.modal === sub.id
                          ? () => this.setState({ modal: "" })
                          : () => this.setState({ modal: sub.id })
                      }
                    >
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        <Icon
                          name={
                            this.state.modal === sub.id
                              ? "chevron circle down"
                              : "chevron circle right"
                          }
                        />{" "}
                        {sub.text[this.props.state.lang]}
                      </div>
                      {this.state.modal === sub.id && (
                        <SubModal>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sub.modal[this.props.state.lang]
                            }}
                          ></div>
                          {/* {sub.modal[this.props.state.lang]} */}
                        </SubModal>
                      )}
                    </Sub>
                  </>
                ))}
              </>
            ))}
          </TimeLine>
        </TimeLineWrapper>
      </Wrapper>
    );
  }
}

export default withContext(Timeline);

const TimeLineWrapper = styled.div`
  @media (max-width: 600px) {
    left: -60%;
  }
`;

const SubModal = styled.div`
  color: black;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-top: 2rem;
  margin-left: 2.25rem;
  background-color: white;
  padding: 2rem;
  border-radius: 5px;
  width: 70%;
`;

const Sub = styled.div`
  cursor: pointer;
  color: white;
  font-size: 1.75rem;
  left: 28%;
  position: relative;
  padding: 2rem 0;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 15px;
  //   height: 15px;
  //   border-radius: 50%;
  //   top: 42%;
  //   background-color: white;
  //   left: -16.2%;
  //   margin-right: 1rem;
  // }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   width: 2rem;
  //   height: 3px;
  //   top: 50%;
  //   background-color: white;
  //   left: -8.5%;
  //   margin-right: 1rem;
  //   @media (max-width: 700px) {
  //     display: none;
  //   }
  // }
`;

const Wrapper = styled.div`
  padding: 10rem;
  padding-top: 15rem;
  @media (max-width: 600px) {
    padding: 1rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`;

const TimeLine = styled.div`
  position: relative;
  max-width: 1200px;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 20%;
    margin-left: -6px;
  }
`;

const ContainerRight = styled.div`
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 70%;
  min-width: 50%;
  left: 20%;

  &:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #ff9f55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    left: 20%;
  }

  &:before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  &:after {
    left: -16px;
  }
`;

const Content = styled.div`
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  width: 300px;
  font-size: 1.25rem;
`;
