import React from 'react'
import Context from './Context'

const withContext = (Component) => {
  return (props) => (
    <Context.Consumer>
      {state => <Component {...props} state={state} />}
    </Context.Consumer>
  )
}

export default withContext;