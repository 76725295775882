import React, { Component } from "react";
import axios from "axios";
import { convertFromHTML, convertToRaw, ContentState } from "draft-js";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
// import {CustomContentStateConverter} from './utils/ContentStateConverter';

/*
For each department in the set make a post call to Create Department with the following things:
  id: Random id (or BWL/VWL/etc)
  entityType: department
  startpage: empty editorstate
  title: {
    de: dept.title,
    en: dept.title
  }

For each topic in the set create a module.
  id: random id
  title: {
    en: module.title,
    de: module.title
  }
  data: how to find, where it belongs?

For each item write data as module
- How to get module?

*/

export class Convert extends Component {
  syncDepts = async () => {
    let response = await axios.get("http://localhost:3000/days");
    let days = response.data;
    let departmentArray = days.map(day => {
      let blocksFromHTML = convertFromHTML(day.startpage);
      let state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const markup = convertToRaw(state);

      return {
        entityId: day._id,
        title: {
          en: day.title,
          de: day.title
        },
        startpage: {
          en: JSON.stringify(markup),
          de: JSON.stringify(markup)
        }
      };
    });

    // let DBwrite = departmentArray.map(dep => {
    //   let response = API.graphql(graphqlOperation(mutations.SYNC_DEPTARTMENTS, dep))

    // })
  };

  syncModules = async () => {
    let response = await axios.get("http://localhost:3000/days");
    let days = response.data;
    let modules = days.map(day => {
      let mod = day.topics.map(topic => {
        let input = {
          entityId: topic._id,
          deptId: day._id,
          title: {
            en: topic.title,
            de: topic.title
          }
        };
        let response = API.graphql(
          graphqlOperation(mutations.CREATE_MODULE_WITH_ID, input)
        );
      });
    });
  };

  syncItems = async () => {
    let response = await axios.get("http://localhost:3000/days");
    let days = response.data;
    let items = days.map(day => {
      let modules = day.topics.map(topic => {
        let items = topic.items.map(item => {
          let blocksFromHTML = convertFromHTML(item.html);
          let state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          const newBlockMap = state.getBlockMap().map(block => {
            const entityKey = block.getEntityAt(0);
            if (entityKey !== null) {
              const entityBlock = state.getEntity(entityKey);
              const entityType = entityBlock.getType();
              switch (entityType) {
                case "IMAGE": {
                  const newBlock = block.merge({
                    type: "atomic",
                    text: "img"
                  });
                  return newBlock;
                }
                default:
                  return block;
              }
            }
            return block;
          });
          const newContentState = state.set("blockMap", newBlockMap);
          const markup = convertToRaw(newContentState);

          // const markup = (convertToRaw(state))
          let input = {
            moduleId: topic._id,
            title: {
              en: item.title,
              de: item.title
            },
            editorState: {
              en: JSON.stringify(markup),
              de: JSON.stringify(markup)
            }
          };

          let response = API.graphql(
            graphqlOperation(mutations.SYNC_ITEM, input)
          );
          return response;
        });
      });
    });
  };

  syncEvents = async () => {
    let response = await API.graphql(
      graphqlOperation(queries.GET_ALL_LEGACY_EVENTS)
    );
    let events = response.data.getLegacyEvents;
    events.map(event => {
      let deptId;

      if (event.department.id == 1) {
        deptId = "5c548b623df54832f894645c";
      }
      if (event.department.id == 2) {
        deptId = "5c580ddc60344800048471f9";
      }
      if (event.department.id == 3) {
        deptId = "5c5cabb19363f60004c67145";
      }
      if (event.department.id == 4) {
        deptId = "5c5944e511f43b0004fee636";
      }
      if (event.department.id == 5) {
        deptId = "5c64057689535f00047f0adb";
      }
      if (event.department.id == 6) {
        deptId = "5c64057689535f00047q3445";
      }
      if (event.department.id == 7) {
        deptId = "5c548b623df54832f893753b";
      }

      let input = {
        title: event.title,
        desc: event.desc,
        type: event.type,
        department: {
          de: event.department.de,
          en: event.department.de,
          id: deptId
        },
        startTime: event.startTime,
        endTime: event.endTime,
        language: event.language,
        programs: event.programs
      };

      let responses = API.graphql(
        graphqlOperation(mutations.CREATE_EVENT, { input })
      );
    });
  };

  syncUsers = async () => {
    let legacyUsers = await API.graphql(
      graphqlOperation(queries.GET_LEGACY_USERS)
    );
    legacyUsers.data.getLegacyUsers.map(user => {
      let input = {
        email: user.email,
        entityId: user.userId,
        interests: user.interests
      };
      let copies = API.graphql(
        graphqlOperation(mutations.SYNC_LEGACY_USERS, { input })
      );
    });
  };

  render() {
    return (
      <div>
        {/* <button style={{marginTop: '10rem'}} onClick={this.syncDepts}>Sync Departments</button> */}
        {/* <button style={{marginTop: '10rem'}} onClick={this.syncModules}>Sync Departments</button> */}
        {/* <button style={{marginTop: '10rem'}} onClick={this.syncItems}>Sync Departments</button> */}
        {/* <button style={{marginTop: '10rem'}} onClick={this.syncEvents}>Sync Events</button> */}
        {/* <button style={{marginTop: '10rem'}} onClick={this.syncUsers}>Sync Users</button> */}
      </div>
    );
  }
}

export default Convert;
