import React, { Component } from 'react'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'
import {Segment, Header, Form, Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import withContext from './Context/withContext'

export class ForgotPassword extends Component {

  state = {
    email: "",
    isSendingCode: false,
    codeSent: false,
    confirmed: false,
    code: "",
    password: "",
    isConfirming: false
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSendCodeClick = async event => {
    event.preventDefault()

    this.setState({isSendingCode: true})

    try {
      await Auth.forgotPassword(this.state.email)
      this.setState({ codeSent: true })
    } catch (err) {
      alert(err.message)
      this.setState({ isSendingCode: false })
    }
  }

  handleConfirmClick = async event => {
    event.preventDefault()

    this.setState({isConfirming: true})

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (err) {
      alert(err.message)
      this.setState({isConfirming: false})
    }
  }

  renderRequestCodeForm = () => {
    return(
      <Wrapper>
        <Segment>
          <Header style={{fontSize: '2rem'}} textAlign="left">
            {this.props.state.lang === "en"
              ? "Forgot Password"
              : "Passwort vergessen"
            }
          </Header>
          <Form onSubmit={this.handleSendCodeClick}>
            <Form.Field>
              <label>Email</label>
              <input 
                name="email"
                value={this.state.email} 
                onChange={this.handleChange}
                placeholder='Email' />
            </Form.Field>
            <Button loading={this.state.isSendingCode} type='submit'>
              {this.props.state.lang === "en"
                  ? "Submit"
                  : "Senden"
                }
            </Button>
          </Form>
        </Segment>
      </Wrapper>
    )
  }

  renderConfirmationForm = () => {
    return (
      <Wrapper>
        <Segment>
          <Header style={{fontSize: '2rem'}} textAlign="left">
            {this.props.state.lang === "en"
                ? "Forgot Password"
                : "Passwort vergessen"
              }
          </Header>
          <p>
            {this.props.state.lang === "en"
              ? "Please check your email for the confirmation code, then choose a new password."
              : "Bitte überprüfe deine Emails für den Bestätigunscode. Danach kannst du dein Passwort ändern."
            }
          </p>
          <Form onSubmit={this.handleConfirmClick}>
            <Form.Field>
              <label>Code</label>
              <input 
                name="code"
                value={this.state.code} 
                onChange={this.handleChange}
                placeholder='Code' />
            </Form.Field>
            <Form.Field>
              <label>
                {this.props.state.lang === "en"
                  ? "New Password"
                  : "Neues Passwort"
                }
              </label>
              <input 
                name="password"
                value={this.state.password} 
                onChange={this.handleChange}
                placeholder={this.props.state.lang === "en" ? 'Password' : 'Passwort'}
                type="password" />
            </Form.Field>
            <Button loading={this.state.isConfirming} type='submit'>
              {this.props.state.lang === "en"
                ? "Submit"
                : "Senden"
              }
            </Button>
          </Form>
        </Segment>
      </Wrapper>
    )
  }

  renderSuccessMessage = () => {
    return (
      <Wrapper>
        <Segment>
          <Header style={{fontSize: '2rem'}} textAlign="left">
            {this.props.state.lang === "en"
                ? "Success!"
                : "Passwort zurückgesetzt!"
              }
          </Header>
          <p> 
            { this.props.state.lang === 'en'
              ? "Your password has been reset. You may now login with your new password."
              : "Dein Passwort wurde geändert. Du kannst dich jetzt mit deinem neuen Passwort einloggen."
            }
            
          </p>
          <Button onClick={() => this.props.history.push('/')}>
            {this.props.state.lang === "en"
              ? "Return to Homepage"
              : "Zurück zur Homepage"
            }
            
          </Button>
        </Segment>
      </Wrapper>
    )
  }

  render() {
    return(
      <>
      {!this.state.codeSent
        ? this.renderRequestCodeForm()
        : !this.state.confirmed
          ? this.renderConfirmationForm()
          : this.renderSuccessMessage()
      }
      </>
    )
  }
}

export default withContext(withRouter(ForgotPassword))

const Wrapper = styled.div`
  width: 30%;
  margin: 0 auto
  padding-top: 15rem;
  @media (max-width: 800px) {
    width: 90%
  }
`