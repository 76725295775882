import React, { Component } from "react";
import styled from "styled-components";

import { Menu, Loader, Button, Icon, Accordion } from "semantic-ui-react";
import withContext from "../Context/withContext";

import { API, graphqlOperation, Analytics } from "aws-amplify";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import SelectionModal from "./SelectionModal";

import { SortableContainer, SortableElement } from "react-sortable-hoc";

import arrayMove from "array-move";
import { colors } from "../css-config";

const SortableItem = SortableElement(({ item }) => (
  <Menu.Item active={false}>
    <Icon name="move" /> {item.title.de}
  </Menu.Item>
));

const SortableList = SortableContainer(({ items }) => (
  <div>
    {items.map((item, index) => (
      <SortableItem item={item} index={index} key={`item-${item.title.de}`} />
    ))}
  </div>
));

export class ModulePanel extends Component {
  state = {
    visible: true,
    modules: [],
    loading: false,
    modal: false,
    titleDE: "",
    titleEN: "",
    edit: false,
    item: "",
    ordering: false,
    orderingModule: "",
    sortableItems: [],
    activeIndex: null,
    orderModules: false
  };

  async componentDidMount() {
    await this.fetching();
  }

  fetching = async () => {
    this.setState({ loading: true });
    let response = await API.graphql(
      graphqlOperation(queries.GET_ALL_MODULES_ITEMS, {
        deptId: this.props.state.activeDept
      })
    );
    let filteredModules = response.data.getDepartment.modules.filter(mod => {
      if (mod.day === "both") {
        return mod;
      }
      return mod.day === process.env.REACT_APP_DAY;
    });
    this.setState({
      modules: filteredModules,
      loading: false
    });
  };

  async componentWillReceiveProps(newProps) {
    if (newProps.state.activeDept !== this.props.state.activeDept)
      await this.setState({ loading: true });
    let response = await API.graphql(
      graphqlOperation(queries.GET_ALL_MODULES_ITEMS, {
        deptId: this.props.state.activeDept
      })
    );
    let filteredModules = response.data.getDepartment.modules.filter(mod => {
      if (mod.day === "both") {
        return mod;
      }
      return mod.day === process.env.REACT_APP_DAY;
    });
    this.setState({
      modules: filteredModules,
      loading: false
    });
  }

  showModal = e => {
    this.setState({ modal: true });
  };

  addItem = async (e, id) => {
    let title = {
      en: this.state.titleEN,
      de: this.state.titleDE
    };

    let moduleId = id;

    let response = await API.graphql(
      graphqlOperation(mutations.CREATE_EMPTY_ITEM, { title, moduleId })
    );

    this.setState({
      modal: false,
      titleEN: "",
      titleDE: "",
      module: ""
    });
    window.location.reload();
  };

  close = () => {
    this.setState({
      modal: false,
      titleDE: "",
      titleEN: "",
      module: "",
      item: ""
    });
  };

  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  };

  delete = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      let response = API.graphql(
        graphqlOperation(mutations.DELETE_ITEM, { entityId: id })
      );
      window.location.reload();
    }
    return;
  };

  edit = async (e, id) => {
    let title = {
      en: this.state.titleEN,
      de: this.state.titleDE
    };
    let response = await API.graphql(
      graphqlOperation(mutations.UPDATE_ITEM_TITLE, { entityId: id, title })
    );
    this.close();
    window.location.reload();
  };

  createModule = async () => {
    let titleEN = prompt("English Title");
    let titleDE = prompt("German Title");
    if (titleDE !== null && titleEN !== null) {
      let response = await API.graphql(
        graphqlOperation(mutations.CREATE_MODULE, {
          deptId: this.props.state.activeDept,
          title: { de: titleDE, en: titleEN },
          day: process.env.REACT_APP_DAY
        })
      );
      window.location.reload();
    }
  };

  moduleDelete = async (e, id) => {
    if (window.confirm("Do you want to delete this module?")) {
      let response = await API.graphql(
        graphqlOperation(mutations.DELETE_MODULE, { entityId: id })
      );
      window.location.reload();
    }
    return;
  };

  editModule = async (e, id) => {
    let titleEN = prompt("New English Title");
    let titleDE = prompt("New German Title");
    if (titleDE !== null && titleEN !== null) {
      let response = await API.graphql(
        graphqlOperation(mutations.UPDATE_MODULE, {
          entityId: id,
          title: { de: titleDE, en: titleEN }
        })
      );
      window.location.reload();
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (!this.state.orderModules) {
      this.setState(({ sortableItems }) => ({
        sortableItems: arrayMove(sortableItems, oldIndex, newIndex)
      }));
    } else {
      this.setState(({ modules }) => ({
        modules: arrayMove(modules, oldIndex, newIndex)
      }));
    }
  };

  toggleOrder = async mod => {
    if (this.state.ordering) {
      let arraySorted = await Promise.all(
        this.state.sortableItems.map((item, index) => {
          API.graphql(
            graphqlOperation(mutations.SET_SORT, {
              entityId: item.entityId,
              sort: index
            })
          );
          return {
            id: item.entityId,
            index: index
          };
        })
      );
      this.setState({ sortableItems: [], ordering: false, orderingModule: "" });
      this.fetching();
    } else {
      this.setState({
        sortableItems: mod.items,
        orderingModule: mod.entityId,
        ordering: true
      });
    }
  };

  orderModules = async () => {
    let arraySorted = await Promise.all(
      this.state.modules.map((item, index) => {
        API.graphql(
          graphqlOperation(mutations.SET_SORT, {
            entityId: item.entityId,
            sort: index
          })
        );
        return {
          id: item.entityId,
          index: index
        };
      })
    );
    this.setState({ orderModules: false });
    this.fetching();
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  translateDept = () => {
    switch (this.props.state.activeDept) {
      case "5c548b623df54832f893753b":
        return "Uni Allgemein";
      case "5c548b623df54832f894645c":
        return "BWL";
      case "5c580ddc60344800048471f9":
        return "Jura";
      case "5c5944e511f43b0004fee636":
        return "Phil";
      case "5c5cabb19363f60004c67145":
        return "VWL";
      case "5c64057689535f00047f0adb":
        return "WIM";
      case "5c64057689535f00047q3445":
        return "SoWi";
      default:
        return "na";
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader active />;
    } else {
      return (
        <Left>
          <Menu
            fluid
            pointing
            vertical
            style={{ minHeight: "100%" }}
            size="large"
          >
            {!this.state.orderModules ? (
              this.state.modules
                .sort((a, b) => a.sort - b.sort)
                .map((mod, index) => (
                  <Menu.Item key={index}>
                    <Accordion>
                      <Accordion.Title
                        style={moduleStyle}
                        active={this.state.activeIndex === index}
                        index={index}
                        onClick={this.handleClick}
                      >
                        <Icon name="dropdown" />
                        {/* <Menu.Header> */}
                        {mod.title[this.props.state.lang]}

                        {/* </Menu.Header> */}
                      </Accordion.Title>
                      <Accordion.Content
                        active={this.state.activeIndex === index}
                      >
                        {this.props.state.userGroup.includes(
                          this.props.state.activeDept
                        ) && (
                          // <Button.Group size="tiny">
                          <div style={{ marginBottom: "1rem" }}>
                            <Button
                              icon
                              circular
                              size="mini"
                              onClick={e => this.editModule(e, mod.entityId)}
                            >
                              <Icon name="edit" />
                            </Button>
                            {/* <Button.Or /> */}
                            <Button
                              icon
                              circular
                              size="mini"
                              onClick={e => this.moduleDelete(e, mod.entityId)}
                            >
                              <Icon name="cancel" />
                            </Button>
                          </div>
                        )
                        //</Button.Group>
                        }

                        {/* One Item, sortable */}

                        <Menu.Menu>
                          {this.state.ordering &&
                          this.state.orderingModule === mod.entityId ? (
                            // ORDERING
                            <SortableList
                              items={this.state.sortableItems}
                              onSortEnd={this.onSortEnd}
                            />
                          ) : (
                            mod.items
                              .sort((a, b) => a.sort - b.sort)
                              .map((item, index) => (
                                <div key={index}>
                                  <Menu.Item
                                    active={
                                      this.props.state.activeItem ===
                                      item.entityId
                                    }
                                    onClick={e => {
                                      this.props.state.onActiveItemChange(
                                        e,
                                        item.entityId
                                      );
                                      Analytics.record({
                                        name: "pageView",
                                        attributes: {
                                          page: `${
                                            item.title.en
                                          } - ${this.translateDept()}`,
                                          dept: this.translateDept()
                                        }
                                      });
                                    }}
                                    style={itemStyle}
                                  >
                                    {item.title[this.props.state.lang]}
                                    <br />
                                  </Menu.Item>

                                  {this.props.state.userGroup.includes(
                                    this.props.state.activeDept
                                  ) &&
                                    this.props.state.activeItem ===
                                      item.entityId && (
                                      <div style={{ marginLeft: "1rem" }}>
                                        <Button
                                          size={"mini"}
                                          icon
                                          onClick={e =>
                                            this.setState({
                                              edit: true,
                                              modal: true,
                                              titleDE: item.title.de,
                                              titleEN: item.title.en,
                                              item: item.entityId
                                            })
                                          }
                                        >
                                          <Icon name="edit" /> Edit
                                        </Button>
                                        <Button
                                          size={"mini"}
                                          icon
                                          onClick={e =>
                                            this.delete(e, item.entityId)
                                          }
                                        >
                                          <Icon name="cancel" /> Delete
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              ))
                          )}

                          {this.props.state.userGroup.includes(
                            this.props.state.activeDept
                          ) && (
                            <>
                              <Button
                                onClick={e =>
                                  this.setState({
                                    modal: true,
                                    module: mod.entityId
                                  })
                                }
                                style={{
                                  marginLeft: "1rem",
                                  marginTop: "1rem"
                                }}
                                size={"mini"}
                                icon
                              >
                                <Icon name="add circle" />
                                Add Item
                              </Button>

                              <Button
                                primary={
                                  this.state.ordering &&
                                  this.state.orderingModule === mod.entityId
                                }
                                onClick={() => this.toggleOrder(mod)}
                                icon
                                size="mini"
                              >
                                <Icon name="shuffle" />
                                {this.state.ordering &&
                                this.state.orderingModule === mod.entityId
                                  ? "Exit"
                                  : "Reorder"}
                              </Button>
                            </>
                          )}
                          {this.state.edit ? (
                            <SelectionModal
                              titleEN={this.state.titleEN}
                              titleDE={this.state.titleDE}
                              handleChange={this.handleChange}
                              open={this.state.modal}
                              entityId={this.state.item}
                              addItem={this.edit}
                              close={this.close}
                              edit
                              onUnmount={e => this.setState({ edit: false })}
                            />
                          ) : (
                            <SelectionModal
                              titleEN={this.state.titleEN}
                              titleDE={this.state.titleDE}
                              handleChange={this.handleChange}
                              open={this.state.modal}
                              entityId={this.state.module}
                              addItem={this.addItem}
                              close={this.close}
                              onUnmount={e => this.setState({ edit: false })}
                            />
                          )}
                        </Menu.Menu>
                      </Accordion.Content>
                    </Accordion>
                  </Menu.Item>
                ))
            ) : (
              <SortableList
                items={this.state.modules}
                onSortEnd={this.onSortEnd}
              />
            )}
            {this.props.state.userGroup.includes(
              this.props.state.activeDept
            ) && (
              <>
                <Button
                  size="tiny"
                  onClick={this.createModule}
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                >
                  <Icon name="plus" /> Add Module
                </Button>
                <br />
                <Button
                  size="tiny"
                  onClick={
                    this.state.orderModules
                      ? this.orderModules
                      : () => this.setState({ orderModules: true })
                  }
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                  primary={this.state.orderModules}
                >
                  <Icon name="shuffle" />{" "}
                  {this.state.orderModules ? "Save" : "Reorder Modules"}
                </Button>
              </>
            )}
          </Menu>
        </Left>
      );
    }
  }
}

export default withContext(ModulePanel);

const Left = styled.div`
  flex: 1;
  background: white;
  border-radius: 5px;
  margin-right: 0.5rem;
  min-width: 16rem;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 1rem;
    min-height: 40vh;
    max-height: 40vh;
  }
`;

const moduleStyle = {
  color: `rgb(${colors.primaryDark})`,
  lineHeight: "1.5rem"
};

const itemStyle = {
  color: `rgb(${colors.primaryDark})`,
  fontSize: "1rem"
};
