import React, { Component } from "react";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";
import { Loader } from "semantic-ui-react";

import EventCard from "./EventCard";
import FilterPanel from "./FilterPanel";
import FilterResults from "./FilterResults";
import withContext from "../Context/withContext";

import * as queries from "../graphql/queries";

const Wrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  padding: 2rem 0;
  width: 80%;
  margin: 2rem auto;
`;

const Heading = styled.h2`
  font-size: 3rem;
  color: white;
  text-align: center;
  margin: 5rem 0;
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: white;
  text-align: justify;
  width: 80%;
  margin: 2rem auto;
`;

export class Agenda extends Component {
  state = {
    events: [],
    filter: {
      department: "",
      type: "",
      startTime: "",
      program: "",
    },
    loading: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let events = await API.graphql(graphqlOperation(queries.GET_ALL_EVENTS));
    let filteredEvents = events.data.getAllEvents.filter((event) => {
      return event.day === process.env.REACT_APP_DAY;
    });

    if (this.props.events) {
      this.setState({ events: this.props.events, loading: false });
    } else {
      this.setState({ events: filteredEvents, loading: false });
    }
  }

  // componentWillReceiveProps() {
  //   this.setState({events: this.props.events})
  // }

  setFilter = (e, name, data) => {
    var filter = { ...this.state.filter };
    filter[name] = data.value;
    this.setState({ filter });
  };

  clearFilter = () => {
    let filter = {
      department: "",
      type: "",
      startTime: "",
      program: "",
    };
    this.setState({ filter });
  };

  renderData = () => {
    let { department, type, startTime, program } = this.state.filter;
    let result = this.state.events;

    if (department && department !== "") {
      result = result.filter((item) => {
        return item.department.id === department;
      });
    }
    if (type && type !== "") {
      result = result.filter((item) => item.type === type);
    }
    if (program && program !== "Alle") {
      result = result.filter((item) => {
        let onlyDE = item.programs.filter((currentValue) => {
          return program === currentValue.de;
        });
        return item.programs.includes(...onlyDE);
      });
    }
    if (startTime && startTime !== "") {
      result = result.filter(
        (item) => item.startTime >= `2020-03-19T${startTime}:00+01:00`
      );
    }

    result = result.sort((timeA, timeB) => {
      let firstTime = new Date(timeA.startTime);
      let secondTime = new Date(timeB.startTime);
      return firstTime - secondTime;
    });
    return result.map((item, i) => <EventCard event={item} key={i} />);
  };

  renderDescription = () => {
    const lang = this.props.state.lang;
    if (lang === "de") {
      return (
        <>
          <Text>
            Logge dich am{" "}
            {process.env.REACT_APP_DAY === "master" ? "19. März" : "16. Juni"}{" "}
            ein und erfahre im Livestream mehr über die Studiengänge und das
            Campusleben an der Universität Mannheim. Live-Chats geben dir die
            Möglichkeit, alle offenen Fragen rund um die Studieninhalte, das
            Bewerbungs- und Zulassungsverfahren, mögliche Auslandsaufenthalte
            oder das Campusleben zu stellen.
          </Text>
          <Text>
            Finde über die Filter die Veranstaltungen, die zu deinen Interessen
            passen.
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text>
            Log in on{" "}
            {process.env.REACT_APP_DAY === "master" ? "March 19" : "June 16"}{" "}
            and discover the different programs and the campus life at the
            University of Mannheim in our live stream. Ask all your questions
            about course contents, the application and admission process,
            possible stays abroad or life on campus in our live chats.
          </Text>
          <Text>
            Find the sessions that fit your profile via the filter option.
          </Text>
        </>
      );
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <Wrapper>
          <Loader
            size="huge"
            inverted
            active
            inline="centered"
            style={{ color: "white" }}
          >
            Loading
          </Loader>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          {!this.props.events && (
            <>
              <Heading textAlign="center">Agenda</Heading>

              {this.renderDescription()}
            </>
          )}
          <FilterPanel
            setFilter={this.setFilter}
            clearFilter={this.clearFilter}
            events={this.state.events}
          />
          <FilterResults
            events={this.state.events}
            renderData={this.renderData}
          />
        </Wrapper>
      );
    }
  }
}

export default withContext(Agenda);
