import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { withAuthenticator } from 'aws-amplify-react'
import withContext from '../Context/withContext'


import EventsAdmin from './EventAdmin'
import UsersAdmin from './UsersAdmin'
import AdminHome from './AdminHome'
import { Loader } from 'semantic-ui-react'
import SigninForm from '../Start/SigninForm'

export class Admin extends Component {

  state = {
    loading: true
  }

  async componentDidMount() {
    this.setState({loading: true})
    await this.props.state.onSetUserGroup()
    await this.props.state.onSetUser()
    this.setState({loading: false})
  }

  render() {
    if(this.state.loading) {
      return <Loader active size="massive" inverted />;
    } else if(this.props.state.userGroup.includes("admins")) {
      return (
        <Switch>
          <Route path={`${this.props.match.url}`} exact component={AdminHome} />
          <Route path={`${this.props.match.url}/events`} exact component={EventsAdmin} />
          <Route path={`${this.props.match.url}/users`} exact component={UsersAdmin} />
        </Switch>
      )
    } else {
      return <p>Not Authorized</p>
    }
    
  }
}

export default withAuthenticator(withRouter(withContext(Admin)), false, [<SigninForm redirect={'/admin'} />])

