import React, { useState } from "react";
import { Button, Checkbox, Form, Header, Segment } from "semantic-ui-react";
import { Auth, Analytics } from "aws-amplify";
import withContext from "../Context/withContext";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const SigninForm = props => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      let signedInUser = await Auth.signIn(email, password);
      let interests = signedInUser.attributes["custom:interest"]
        ? signedInUser.attributes["custom:interest"].split(", ")
        : "na";
      Analytics.updateEndpoint({
        address: signedInUser.attributes.email,
        channelType: "EMAIL",
        optOut: "NONE",
        userId: signedInUser.attributes.sub,
        userAttributes: {
          interests,
          day: [process.env.REACT_APP_DAY]
        }
      });
      props.state.userHasAuthenticated(true);
      props.onStateChange("signedIn");
      if (props.redirect) {
        props.history.push(props.redirect);
      } else {
        props.history.push("/inside");
      }
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <Wrapper>
      <Segment>
        <Header style={{ fontSize: "2rem" }} textAlign="left">
          Sign In
        </Header>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Email</label>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
          </Form.Field>
          {/* <Form.Field>
          <Checkbox label='I agree to the Terms and Conditions' />
        </Form.Field> */}
          <Button loading={isLoading} type="submit">
            Sign In
          </Button>
          <br />
          <p></p>
          <a
            onClick={() => props.history.push("/forgot")}
            style={{ cursor: "pointer", marginTop: "1rem" }}
          >
            {props.state.lang === "en"
              ? "Forgot password?"
              : "Passwort vergessen?"}
          </a>
          <p>
            {props.state.lang === "en"
              ? "Problems signing in? "
              : "Probleme bei der Anmeldung? "}
            <a href="mailto:experiencedays@uni-mannheim.de">
              {props.state.lang === "en" ? "Contact us." : "Kontaktiere uns."}
            </a>
          </p>
        </Form>
      </Segment>
    </Wrapper>
  );
};

export default withRouter(withContext(SigninForm));

const Wrapper = styled.div`
  width: 30%;
  margin: 0 auto
  padding-top: 15rem;
  padding-bottom: 10rem;
  @media (max-width: 800px) {
    width: 90%
  }
`;
