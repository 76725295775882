import React, { Component } from "react";
import * as queries from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Loader, Item, Button } from "semantic-ui-react";
import UniSiegel from "../UniSiegel.png";

import withContext from "../Context/withContext";
import { REMOVE_MODERATOR_FROM_EVENT } from "../graphql/mutations";

export class ModeratorCard extends Component {
  state = {
    loading: true,
    moderator: {}
  };

  async componentDidMount() {
    let moderator = await API.graphql(
      graphqlOperation(queries.GET_USER_BY_ID, {
        entityId: this.props.moderator.entityId
      })
    );
    this.setState({ moderator: moderator.data.getUserById, loading: false });
  }

  removeModerator = async () => {
    let response = await API.graphql(
      graphqlOperation(REMOVE_MODERATOR_FROM_EVENT, {
        eventId: this.props.event.entityId,
        userId: this.props.cardIndex.toString()
      })
    );
    window.location.reload();
  };

  render() {
    if (this.state.loading) {
      return <Loader active inverted size={"massive"} />;
    } else {
      return (
        <Item>
          <Item.Image
            src={
              this.state.moderator.image
                ? this.state.moderator.image
                : UniSiegel
            }
            size={"tiny"}
            alt="moderator"
          />
          <Item.Content>
            <Item.Header>
              {this.state.moderator.firstName || "Anonymous"}{" "}
              {this.state.moderator.lastName || "Moderator"}
            </Item.Header>
            <Item.Meta>
              {this.state.moderator.position
                ? this.state.moderator.position[this.props.state.lang]
                : null}
            </Item.Meta>
            {this.props.state.userGroup.includes(
              this.props.event.department.id
            ) && (
              <Item.Meta>
                <Button size={"tiny"} onClick={this.removeModerator}>
                  Remove
                </Button>
              </Item.Meta>
            )}
          </Item.Content>
        </Item>
      );
    }
  }
}

export default withContext(ModeratorCard);
