import React from "react";
import { Menu } from "semantic-ui-react";
import withContext from "./Context/withContext";
import { footerInfo } from "./dummyContent";
import { Link } from "react-router-dom";

const Footer = ({ state }) => (
  <Menu stackable style={{ marginTop: "-1rem", maxWidth: "100%" }}>
    <Menu.Item>
      <Link to="/imprint">{footerInfo.imprint[state.lang]}</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="privacy">{footerInfo.privacy[state.lang]}</Link>
    </Menu.Item>
    <Menu.Item>
      <a href="mailto:experiencedays@uni-mannheim.de">
        {footerInfo.contact[state.lang]}
      </a>
    </Menu.Item>
    <Menu.Menu position="right">
      <Menu.Item>&copy; Mannheim Business School gGmbH, 2020</Menu.Item>
    </Menu.Menu>
  </Menu>
);

export default withContext(Footer);
