import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import {
  Grid,
  Form,
  Segment,
  Button,
  Dropdown,
  Dimmer,
  Loader,
  Modal,
  Header,
  Card
} from "semantic-ui-react";
import EventCard from "../Start/EventCard";
import { API, Auth, graphqlOperation } from "aws-amplify";
import styled from "styled-components";
import myTheme from "../Start/myTheme";
import withContext from "../Context/withContext";

import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

const Wrapper = styled.div`
  width: 80%;
  padding: 5rem 0;
  padding-top: 10rem;
  margin: 0 auto;
`;

const departments = [
  {
    key: 1,
    text: "Business School",
    value: {
      en: "Business School",
      de: "Fakultät für Betriebswirtschaftslehre",
      id: 1
    }
  },
  {
    key: 2,
    text: "Department of Law",
    value: {
      en: "Department of Law",
      de: "Abteilung Rechtswissenschaft",
      id: 2
    }
  },
  {
    key: 3,
    text: "Department of Economics",
    value: {
      en: "Department of Economics",
      de: "Abteilung Volkswirtschaftslehre",
      id: 3
    }
  },
  {
    key: 4,
    text: "School of Humanities",
    value: {
      en: "School of Humanities",
      de: "Philosophische Fakultät",
      id: 4
    }
  },
  {
    key: 5,
    text: "School of Business Informatics and Mathematics",
    value: {
      en: "School of Business Informatics and Mathematics",
      de: "Fakultät für Wirtschaftsinformatik und -mathematik",
      id: 5
    }
  },
  {
    key: 6,
    text: "School of Social Sciences",
    value: {
      en: "School of Social Sciences",
      de: "Fakultät für Sozialwissenschaften",
      id: 6
    }
  },
  {
    key: 7,
    text: "University of Mannheim",
    value: {
      en: "University of Mannheim",
      de: "Universität Mannheim",
      id: 7
    }
  }
];

export class EventAdmin extends Component {
  state = {
    titleDE: "",
    titleEN: "",
    descDE: "",
    descEN: "",
    type: "",
    department: "",
    startTime: "",
    endTime: "",
    language: "",
    programs: [],
    loading: false,
    open: false,
    dimmer: false,
    events: [],
    editing: false,
    user: "",
    departmentOptions: []
  };

  async componentDidMount() {
    this.setState({ loading: true });
    //let events = await API.get('events', '/', {})
    let events = await API.graphql(graphqlOperation(queries.GET_ALL_EVENTS));
    let filteredEvents = events.data.getAllEvents.filter(event => {
      return event.day === process.env.REACT_APP_DAY;
    });
    let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    await this.setState({ user: user.attributes.email });
    await this.props.state.onSetUserGroup();
    let allDepartments = await API.graphql(
      graphqlOperation(queries.GET_ALL_DEPARTMENTS)
    );
    let options = allDepartments.data.allDepartments.map((dept, index) => {
      return {
        key: index + 1,
        text: dept.title[this.props.state.lang],
        value: {
          en: dept.title.en,
          de: dept.title.de,
          id: dept.entityId
        }
      };
    });
    this.setState({
      loading: false,
      events: filteredEvents,
      departmentOptions: options
    });
  }

  deleteEvent = async id => {
    let confirmation = window.confirm(
      "Are you sure you want to delete the item?"
    );
    if (confirmation === true) {
      // API.del('events', `/${id}`, {})
      // .then(response => {
      //   window.location.reload()
      // })
      // .catch(error => {
      //   alert("There was an error deleting your event")
      // })

      await API.graphql(
        graphqlOperation(mutations.DELETE_EVENT, { entityId: id })
      );
      window.location.reload();
    } else {
      return;
    }
  };

  handleInput = e => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value
    });
  };

  handleInput2 = (e, data) => {
    let name = data.name;
    let value = data.value;
    this.setState({
      [name]: value
    });
  };

  handleDate = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async () => {
    let {
      titleDE,
      titleEN,
      descDE,
      descEN,
      type,
      department,
      startTime,
      endTime,
      language,
      programs
    } = this.state;
    if (
      titleDE === "" ||
      titleEN === "" ||
      descDE === "" ||
      descEN === "" ||
      type === "" ||
      department === "" ||
      startTime === "" ||
      endTime === "" ||
      language === ""
    ) {
      alert(
        "You have not set all options. Please make sure you have filled out every field."
      );
      return;
    }
    this.setState({ loading: true });

    let params = {
      title: {
        en: titleEN,
        de: titleDE
      },
      desc: {
        en: descEN,
        de: descDE
      },
      type,
      department,
      startTime:
        process.env.REACT_APP_DAY === "master"
          ? `2020-03-19T${startTime}:00+01:00`
          : `2020-05-19T${startTime}:00+02:00`,
      endTime:
        process.env.REACT_APP_DAY === "master"
          ? `2020-03-19T${endTime}:00+01:00`
          : `2020-05-19T${endTime}:00+02:00`,
      language,
      programs,
      day: process.env.REACT_APP_DAY
    };

    if (this.state.editing) {
      let response = await API.graphql(
        graphqlOperation(mutations.UPDATE_EVENT, {
          input: params,
          entityId: this.state.events[0].entityId
        })
      );

      this.setState({
        loading: false,
        open: true,
        dimmer: true,
        descEN: "",
        descDE: "",
        titleDE: "",
        titleEN: "",
        type: "",
        department: "",
        programs: [],
        startTime: "",
        endTime: "",
        language: ""
      });

      //   API.put('events', `/${this.state.events[0].eventId}`, {body: params})
      //   .then(response => {
      //
      // }).catch(error => {
      //     alert(error.response)
      //     return
      // });
      //  return
    }

    if (!this.state.editing) {
      // API.post('events', '/', { body: params })
      // .then(response => {
      //   this.setState({
      //     loading: false,
      //     open: true,
      //     dimmer: true,
      //     descEN: "",
      //     descDE: "",
      //     titleDE: "",
      //     titleEN: "",
      //     type: "",
      //     department: "",
      //     programs: [],
      //     startTime: "",
      //     endTime: "",
      //     language: ""
      //   })
      // }).catch(error => {
      //     alert(error.response)
      // });
      let response = await API.graphql(
        graphqlOperation(mutations.CREATE_EVENT, { input: params })
      );
      this.setState({
        loading: false,
        open: true,
        dimmer: true,
        descEN: "",
        descDE: "",
        titleDE: "",
        titleEN: "",
        type: "",
        department: "",
        programs: [],
        startTime: "",
        endTime: "",
        language: ""
      });
    }
  };

  updateCard = (e, event) => {
    let {
      desc,
      title,
      type,
      department,
      programs,
      startTime,
      endTime,
      language
    } = event;
    let editedEvent = this.state.events.filter(onEvent => {
      return event.entityId === onEvent.entityId;
    });

    this.setState({
      events: [...editedEvent],
      descEN: desc.en,
      descDE: desc.de,
      titleDE: title.de,
      titleEN: title.en,
      type,
      department,
      programs,
      startTime: startTime.substring(11, 16),
      endTime: endTime.substring(11, 16),
      language,
      editing: true
    });
  };

  cancelUpdate = () => {
    this.setState({
      editing: false,
      descEN: "",
      descDE: "",
      titleDE: "",
      titleEN: "",
      type: "",
      department: "",
      programs: [],
      startTime: "",
      endTime: "",
      language: ""
    });
    window.location.reload();
  };

  render() {
    if (this.props.state.userGroup.includes("admins")) {
      return (
        <>
          {this.state.loading && (
            <Dimmer active page>
              <Loader>Loading</Loader>
            </Dimmer>
          )}

          <Modal dimmer={this.state.dimmer} open={this.state.open}>
            <Modal.Header>Event created successfully</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <p>The event you have created was saved to the database.</p>
                <p>You may continue with creating events</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Done"
                onClick={() => window.location.reload()}
              />
            </Modal.Actions>
          </Modal>
          <Wrapper>
            <Grid columns={2} stackable style={{ width: "100%" }}>
              <Grid.Row>
                <Grid.Column>
                  <Card.Group>
                    {this.state.events.map((event, i) => (
                      <EventCard
                        onUpdate={this.updateCard}
                        deleteEvent={this.deleteEvent}
                        fluid={true}
                        style={{ width: "100%" }}
                        event={event}
                        key={i}
                      />
                    ))}
                  </Card.Group>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment>
                    <Header>
                      {this.state.editing
                        ? `Editing event ${this.state.titleEN}`
                        : "Create a new event"}
                    </Header>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Field>
                        <label>English Title</label>
                        <input
                          name="titleEN"
                          value={this.state.titleEN}
                          onChange={this.handleInput}
                          placeholder="English Title"
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Deutscher Titel</label>
                        <input
                          name="titleDE"
                          value={this.state.titleDE}
                          onChange={this.handleInput}
                          placeholder="Deutscher Titel"
                        />
                      </Form.Field>
                      <Form.TextArea
                        label="English Description"
                        name="descEN"
                        value={this.state.descEN}
                        onChange={this.handleInput}
                        placeholder="Description goes here"
                      />
                      <Form.TextArea
                        label="Deutsche Beschreibung"
                        name="descDE"
                        value={this.state.descDE}
                        onChange={this.handleInput}
                        placeholder="Beschreibung hierher"
                      />
                      <Form.Field>
                        <label>Event Type</label>
                        <Dropdown
                          placeholder="Choose type of event"
                          value={this.state.type}
                          options={[
                            { text: "Chat", value: "Chat", key: 1 },
                            { text: "Stream", value: "Stream", key: 2 },
                            { text: "Conference", value: "Conference", key: 3 }
                          ]}
                          name="type"
                          onChange={(e, data) => this.handleInput2(e, data)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Department</label>
                        <Dropdown
                          placeholder="Choose department"
                          options={this.state.departmentOptions}
                          value={this.state.department}
                          name="department"
                          onChange={(e, data) => this.handleInput2(e, data)}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>
                          Programs{" "}
                          {this.state.editing
                            ? "(Only select if you want to change existing, otherwise leave empty)"
                            : ""}
                        </label>
                        <Dropdown
                          multiple
                          placeholder="Choose programs"
                          options={programs}
                          name="programs"
                          onChange={(e, data) => this.handleInput2(e, data)}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Start time</label>
                        <input
                          type="string"
                          placeholder="HH:MM"
                          name="startTime"
                          value={this.state.startTime}
                          onChange={this.handleDate}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>End time</label>
                        <input
                          name="endTime"
                          value={this.state.endTime}
                          onChange={this.handleDate}
                          type="string"
                          placeholder="HH:MM"
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Language</label>
                        <Dropdown
                          value={this.state.language}
                          placeholder="Choose language of event"
                          options={[
                            { key: 1, text: "English", value: "en" },
                            { key: 2, text: "Deutsch", value: "de" }
                          ]}
                          name="language"
                          onChange={(e, data) => this.handleInput2(e, data)}
                        />
                      </Form.Field>
                      <Button type="submit">
                        {this.state.editing
                          ? "Save changes"
                          : "Create new event"}
                      </Button>
                      {this.state.editing && (
                        <Button onClick={this.cancelUpdate}>Cancel</Button>
                      )}
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Wrapper>
        </>
      );
    } else {
      return (
        <>
          {this.state.loading && (
            <Dimmer active page>
              <Loader>Loading</Loader>
            </Dimmer>
          )}
          <Wrapper>
            <Segment>
              <p>Sorry, you are not authorized</p>
            </Segment>
          </Wrapper>
        </>
      );
    }
  }
}

export default withAuthenticator(withContext(EventAdmin), {
  theme: myTheme,
  usernameAttributes: "email"
});

const programs = [
  { key: 1, value: { de: "Geschichte", en: "History" }, text: "Geschichte" },
  {
    key: 2,
    value: {
      de: "Mathematik und Informatik",
      en: "Mathematics and Informatics"
    },
    text: "Mathematik und Informatik"
  },
  {
    key: 3,
    value: { de: "Medien und Kommunikation", en: "Media and Communication" },
    text: "Medien und Kommunikation"
  },
  {
    key: 4,
    value: { de: "Philosophie", en: "Philosophy" },
    text: "Philosophie"
  },
  {
    key: 5,
    value: { de: "Politik", en: "Political Science" },
    text: "Politik"
  },
  {
    key: 6,
    value: { de: "Psychologie", en: "Psychology" },
    text: "Psychologie"
  },
  { key: 7, value: { de: "Jura", en: "Law" }, text: "Jura" },
  { key: 8, value: { de: "Soziologie", en: "Sociology" }, text: "Soziologie" },
  {
    key: 9,
    value: { de: "Sprache und Literatur", en: "Language and Literature" },
    text: "Sprache und Literatur"
  },
  {
    key: 10,
    value: { de: "Volkswirtschaft", en: "Economics" },
    text: "Volkswirtschaft"
  },
  { key: 11, value: { de: "Pädagogik", en: "Education" }, text: "Lehramt" },
  {
    key: 12,
    value: { de: "Betriebswirtschaft", en: "Business Administration" },
    text: "Betriebswirtschaft"
  }
];
