import React, { useEffect } from "react";
import { Menu, Dropdown, Button, Icon } from "semantic-ui-react";
import withContext from "./Context/withContext";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";

const options = {
  de: [
    { key: 1, text: "Englisch", value: "en" },
    { key: 2, text: "Deutsch", value: "de" }
  ],
  en: [
    { key: 1, text: "English", value: "en" },
    { key: 2, text: "German", value: "de" }
  ]
};

const Header = props => {
  useEffect(() => {}, [props.state.isAuthenticated]);

  return (
    <>
      <BigMenu>
        <Menu
          compact
          fixed={"top"}
          style={{
            background: "none",
            border: "none",
            boxShadow: "none",
            paddingRight: "1rem"
          }}
        >
          <Menu.Item position={"right"} style={{ outline: "none" }}>
            <Dropdown
              button
              floating
              text={props.state.lang === "en" ? "Language" : "Sprache"}
              labeled
              icon="world"
              options={options[props.state.lang]}
              className="icon"
              onChange={(e, data) => props.state.onLangChange(e, data)}
            />
          </Menu.Item>
          {!props.state.isAuthenticated &&
            process.env.REACT_APP_DAY === "master" && (
              <Menu.Item>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => props.history.push("/inside/")}
                >
                  <Icon name="sign-in" />
                  Log In
                </Button>
              </Menu.Item>
            )}

          {/* {!props.state.isAuthenticated &&
            Date.now() > new Date(settings.loginStartDate) &&
            Date.now() < new Date(settings.loginEndDate) && (
              <Menu.Item>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => props.history.push("/signin")}
                >
                  <Icon name="home" />
                  Signin
                </Button>
              </Menu.Item>
            )} */}
          {props.state.isAuthenticated && (
            <>
              <Menu.Item>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => props.history.push("/inside/")}
                >
                  <Icon name="home" />
                  Home
                </Button>
              </Menu.Item>

              {props.state.userGroup.includes("zula") && (
                <Menu.Item>
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() => (window.location.href = "/inside/chat/zula")}
                  >
                    <Icon name="chat" />
                    Zula Chat
                  </Button>
                </Menu.Item>
              )}
              {props.state.userGroup.includes("admins") && (
                <Menu.Item>
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() => props.history.push("/admin")}
                  >
                    <Icon name="key" />
                    Admin
                  </Button>
                </Menu.Item>
              )}
              <Menu.Item>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => props.history.push("/inside/profile")}
                >
                  <Icon name="user" />
                  Profile
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button negative onClick={props.state.handleLogout}>
                  Logout
                </Button>
              </Menu.Item>
            </>
          )}
        </Menu>
      </BigMenu>
      <SmallMenu>
        <Dropdown
          className="icon"
          floating
          labeled
          icon="bars"
          labeled
          direction="left"
          button
          text="Menu"
          // trigger={<Icon name="bars" />}
        >
          <Dropdown.Menu>
            <Dropdown.Header>Language</Dropdown.Header>
            <Dropdown.Item
              text={props.state.lang === "de" ? "Englisch" : "English"}
              value="en"
              flag="uk"
              onClick={(e, data) => {
                props.state.onLangChange(e, data);
              }}
            />
            <Dropdown.Item
              flag="de"
              text={props.state.lang === "de" ? "Deutsch" : "German"}
              value="de"
              onClick={(e, data) => props.state.onLangChange(e, data)}
            />

            {props.state.isAuthenticated && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  icon="home"
                  text="Home"
                  onClick={() => props.history.push("/inside/")}
                />
                <Dropdown.Item
                  icon="user"
                  text={props.state.lang === "en" ? "Profile" : "Profil"}
                  onClick={() => props.history.push("/inside/profile")}
                />
                <Dropdown.Item
                  icon="power off"
                  text="Logout"
                  onClick={props.state.handleLogout}
                />

                {props.state.userGroup.includes("zula") && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Header>Zula</Dropdown.Header>
                    <Dropdown.Item
                      text="Zula Chat"
                      icon="chat"
                      onClick={() =>
                        (window.location.href = "/inside/chat/zula")
                      }
                    />
                  </>
                )}
                {props.state.userGroup.includes("admins") && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Header>Admin</Dropdown.Header>
                    <Dropdown.Item
                      text="Admin Panel"
                      icon="key"
                      onClick={() => props.history.push("/admin")}
                    />
                  </>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </SmallMenu>
    </>
  );
};

export default withContext(withRouter(Header));

const BigMenu = styled.div`
  @media (max-width: 1125px) {
    display: none;
  }
`;

const SmallMenu = styled.div`
  position: fixed;
  top: 1rem;
  right: 2rem;
  @media (min-width: 1124px) {
    display: none;
  }
  z-index: 5000;
  box-shadow: -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
`;
