import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";

import {
  Grid,
  Card,
  Loader,
  Button,
  Header,
  Icon,
  Message,
  Popup
} from "semantic-ui-react";

import withContext from "../Context/withContext";

import EventCard from "../Start/EventCard";
import { Information } from "../Information/Information";

import styled from "styled-components";
// import { Agenda } from "../Start/Agenda";
// import { ApplyNow } from "./ApplyNow";

export class Home extends Component {
  state = {
    future: [],
    now: [],
    past: [],
    loading: true
  };

  async componentDidMount() {
    this.setState({ loading: true });
    this.fetchEvents();
    this.createEventUpdateListener = API.graphql(
      graphqlOperation(subscriptions.ON_EVENT_ACTIVE_TOGGLE)
    ).subscribe({
      next: data => {
        this.fetchEvents();
      }
    });
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.createEventUpdateListener.unsubscribe();
  }

  fetchEvents = async () => {
    let response = await API.graphql(graphqlOperation(queries.GET_ALL_EVENTS));
    let filteredEvents = response.data.getAllEvents.filter(event => {
      return event.day === process.env.REACT_APP_DAY;
    });
    let future = [];
    let now = [];
    let past = [];
    filteredEvents.map(event => {
      let startTime = new Date(event.startTime);

      let endTime = new Date(event.endTime);

      if (event.active) {
        now.push(event);
      } else if (endTime.getTime() < Date.now()) {
        past.push(event);
      } else {
        future.push(event);
      }
    });
    // let departments = await API.graphql(graphqlOperation(queries.GET_ALL_DEPARTMENTS))
    this.setState({ future, now, past });
  };

  openEvent = (e, event) => {
    if (
      this.props.state.userGroup.includes(event.entityId) &&
      event.type === "Chat"
    ) {
      this.props.history.push(`/inside/chat/${event.entityId}`);
    }
    if (
      this.props.state.userGroup.includes(event.entityId) &&
      event.type === "Stream"
    ) {
      this.props.history.push(`/inside/stream/${this.props.event.entityId}`);
    }
    return;
  };

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);
  scrollToEventRef = () => {
    if (this.props.state.activeDept) {
      window.scrollTo(0, this.eventRef.current.offsetTop + 1400);
    } else {
      window.scrollTo(0, this.eventRef.current.offsetTop + 1000);
    }
  };
  scrollToInfo = () => {
    if (this.props.state.activeDept) {
      window.scrollTo(0, this.infoRef.current.offsetTop - 800);
    } else {
      window.scrollTo(0, this.infoRef.current.offsetTop - 300);
    }
  };

  myRef = React.createRef();
  eventRef = React.createRef();
  infoRef = React.createRef();

  // Test

  render() {
    if (this.state.loading) {
      return <Loader inverted active size={"massive"} />;
    } else {
      return (
        <Wrapper>
          <Header
            as="h1"
            icon
            textAlign="center"
            inverted
            style={{ paddingTop: "5rem" }}
          >
            <Icon name="info" circular />
            <Header.Content>Information</Header.Content>
            <Header.Subheader
              style={{ maxWidth: "50vw", margin: "0 auto", lineHeight: "2rem" }}
            >
              {this.props.state.lang === "de"
                ? `Informiere dich über die Universität Mannheim, 
                das Campusleben und die verschiedenen Masterstudiengänge an unseren Fakultäten. 
                Klicke dazu einfach auf die jeweiligen Fachbereiche: Wir haben dir wichtige Informationen, 
                weiterführende Links sowie Videos und Fotos zusammengestellt, sodass du dir einen Eindruck 
                von den Studiengängen und dem Studentenleben vor Ort machen kannst.`
                : `Learn more about the University of Mannheim, 
                life on campus and the different master's programs at our schools. 
                Just click on the respective departments: We have compiled important 
                information, additional links, videos and photos to give you a first 
                idea of the programs and student life in Mannheim.`}
            </Header.Subheader>
          </Header>
          <Information
            state={this.props.state}
            ref={this.myRef}
            scrollToInfo={this.scrollToInfo}
          />
          <div ref={this.infoRef}></div>
          <Header
            as="h1"
            icon
            textAlign="center"
            inverted
            style={{ paddingTop: "5rem" }}
          >
            <Icon name="chat" circular />
            <Header.Content>
              {this.props.state.lang === "de"
                ? "Aktuelle Events"
                : "Current Events"}
            </Header.Content>
            <Header.Subheader
              style={{ maxWidth: "50vw", margin: "0 auto", lineHeight: "2rem" }}
            >
              {this.props.state.lang === "de"
                ? `Nimm an unseren Chats und Livestreams teil, um dich näher 
              über einzelne Studiengänge oder das Studienleben in Mannheim zu 
              informieren und mit unseren Mitarbeiterinnen und Mitarbeitern sowie Studierenden 
              in Kontakt zu treten. Klicke dazu einfach auf das jeweilige Event. 
              Wir freuen uns auf deine Fragen! Weiter unten findest du die kommenden 
              Programmpunkte sowie im Archiv die Verläufe der bereits beendeten Chats zum Nachlesen.`
                : `Join our chats and live streams to learn more about the particular programs or student 
                life in Mannheim and to get in touch with our staff members and students. 
                Just click on the respective event. We are looking forward to your questions!
                Below you can find the upcoming program and chat histories in the repository. 
                `}
            </Header.Subheader>
          </Header>
          {/* <Agenda state={this.props.state} events={this.state.now}/> */}
          <Grid
            centered
            style={{
              width: "100%",
              maxWidth: "100%",
              margin: "0 auto"
              // minWidth: "100%"
            }}
          >
            <Grid.Row
              style={{
                width: "100%",
                padding: 0,
                paddingTop: "3rem"
              }}
            >
              <Grid.Column
                centered
                textAlign={"center"}
                style={{
                  width: "100%",
                  padding: 0,
                  margin: 0
                }}
              >
                <Card.Group centered>
                  <>
                    {this.state.now.length !== 0 ? (
                      this.state.now.map((event, index) => {
                        return (
                          <EventCard
                            key={event.entityId}
                            event={event}
                            active
                          />
                        );
                      })
                    ) : (
                      <Message
                        compact
                        style={{ width: "25rem", textAlign: "left" }}
                        icon
                      >
                        <Icon name="circle notched" loading />
                        <Message.Content>
                          <Message.Header>
                            {this.props.state.lang === "de"
                              ? "Keine aktuellen Events"
                              : "No current events"}
                          </Message.Header>
                          {this.props.state.lang === "de" ? `` : ``}
                        </Message.Content>
                      </Message>
                    )}
                  </>
                </Card.Group>

                <Header
                  as="h1"
                  icon
                  textAlign="center"
                  inverted
                  style={{ paddingTop: "5rem" }}
                >
                  <p ref={this.eventRef}></p>
                  <Icon name="chat" circular />
                  <Header.Content>
                    {this.props.state.lang === "de"
                      ? "Kommende Events"
                      : "Upcoming Events"}
                  </Header.Content>
                  <Header.Subheader
                    style={{
                      maxWidth: "50vw",
                      margin: "0 auto",
                      lineHeight: "2rem"
                    }}
                  >
                    {this.props.state.lang === "de"
                      ? `Folgende Chats und Livestreams sind im Laufe des Tages noch geplant. 
                    Die aktuellen Events kannst du weiter oben direkt besuchen. Solltest du 
                    bereits ein Event verpasst haben, findest du die Chatverläufe weiter unten zum Nachlesen. `
                      : `The following chats and live streams will be available later today. You can directly join 
                    the current events above. If you missed an event, you can find the chat histories below.`}
                  </Header.Subheader>
                </Header>
                <Card.Group centered>
                  {this.state.future.length !== 0 ? (
                    this.state.future
                      .sort((timeA, timeB) => {
                        let firstTime = new Date(timeA.startTime);
                        let secondTime = new Date(timeB.startTime);
                        return firstTime - secondTime;
                      })
                      .map((event, i) => {
                        return <EventCard key={i} event={event} />;
                      })
                  ) : (
                    <Message
                      compact
                      style={{ width: "25rem", textAlign: "left" }}
                      icon
                    >
                      <Icon name="circle notched" loading />
                      <Message.Content>
                        <Message.Header>
                          {this.props.state.lang === "de"
                            ? "Keine kommenden Events"
                            : "No upcoming events"}
                        </Message.Header>
                        {this.props.state.lang === "de" ? `` : ``}
                      </Message.Content>
                    </Message>
                  )}
                </Card.Group>
                <Header
                  as="h1"
                  icon
                  textAlign="center"
                  inverted
                  style={{ paddingTop: "5rem" }}
                >
                  <Icon name="chat" circular />
                  <Header.Content>
                    {this.props.state.lang === "de"
                      ? "Vergangene Events"
                      : "Past Events"}
                  </Header.Content>
                  <Header.Subheader
                    style={{
                      maxWidth: "50vw",
                      margin: "0 auto",
                      lineHeight: "2rem"
                    }}
                  >
                    {this.props.state.lang === "de"
                      ? `Hier findest du alle Chatverläufe nochmal zum Nachlesen. 
                    Solltest du weitere Fragen haben, kannst du uns natürlich gerne jederzeit kontaktieren – 
                    die Kontakte findest du im Informationsbereich oben.`
                      : `Here you can find all chat histories. 
                    If you have any further questions, please don’t hesitate to 
                    contact us – you can find the contacts in the information area above.`}
                  </Header.Subheader>
                </Header>
                <Card.Group centered style={{ paddingBottom: "10rem" }}>
                  {this.state.past.length !== 0 ? (
                    this.state.past.map((event, i) => {
                      return <EventCard archive key={i} event={event} />;
                    })
                  ) : (
                    <Message
                      compact
                      style={{ width: "25rem", textAlign: "left" }}
                      icon
                    >
                      <Icon name="circle notched" loading />
                      <Message.Content>
                        <Message.Header>
                          {this.props.state.lang === "de"
                            ? "Keine vergangenen Events"
                            : "No past events"}
                        </Message.Header>
                      </Message.Content>
                    </Message>
                  )}
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div
            style={{
              position: "fixed",
              bottom: "2rem",
              right: "2rem",
              zIndex: "5000"
            }}
          >
            <Popup
              trigger={
                <Button
                  icon="info"
                  size="massive"
                  circular
                  onClick={this.scrollToMyRef}
                  style={{
                    marginBottom: "1rem",
                    boxShadow: "-.25rem .25rem .5rem rgba(0,0,0,0.25)"
                  }}
                />
              }
              content="Information"
              position="left center"
            />
            <br />
            <Popup
              trigger={
                <Button
                  icon="chat"
                  size="massive"
                  circular
                  onClick={this.scrollToEventRef}
                  style={{
                    marginBottom: "1rem",
                    boxShadow: "-.25rem .25rem .5rem rgba(0,0,0,0.25)"
                  }}
                />
              }
              content="Events"
              position="left center"
            />
            <br />
            <a
              href="https://evasys.uni-mannheim.de/evasys/online.php?p=OnExDaysUniMA20"
              target="_blank"
            >
              <Popup
                trigger={
                  <Button
                    icon="star"
                    size="massive"
                    circular
                    style={{
                      boxShadow: "-.25rem .25rem .5rem rgba(0,0,0,0.25)"
                    }}
                  />
                }
                content="Feedback"
                position="left center"
              />
            </a>
          </div>
          {/* <ApplyNowButton>
            {this.props.state.lang === "en" ? (
              <ApplyLink
                href="https://onlinebewerbung.uni-mannheim.de/"
                target="_blank"
              >
                <p>
                  Apply
                  <br />
                  now
                </p>
              </ApplyLink>
            ) : (
              <ApplyLink
                href="https://onlinebewerbung.uni-mannheim.de/"
                target="_blank"
              >
                <p>
                  Jetzt
                  <br />
                  bewerben
                </p>
              </ApplyLink>
            )}
          </ApplyNowButton> */}
        </Wrapper>
      );
    }
  }
}

export default withContext(Home);

const Wrapper = styled.div`
  padding-top: 5rem;
  // max-width: 95%;
`;

// const ApplyNowButton = styled.div`
//   position: fixed;
//   top: 10rem;
//   right: 0;
//   width: 8rem;
//   background: rgb(35, 46, 88);
//   color: white;
//   text-align: center;
//   padding: 1rem;
//   font-size: 1.2rem;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
//   cursor: pointer;
// `;

// const ApplyLink = styled.a`
//   color: white;
// `;
