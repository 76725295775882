export const colors = {
  primaryDark: "35, 46, 88",
  primaryLight: "172, 185, 203" 
}

export const assets = {
  distribution: 'https://assets.online-days.com',
  bgImageLarge: 'https://assets.online-days.com/images/schloss-1600px.jpg',
  bgImageSmall: 'https://assets.online-days.com/images/schloss-1600px.jpg'
}
// $color-primary-dark: rgb(35, 46, 88);
// $color-primary-medium: lighten(rgb(35, 46, 88), 8%);
// $color-primary-light: rgb(172, 185, 203);

// $color-white: rgb(250, 250, 250);
// $color-black: rgb(0, 0, 0);

// $color-gray-light: rgb(230, 230, 230);
// $color-gray-medium: lightgray;
// $color-gray-dark: gray;


// $default-font-size: 1.8rem;
// $default-font-weight: 300;


// //Faculty Colors

// $color-wim-dark: rgb(64, 136, 133);
// $color-wim-light: lighten($color-wim-dark, 15%);


// $color-phil-dark: rgb(195, 47, 53);
// $color-phil-light: lighten($color-phil-dark, 15%);


// $color-vwl-dark: rgb(130, 152, 175);
// $color-vwl-light: lighten($color-vwl-dark, 15%);


// $color-bwl-dark: rgb(151, 184, 98);
// $color-bwl-light: lighten($color-bwl-dark, 15%);

// //Editor colors

// $color-editor: $color-vwl-dark;'