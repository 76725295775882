import React from 'react'
import HeaderStyleDropdown from './HeaderStyleDropdown'
import BlockStyleButton from './BlockStyleButton'

export const BLOCK_TYPES = [
  { label: " “ ” ", style: "blockquote", icon: "quote left"},
  { label: "UL", style: "unordered-list-item", icon: "list ul" },
  { label: "OL", style: "ordered-list-item", icon: "list ol" },
  { label: "{ }", style: 'code-block', icon: "code" }
 ];
 export const HEADER_TYPES = [
   { label: "H1", style: "header-one" },
   { label: "H2", style: "header-two" },
   { label: "H3", style: "header-three" },
   { label: "H4", style: "header-four" },
   { label: "H5", style: "header-five" }
 ]

  export function getBlockStyle(block) {
    switch (block.getType()) {
      case "blockquote":
        return "draftjs-blockquote"
      case "unstyled":
        return "draftjs-unstyled"
      case "header-one": 
        return "draftjs-header-one"
      case "header-two":
        return "draftjs-header-two"
      case "header-three":
        return "draftjs-header-three"
      case "header-four":
        return "draftjs-header-four"
      case "header-five":
        return "draftjs-header-five"
      case "unordered-list-item":
        return "draftjs-unordered-list"
      case "ordered-list-item":
        return "draftjs-ordered-list"
      default:
        return null;
  }
 }

 class BlockStyleToolbar extends React.Component {
  render() {
   const { editorState } = this.props;
   const selection = editorState.getSelection();
   const blockType = editorState
   .getCurrentContent()
   .getBlockForKey(selection.getStartKey())
   .getType();
   
   return (
    <span>
     <span className="RichEditor-controls">
      
      
      {BLOCK_TYPES.map(type => {
       return (
        <BlockStyleButton
         active={type.style === blockType}
         label={type.label}
         onToggle={this.props.onToggle}
         style={type.style}
         key={type.label}
         type={type}
         icon={type.icon}
         />
       );
      })}

      <HeaderStyleDropdown
        headerOptions={HEADER_TYPES}
        active={blockType}
        onToggle={this.props.onToggle}
        />
     </span>
    </span>
   );
  }
 }

 export default BlockStyleToolbar;