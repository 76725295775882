import React from "react";
import { Grid } from "semantic-ui-react";
import styled from "styled-components";
import { features } from "../dummyContent";

import FeatureCard from "./FeatureCard";
import Countdown from "./Countdown";

const Wrapper = styled.div`
  min-height: 70vh;
  background-color: rgba(250, 250, 250, 0.7);
  padding: 2rem 0;
  color: rgb(35, 46, 88);
`;

const Heading = styled.h2`
  font-size: 3rem;
  font-color: white;
  text-align: center;
`;

const Features = () => (
  <Wrapper>
    <Heading textAlign="center">Features</Heading>
    <Grid columns={features.length} centered>
      <Grid.Row>
        {features.map((feature, i) => (
          <FeatureCard feature={feature} key={i} />
        ))}
      </Grid.Row>
    </Grid>
    <Countdown
      date={
        process.env.REACT_APP_DAY === "master"
          ? "Thu, 19 Mar 2020 08:00:00"
          : "Tue, 16 Jun 2020 08:00:00"
      }
    />
  </Wrapper>
);

export default Features;
