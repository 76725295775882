import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 5rem 0;
  padding-top: 10rem;
`;

const Imprint = () => (
  <Wrapper>
    <Segment style={{ width: "80%", margin: "0 auto" }}>
      <div className="page">
        <div className="page__container">
          <h2 className="heading-secondary u-margin-bottom-m">Legal Notice</h2>
          <p>
            Mannheim Business School gGmbH
            <br />
            L 5, 6 <br />
            68161 Mannheim
            <br />
            Germany <br />
            Tel.: +49 621 181 12812
            <br />
            Fax: +49 621 181 1278 <br />
            Internet: https://www.mannheim-business-school.de
            <br />
            <br />
            Sitz Mannheim. Amtsgericht Mannheim HRB 10262.
            <br />
            Geschäftsführer: Prof. Dr. Jens Wüstemann <br />
            SteuerNr. 38107/03995 <br />
            Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV: Prof. Dr.
            Jens Wüstemann
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            Copyright Notice
          </h3>
          <p>
            All content is property of Mannheim Business School gGmbH except for
            the content provided by the participating institutions; these are
            property of their respective authors.
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            Disclaimer
          </h3>

          <p className="u-margin-bottom-s">
            The author reserves the right not to be responsible for the
            topicality, correctness, completeness or quality of the information
            provided. Liability claims regarding damage caused by the use of any
            information provided, including any kind of information which is
            incomplete or incorrect,will therefore be rejected. All offers are
            not-binding and without obligation. Parts of the pages or the
            complete publication including all offers and information might be
            extended, changed or partly or completely deleted by the author
            without separate announcement.
          </p>
          <p className="u-margin-bottom-s">
            The author is not responsible for any contents linked or referred to
            from his pages - unless he has full knowledge of illegal contents
            and would be able to prevent the visitors of his site from viewing
            those pages. If any damage occurs by the use of information
            presented there, only the author of the respective pages might be
            liable, not the one who has linked to these pages. Furthermore the
            author is not liable for any postings or messages published by users
            of discussion boards, guestbooks or mailinglists provided on his
            page.
          </p>
          <p className="u-margin-bottom-s">
            The author intended not to use any copyrighted material for the
            publication or, if not possible, to indicatethe copyright of the
            respective object. The copyright for any material created by the
            author is reserved. Any duplication or use of objects such as
            diagrams, sounds or texts in other electronic or printed
            publications is not permitted without the author's agreement.
          </p>
          <p className="u-margin-bottom-s">
            If the opportunity for the input of personal or business data (email
            addresses, name, addresses) is given, the input of these data takes
            place voluntarily. The use and payment of all offered services are
            permitted - if and so far technically possible and reasonable -
            without specification of any personal data or under specification of
            anonymized data or an alias. The use of published postal addresses,
            telefone or fax numbers and email addresses for marketing purposes
            is prohibited, offenders sending unwanted spam messages will be
            punished.
          </p>
          <p className="u-margin-bottom-s">
            This disclaimer is to be regarded as part of the internet
            publication which you were referred from. If sections or individual
            terms of this statement are not legal or correct, the content or
            validity of the other parts remain uninfluenced by this fact.
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            Design and Development
          </h3>
          <p>
            <a href="https://www.online-days.de">OnlineDays</a>
            <br />
            Benjamin Ebner - Digital Media Solutions <br />
            info@online-days.de <br />
          </p>
        </div>
      </div>
    </Segment>
  </Wrapper>
);

export default Imprint;
