import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import withContext from "./Context/withContext";
import styled from "styled-components";
import { colors, assets } from "./css-config";

const Wrapper = styled.div`
  display: flex;
  height: 50vh;
`;

const Background = styled.div`
  box-sizing: border-box;
  background-image: linear-gradient(
      to right bottom,
      rgba(${colors.primaryDark}, 0.85),
      rgba(${colors.primaryLight}, 0.9)
    ),
    url(${assets.bgImageLarge});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  font-family: "Calibri", sans-serif;
`;

const Error = props => {
  return (
    <Background>
      <Wrapper>
        <Segment style={{ margin: "auto" }}>
          <Header as="h2" icon style={{ color: `rgb(${colors.primaryDark})` }}>
            <Icon
              name="settings"
              style={{ color: `rgb(${colors.primaryDark})` }}
            />
            Sorry
            <Header.Subheader>
              {props.message[props.state.lang]}
            </Header.Subheader>
          </Header>
        </Segment>
      </Wrapper>
    </Background>
  );
};

export default withContext(Error);
