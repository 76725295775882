import React, { Component } from "react";
import styled from "styled-components";
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import addLinkPlugin from "./plugins/addLinkPlugin";
import BlockStyleToolbar, {
  getBlockStyle,
} from "./blockStyles/BlockStyleToolbar";
import { mediaBlockRenderer } from "./entities/mediaBlockRenderer";
import {
  Button,
  Icon,
  Loader,
  Modal,
  Form,
  Progress,
  Sticky,
  Placeholder,
} from "semantic-ui-react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { PhotoPicker } from "aws-amplify-react";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import withContext from "../Context/withContext";

import "./Information.scss";

class ContentPanel extends Component {
  plugins = [addLinkPlugin];

  state = {
    editorState: EditorState.createEmpty(),
    admin: false,
    activeItem: this.props.state.activeDept,
    loading: true,
    modal: false,
    percentUploaded: 0,
    video: false,
    title: {},
    cover: null,
    pdf: "",
    addPdf: false,
    image: "",
  };

  async componentDidMount() {
    if (!this.props.state.reference) {
      let response = await API.graphql(
        graphqlOperation(queries.GET_DEPARTMENT_STARTPAGE, {
          deptId: this.props.state.activeDept,
        })
      );
      this.setState({
        title: response.data.getDepartment.title,
        loading: false,
        activeItem: response.data.getDepartment,
        editorState: EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(
              response.data.getDepartment.startpage[this.props.state.lang]
            )
          )
        ),
      });
    } else {
      if (this.props.state.activeItem) {
        let response = await API.graphql(
          graphqlOperation(queries.GET_ITEM_EDITOR_STATE, {
            entityId: this.props.state.activeItem,
          })
        );
        this.setState({
          loading: false,
          title: response.data.getItem.title,
          editorState: EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(
                response.data.getItem.editorState[this.props.state.lang]
              )
            )
          ),
        });
      } else {
        let response = await API.graphql(
          graphqlOperation(queries.GET_DEPARTMENT_STARTPAGE, {
            deptId: this.props.state.activeDept,
          })
        );
        this.setState({
          loading: false,
          title: response.data.getDepartment.title,
          activeItem: response.data.getDepartment,
          editorState: EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(
                response.data.getDepartment.startpage[this.props.state.lang]
              )
            )
          ),
        });
      }
      this.props.state.onReferenceSet(false);
    }
  }

  async componentWillReceiveProps(newProps) {
    this.setState({ loading: true });
    if (newProps.state.activeItem) {
      let response = await API.graphql(
        graphqlOperation(queries.GET_ITEM_EDITOR_STATE, {
          entityId: newProps.state.activeItem,
        })
      );
      this.setState({
        title: response.data.getItem.title,
        loading: false,
        editorState: EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(response.data.getItem.editorState[this.props.state.lang])
          )
        ),
      });
    } else {
      let response = await API.graphql(
        graphqlOperation(queries.GET_DEPARTMENT_STARTPAGE, {
          deptId: newProps.state.activeDept,
        })
      );
      this.setState({
        loading: false,
        title: response.data.getDepartment.title,
        activeItem: response.data.getDepartment,
        editorState: EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(
              response.data.getDepartment.startpage[this.props.state.lang]
            )
          )
        ),
      });
    }
    this.setState({ admin: false });
  }

  onChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    this.setState({ editorState });
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );

    if (newState) {
      this.onChange(newState);
      return "handled";
    }

    return "not handled";
  };

  onItalicClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
    );
  };

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
  };

  onUnderlineClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
    );
  };

  onAddLink = () => {
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    const link = window.prompt("Paste the link -");
    if (!link) {
      this.onChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  focus = () => this.refs.editor.focus();

  onAddVideo = async (data) => {
    let filename = `${Date.now()}-${this.state.image.name}`;
    let uploadedFile = await Storage.put(filename, this.state.image, {
      contentType: this.state.image.type,
      progressCallback: (progress) => {
        const percentUploaded = Math.round(
          (progress.loaded / progress.total) * 100
        );
        this.setState({ percentUploaded });
      },
    });
    if (this.state.cover !== null) {
      let coverName = `${Date.now()}-${this.state.cover.name}`;
      var uploadedCover = await Storage.put(coverName, this.state.cover, {
        contentType: this.state.cover.type,
        progressCallback: (progress) => {
          const percentUploaded = Math.round(
            (progress.loaded / progress.total) * 100
          );
          this.setState({ percentUploaded });
        },
      });
    }
    this.setState({ modal: false, image: "", cover: null, percentUploaded: 0 });
    let imageKey = uploadedFile.key;
    let urlValue = `https://assets.online-days.com/public/${imageKey}`;
    let coverValue = uploadedCover
      ? `https://assets.online-days.com/public/${uploadedCover.key}`
      : null;
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "video",
      "IMMUTABLE",
      { src: urlValue, cover: coverValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };

  onAddImage = async (data) => {
    let filename = `${Date.now()}-${this.state.image.name}`;
    let uploadedFile = await Storage.put(filename, this.state.image.file, {
      contentType: this.state.image.type,
      progressCallback: (progress) => {
        const percentUploaded = Math.round(
          (progress.loaded / progress.total) * 100
        );
        this.setState({ percentUploaded });
      },
    });
    this.setState({ modal: false, image: "", percentUploaded: 0 });
    let imageKey = uploadedFile.key;
    let urlValue = `https://assets.online-days.com/public/${imageKey}`;
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };

  onAddImageClick = () => {
    this.setState({ modal: true, video: false });
  };

  onAddVideoClick = () => {
    this.setState({ modal: true, video: true });
  };

  saveContent = async () => {
    const contentState = this.state.editorState.getCurrentContent();
    if (this.props.state.activeItem) {
      let response = await API.graphql(
        graphqlOperation(mutations.UPDATE_ITEM_EDITOR, {
          editorState: JSON.stringify(convertToRaw(contentState)),
          entityId: this.props.state.activeItem,
          language: this.props.state.lang,
        })
      );
    } else {
      let response = await API.graphql(
        graphqlOperation(mutations.UPDATE_STARTPAGE_EDITOR, {
          startpage: JSON.stringify(convertToRaw(contentState)),
          entityId: this.props.state.activeDept,
          language: this.props.state.lang,
        })
      );
    }
  };

  changeUser = () => {
    if (this.state.admin) {
      this.saveContent();
      this.setState({ admin: false });
    } else {
      this.setState({ admin: true });
    }
  };

  saveLike = async () => {
    let userId = this.props.state.user.username;
    let input = {
      deptId: this.props.state.activeDept,
      itemId: this.props.state.activeItem ? this.props.state.activeItem : null,
      title: this.state.title,
    };
    let response = await API.graphql(
      graphqlOperation(mutations.ADD_LIKE_TO_USER, { userId, input })
    );
    alert("Saved to likes!");
  };

  stickyRef = React.createRef();

  onAddEmbedClick = async () => {
    let filename = `${Date.now()}-${this.state.pdf.name}`;
    let uploadedFile = await Storage.put(filename, this.state.pdf, {
      contentType: this.state.pdf.type,
      progressCallback: (progress) => {
        const percentUploaded = Math.round(
          (progress.loaded / progress.total) * 100
        );
        this.setState({ percentUploaded });
      },
    });
    this.setState({ addPdf: false, pdf: "", percentUploaded: 0 });
    let imageKey = uploadedFile.key;
    let urlValue = `https://assets.online-days.com/public/${imageKey}`;
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "pdf",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };

  onAddIframe = () => {
    let frame = window.prompt("Address of iframe");
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "iframe",
      "IMMUTABLE",
      { src: frame }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <Right>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Right>
      );
    } else {
      return (
        <Right ref={this.stickyRef}>
          <>
            {this.state.admin && (
              <Sticky context={this.stickyRef}>
                <div
                  style={{
                    backgroundColor: "lightgrey",
                    padding: "1rem",
                    borderRadius: "5px",
                    boxShadow: ".5rem .5rem 1rem rgba(0,0,0,0.3)",
                  }}
                >
                  <Button.Group style={{ marginRight: "1rem" }}>
                    <Button icon onClick={this.onBoldClick}>
                      <Icon name="bold" />
                    </Button>
                    <Button icon onClick={this.onUnderlineClick}>
                      <Icon name="underline" />
                    </Button>
                    <Button icon onClick={this.onItalicClick}>
                      <Icon name="italic" />
                    </Button>
                  </Button.Group>
                  <Button.Group style={{ marginRight: "1rem" }}>
                    <Button icon onClick={this.onAddLink}>
                      <Icon name="linkify" />
                    </Button>
                    <Button icon onClick={this.onAddImageClick}>
                      <Icon name="image" />
                    </Button>
                    <Button icon onClick={this.onAddVideoClick}>
                      <Icon name="video" />
                    </Button>
                    <Button
                      icon
                      onClick={() => this.setState({ addPdf: true })}
                    >
                      <Icon name="paperclip" />
                    </Button>
                    <Button icon onClick={this.onAddIframe}>
                      <Icon name="window maximize outline" />
                    </Button>
                  </Button.Group>

                  <BlockStyleToolbar
                    editorState={this.state.editorState}
                    onToggle={this.toggleBlockType}
                  />
                  {this.props.state.userGroup.includes(
                    this.props.state.activeDept
                  ) && (
                    <div style={{ display: "block", marginTop: "1rem" }}>
                      <Button.Group>
                        <Button positive onClick={this.changeUser}>
                          {this.state.admin ? "Save" : "Edit Content"}
                        </Button>
                        <Button.Or />
                        <Button onClick={() => this.setState({ admin: false })}>
                          Cancel
                        </Button>
                      </Button.Group>
                    </div>
                  )}
                </div>
              </Sticky>
            )}

            <Editor
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              plugins={this.plugins}
              blockStyleFn={getBlockStyle}
              ref="editor"
              blockRendererFn={mediaBlockRenderer}
              readOnly={this.state.admin ? false : true}
            />
            <div style={{ marginTop: "2rem" }}>
              <Button
                onClick={(e) => this.saveLike(e)}
                icon
                labelPosition="left"
              >
                <Icon name="heart" /> Save to My Likes
              </Button>
              {this.props.state.userGroup.includes(
                this.props.state.activeDept
              ) &&
                !this.state.admin && (
                  <Button onClick={this.changeUser}>
                    {this.state.admin ? "Save" : "Edit Content"}
                  </Button>
                )}
            </div>
          </>
          {this.state.modal && (
            <Modal open>
              <Modal.Header>
                {this.state.video ? "Select a Video" : "Select a Photo"}
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Form>
                    {this.state.video ? (
                      <>
                        <p>Upload a video</p>
                        <p>
                          Note: Please try to keep the maximum filesize to
                          100mb. Also, please avoid the "+" sign in the
                          filename, as this may cause trouble with the video
                          playback.
                        </p>
                        <input
                          type="file"
                          accept="video/*"
                          disabled={this.state.percentUploaded > 0}
                          onChange={(e) =>
                            this.setState({ image: e.target.files[0] })
                          }
                        />
                      </>
                    ) : (
                      <PhotoPicker
                        preview
                        onPick={(data) => this.setState({ image: data })}
                      />
                    )}

                    {this.state.video && (
                      <>
                        <p>Upload a cover image</p>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            this.setState({ cover: e.target.files[0] })
                          }
                          disabled={this.state.percentUploaded > 0}
                        />
                      </>
                    )}
                    {this.state.percentUploaded > 0 && (
                      <Progress
                        autoSuccess
                        percent={this.state.percentUploaded}
                      />
                    )}
                  </Form>
                  {this.state.video ? (
                    <Button
                      disabled={
                        this.state.image === "" ||
                        this.state.percentUploaded > 0
                      }
                      onClick={this.onAddVideo}
                    >
                      Upload Video
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        this.state.image === "" ||
                        this.state.percentUploaded > 0
                      }
                      onClick={this.onAddImage}
                    >
                      Upload Photo
                    </Button>
                  )}

                  <Button
                    onClick={() =>
                      this.setState({
                        modal: false,
                        image: "",
                        percentUploaded: 0,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )}
          {this.state.addPdf && (
            <Modal open>
              <Modal.Header>Add a PDF Document</Modal.Header>
              <Modal.Content>
                <Form>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => this.setState({ pdf: e.target.files[0] })}
                  />
                </Form>
                <Button
                  disabled={this.state.pdf === ""}
                  onClick={this.onAddEmbedClick}
                >
                  Submit
                </Button>
                {this.state.percentUploaded > 0 && (
                  <Progress autoSuccess percent={this.state.percentUploaded} />
                )}
              </Modal.Content>
            </Modal>
          )}
        </Right>
      );
    }
  }
}

export default withContext(ContentPanel);

const Right = styled.div`
  flex: 3;
  padding: 3rem;
  overflow-y: scroll;
  border-radius: 5px;
  background: white;
  resize: both;
  @media (max-width: 800px) {
    min-height: 80vh;
  }
`;
