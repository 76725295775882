import React, { Component } from "react";
import styled from "styled-components";
import withContext from "./Context/withContext";

export class Logo extends Component {
  render() {
    return (
      <HeaderLogo>
        <img
          src={
            this.props.state.lang === "en"
              ? "https://assets.online-days.com/logos/UNI_MA_Logo_EN_Weiss.png"
              : "https://assets.online-days.com/logos/Logo_UMA_DE_Weiss.png"
          }
          alt="University of Mannheim"
          style={{
            width: "100%"
          }}
        />
      </HeaderLogo>
    );
  }
}

export default withContext(Logo);

const HeaderLogo = styled.div`
  @media (max-width: 360px) {
    top: 2.5rem;
  }

  position: relative;
  width: 20rem;
`;
