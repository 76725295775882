import React, { Component } from 'react'
import {Card, List, Button, Icon, Modal, Dropdown, Placeholder} from 'semantic-ui-react'
import { API, graphqlOperation } from 'aws-amplify'
import { GET_DEPT_STAFF } from '../graphql/queries'
import { ADD_USER_TO_POOL, REMOVE_USER_FROM_POOL } from '../graphql/mutations'

export class GroupCard extends Component {

  state = {
    editors: [],
    modal: false,
    input: "",
    loading: true
  }

  async componentDidMount() {
    let response = await API.graphql(graphqlOperation(GET_DEPT_STAFF, {poolId: this.props.group.GroupName}))
    this.setState({editors: response.data.getUsersFromPool, loading: false})
  }

  handleChange = (e, data) => {
    this.setState({input: data.value})
  }

  onSubmit = async () => {
    let response = await API.graphql(graphqlOperation(ADD_USER_TO_POOL, {userId: this.state.input, groupName: this.props.group.GroupName}))
    window.location.reload()
  }

  onDelete = async (e, editor) => {
    let confirmation = window.confirm("Do you really want to delete this user?")
    if(confirmation === true) {
      let response = await API.graphql(graphqlOperation(REMOVE_USER_FROM_POOL, {userId: editor.entityId, groupName: this.props.group.GroupName}))
      window.location.reload()
    } else {
      return
    }
  }
  
  
  render() {
    const options = this.props.users.map((user, index) => {
      return {
        key: index,
        text: user.email,
        value: user.entityId
      }
    })

    return (
      <>
      <Card fluid>
        <Card.Content>
          {this.state.loading ?
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
          :
          <>
          <Card.Header>{this.props.group.Description} ({this.state.editors.length})</Card.Header>
          {/* <Card.Meta>{this.props.group.GroupName}</Card.Meta> */}
          <Card.Description>
            <List size={"big"} divided relaxed>
              {this.state.editors.map(editor => (
                <List.Item>
                  <List.Content floated="left">
                    <List.Description>
                    {editor.email}
                    </List.Description>
                  </List.Content>
                  <List.Content floated="right">
                    <Button onClick={(e, id) => this.onDelete(e, editor)} size={"tiny"} circular icon>
                      <Icon name="delete"/>
                    </Button>
                  </List.Content>
                  
                </List.Item>
              ))
              }
            </List>  
          </Card.Description>
          </>
  }
        </Card.Content>
        <Card.Content>
        
          <Button onClick={() => this.setState({modal: true})}>Add</Button>
        </Card.Content>
      </Card>
      {this.state.modal &&
      <Modal open >
        <Modal.Header>Add Editor</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            This action will add the given user to thee editors of {this.props.group.GroupName}
          </Modal.Description>
          <Modal.Description>
          <Dropdown 
          search 
          selection
          fluid
          options={options}
          onChange={(e, data) => this.handleChange(e,data)}
        />
          </Modal.Description>
        
        <Button.Group style={{marginTop: '2rem'}}>
          <Button onClick={() => this.setState({modal: false, input: ""})}>Cancel</Button>
          <Button.Or />
          <Button positive onClick={this.onSubmit}>Save</Button>
        </Button.Group>
        </Modal.Content>
      </Modal>
        
      }
      
      </>
    )
  }
}

export default GroupCard
