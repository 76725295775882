import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import Amplify from "aws-amplify";
import { errorMessage } from "./dummyContent";
import { CookiesProvider } from "react-cookie";

import Admin from "./Admin/Admin";
import EventAdmin from "./Admin/EventAdmin";
import UsersAdmin from "./Admin/UsersAdmin";
import App from "./App";
import Error from "./Error";
import Imprint from "./Legal/Imprint";
import Privacy from "./Legal/Privacy";
import Start from "./Start/Start";
import SigninForm from "./Start/SigninForm";
import SignupForm from "./Start/SignupForm";
import ErrorBoundary from "./ErrorBoundary";
import LoginArea from "./LoginArea";
import Sortable from "./test/ortable";

import Convert from "./Convert";
import ForgotPassword from "./ForgotPassword";
import MMM from "./Infographics/MMM";
import Timeline from "./Infographics/Timeline";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "events",
        endpoint:
          process.env.REACT_APP_STAGE === "production"
            ? "https://uku16hqhd9.execute-api.eu-central-1.amazonaws.com/prod/events"
            : "https://rzeb0fmg09.execute-api.eu-central-1.amazonaws.com/dev/events",
        region: "eu-central-1"
      }
    ]
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: "0664671e7ba142c7a3e7dae6b316aaf3",
      region: "eu-central-1",
      mandatorySignIn: false
    }
  },
  "aws_appsync_graphqlEndpoint":
    "https://gac6lqwjkfcnrdwlt6m2hsmu5u.appsync-api.eu-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_API,
  Storage: {
    AWSS3: {
      bucket: "oed-assets",
      region: "eu-central-1"
    }
  }
});

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <CookiesProvider>
        <App>
          <Switch>
            <Route path="/" exact component={Start} />
            <Route path="/imprint" exact component={Imprint} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/admin" exact component={Admin} />
            <Route path="/admin/events" exact component={EventAdmin} />
            <Route path="/admin/users" exact component={UsersAdmin} />
            <Route path="/signin" exact component={SigninForm} />
            <Route path="/signup" exact component={SignupForm} />
            <Route path="/inside" component={LoginArea} />
            <Route path="/sortable" component={Sortable} />
            <Route path="/mmm" exact component={MMM} />
            <Route path="/timeline" exact component={Timeline} />
            <Route path="/forgot" exact component={ForgotPassword} />
            <Route
              render={props => <Error {...props} message={errorMessage} />}
            />
          </Switch>
        </App>
      </CookiesProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);

serviceWorker.unregister();
