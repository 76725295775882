import React, { Component } from "react";
import ReactCountdown from "react-countdown-now";
import styled from "styled-components";
import { Statistic } from "semantic-ui-react";

export class Countdown extends Component {
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <p></p>;
    } else {
      return (
        <CountdownWrapper>
          <Statistic>
            <Statistic.Value style={colored}>{days}</Statistic.Value>
            <Statistic.Label style={colored}>Days</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value style={colored}>{hours}</Statistic.Value>
            <Statistic.Label style={colored}>Hours</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value style={colored}>{minutes}</Statistic.Value>
            <Statistic.Label style={colored}>Minutes</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value style={colored}>{seconds}</Statistic.Value>
            <Statistic.Label style={colored}>Seconds</Statistic.Label>
          </Statistic>
        </CountdownWrapper>
      );
    }
  };

  render() {
    return <ReactCountdown renderer={this.renderer} date={this.props.date} />;
  }
}

export default Countdown;

const CountdownWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: rgb(35, 46, 88);
  padding-top: 4rem;
`;

const colored = {
  color: "rgb(35, 46, 88)"
};
