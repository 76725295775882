import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { PUT_ERROR } from "./graphql/mutations";
import { Segment, Header, Icon } from "semantic-ui-react";
import { colors, assets } from "./css-config";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 50vh;
`;

const Background = styled.div`
  box-sizing: border-box;
  background-image: linear-gradient(
      to right bottom,
      rgba(${colors.primaryDark}, 0.85),
      rgba(${colors.primaryLight}, 0.9)
    ),
    url(${assets.bgImageLarge});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  font-family: "Calibri", sans-serif;
`;

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error1, errorInformation) {
    try {
      let response = await API.graphql(
        graphqlOperation(PUT_ERROR, {
          errorInfo: error1.toString(),
          errorLocation: errorInformation
        })
      );
    } catch (e) {
      console.log("ERROR CALLING API", e);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Background>
          <Wrapper>
            <Segment style={{ margin: "auto" }}>
              <Header
                as="h2"
                icon
                style={{ color: `rgb(${colors.primaryDark})` }}
              >
                <Icon
                  name="settings"
                  style={{ color: `rgb(${colors.primaryDark})` }}
                />
                Ooooops!
                <Header.Subheader>
                  Sorry, it looks like something went wrong. Please{" "}
                  <a
                    href={`https://${process.env.REACT_APP_DAY}.online-days.com`}
                  >
                    try again
                  </a>
                  .
                  <br />
                  If this keeps happening, please
                  <a href="mailto:experiencedays@uni-mannheim.de">contact us</a>
                  .
                </Header.Subheader>
              </Header>
            </Segment>
          </Wrapper>
        </Background>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
