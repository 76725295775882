import React, { Component } from "react";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  Loader,
  Table,
  Header,
  Icon,
  Modal,
  Label,
  Segment,
  Menu
} from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import StaffAdmin from "./StaffAdmin";
import { withAuthenticator } from "aws-amplify-react";
import withContext from "../Context/withContext";

export class UsersAdmin extends Component {
  state = {
    loading: true,
    users: [],
    modal: false,
    allUsers: []
  };

  async componentDidMount() {
    await this.props.state.onSetUserGroup();
    await this.props.state.onSetUser();
    let response = await API.graphql(graphqlOperation(queries.GET_ALL_USERS));
    let filteredUsers = response.data.getAllUsers.filter(
      user => user.day === process.env.REACT_APP_DAY
    );
    // this.setState({users: response.data.getAllUsers, loading: false})
    this.setState({
      users: filteredUsers,
      allUsers: response.data.getAllUsers,
      loading: false
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader inverted active size={"massive"} />;
    } else if (this.props.state.userGroup.includes("admins")) {
      return (
        <Wrapper>
          <Container>
            <StaffAdmin users={this.state.allUsers} />
            <Header as="h2" icon textAlign="center">
              <Icon name="users" circular />
              <Header.Content>
                Confirmed Users ({this.state.users.length})
              </Header.Content>
            </Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Interests</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.users.map(user => {
                  // On click of button render a form, where the department IDs load and you can assign them to a user, then call the updateUserData API
                  return (
                    <Table.Row>
                      <Table.Cell>{user.entityId}</Table.Cell>
                      <Table.Cell>{user.email}</Table.Cell>
                      <Table.Cell>
                        <ul>
                          {user.interests.map(interest => {
                            if (interest !== "na") {
                              return <li>{interest}</li>;
                            }
                          })}
                        </ul>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        </Wrapper>
      );
    } else {
      return <p>Not Authorized</p>;
    }
  }
}

export default withAuthenticator(withRouter(withContext(UsersAdmin)));

const Wrapper = styled.div`
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

const Container = styled.div`
  background: white;
  border-radius: 5px;
  min-height: 75vh;
  width: 80%;
  margin: 0 auto;
  padding: 5rem;
  text-align: center;
`;
