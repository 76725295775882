import React, { Component } from "react";
import styled from "styled-components";
import {
  Grid,
  Header,
  Icon,
  Loader,
  Form,
  Button,
  Modal,
  Progress,
  Image,
  Item,
  Tab
} from "semantic-ui-react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { PhotoPicker } from "aws-amplify-react";
import withContext from "../Context/withContext";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import Moment from "react-moment";

export class Profile extends Component {
  state = {
    loading: true,
    user: null,
    editing: false,
    modal: false,
    image: "",
    percentUploaded: 0,
    firstName: "",
    lastName: "",
    positionEN: "",
    positionDE: ""
  };

  async componentDidMount() {
    let response = await API.graphql(
      graphqlOperation(queries.GET_USER_BY_ID, {
        entityId: this.props.state.user.username
      })
    );
    this.setState({
      user: response.data.getUserById,
      firstName: response.data.getUserById.firstName,
      lastName: response.data.getUserById.lastName,
      loading: false
    });
    if (response.data.getUserById.position) {
      this.setState({
        positionDE: response.data.getUserById.position.de,
        positionEN: response.data.getUserById.position.en
      });
    }
  }

  upload = async () => {
    let filename = `Profile-Image-${this.state.image.name}-${Date.now()}`;
    let uploadedFile = await Storage.put(filename, this.state.image.file, {
      contentType: this.state.image.type,
      progressCallback: progress => {
        const percentUploaded = Math.round(
          (progress.loaded / progress.total) * 100
        );
        this.setState({ percentUploaded });
      }
    });

    let imageKey = uploadedFile.key;
    let urlValue = `https://assets.online-days.com/public/${imageKey}`;
    let postImage = await API.graphql(
      graphqlOperation(mutations.CHANGE_USER_IMAGE, {
        entityId: this.state.user.entityId,
        image: urlValue
      })
    );

    this.setState({ modal: false, image: "", percentUploaded: 0 });
    window.location.reload();
  };

  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  };

  changeUserDetails = async () => {
    let { firstName, lastName, positionDE, positionEN } = this.state;
    let entityId = this.props.state.user.username;

    let input = {
      firstName,
      lastName,
      position: {
        en: positionEN !== "" ? positionEN : "-",
        de: positionDE !== "" ? positionDE : "-"
      }
    };
    try {
      let response = await API.graphql(
        graphqlOperation(mutations.CHANGE_USER_DETAILS, { entityId, input })
      );
      window.location.reload();
    } catch (err) {}
  };

  goToInfo = (e, item) => {
    this.props.state.onReferenceSet(true);
    this.props.state.onActiveDepChange(e, item.deptId);
    this.props.state.onActiveItemChange(e, item.itemId);
    this.props.history.push("/inside/information");
  };

  removeLike = async (e, index) => {
    let userId = this.props.state.user.username;
    let response = await API.graphql(
      graphqlOperation(mutations.REMOVE_LIKE, { userId, index })
    );
    window.location.reload();
  };

  removeMessageLike = async index => {
    let userId = this.props.state.user.username;
    let response = await API.graphql(
      graphqlOperation(mutations.REMOVE_MESSAGE_LIKE, { userId, index })
    );
    window.location.reload();
  };

  render() {
    const panes = [
      {
        menuItem: "Information",
        render: () => (
          <Tab.Pane attached={false}>
            <Item.Group divided>
              {this.state.user.likes !== null
                ? this.state.user.likes.map((like, index) => {
                    return (
                      <>
                        <Item>
                          <Item.Content>
                            <Item.Header>
                              {like.title[this.props.state.lang]}
                            </Item.Header>
                            <Item.Extra>
                              <Button
                                onClick={e => this.goToInfo(e, like)}
                                primary
                                size={"tiny"}
                              >
                                Visit <Icon name="chevron right" />
                              </Button>
                              <Button
                                size={"tiny"}
                                icon
                                onClick={e => this.removeLike(e, index)}
                              >
                                <Icon name="cancel" />
                                Remove
                              </Button>
                            </Item.Extra>
                          </Item.Content>
                        </Item>{" "}
                      </>
                    );
                  })
                : "No Likes yet"}
            </Item.Group>
          </Tab.Pane>
        )
      },
      {
        menuItem: "Messages",
        render: () => (
          <Tab.Pane attached={false}>
            <Item.Group divided>
              {this.state.user.messageLikes !== null
                ? this.state.user.messageLikes.map((like, index) => {
                    return (
                      <Item>
                        <Item.Content>
                          <Item.Header>
                            User {like.authorId.substring(0, 6)}
                          </Item.Header>
                          <Item.Meta>
                            <Moment format="HH:mm" date={like.createdAt} />
                          </Item.Meta>
                          <Item.Description>{like.body}</Item.Description>
                          <Item.Extra>
                            <Button
                              size={"tiny"}
                              icon
                              onClick={() => this.removeMessageLike(index)}
                            >
                              <Icon name="cancel" />
                              Remove
                            </Button>
                          </Item.Extra>
                        </Item.Content>
                      </Item>
                    );
                  })
                : "No Likes yet"}
            </Item.Group>
          </Tab.Pane>
        )
      }
    ];
    if (this.state.loading) {
      return <Loader inverted active size={"massive"} />;
    } else {
      return (
        <Wrapper>
          <Grid columns={2} divided centered padded stackable>
            <Grid.Row>
              <Grid.Column
                verticalAlign="top"
                width={5}
                style={{
                  background: "white",
                  padding: "1rem",
                  borderRadius: "5px"
                }}
              >
                {this.state.user.image ? (
                  <Image size={"large"} src={this.state.user.image} />
                ) : (
                  <Icon name="users" circular />
                )}
                <Header
                  as="h2"
                  icon
                  textAlign="center"
                  style={{ marginTop: "2rem" }}
                >
                  <Header.Content>Profile</Header.Content>
                  {this.props.state.userGroup.length !== 0 && (
                    <Button
                      style={{ margin: "1rem" }}
                      size={"mini"}
                      onClick={() => this.setState({ modal: true })}
                    >
                      Upload Photo
                    </Button>
                  )}
                  <Form>
                    <Form.Field disabled={!this.state.editing}>
                      <label>First Name</label>
                      <input
                        placeholder="First Name"
                        value={this.state.firstName}
                        name="firstName"
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field disabled={!this.state.editing}>
                      <label>Last Name</label>
                      <input
                        placeholder="Last Name"
                        value={this.state.lastName}
                        name="lastName"
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field disabled>
                      <label>Email</label>
                      <input
                        placeholder="Email"
                        value={this.state.user.email}
                        name="email"
                        readOnly
                      />
                    </Form.Field>
                    {this.props.state.userGroup.length !== 0 && (
                      <>
                        <Form.Field disabled={!this.state.editing}>
                          <label>Position (German)</label>
                          <input
                            placeholder="Position"
                            value={this.state.positionDE}
                            name="positionDE"
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                        <Form.Field disabled={!this.state.editing}>
                          <label>Position (English)</label>
                          <input
                            placeholder="Position"
                            value={this.state.positionEN}
                            name="positionEN"
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                      </>
                    )}

                    {!this.state.editing ? (
                      <Button
                        type="submit"
                        onClick={e => this.setState({ editing: true })}
                      >
                        Edit
                      </Button>
                    ) : (
                      <>
                        <Button type="submit" onClick={this.changeUserDetails}>
                          Save
                        </Button>
                        <Button
                          type="submit"
                          onClick={e => this.setState({ editing: false })}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </Form>
                </Header>
              </Grid.Column>
              <Grid.Column
                width={10}
                style={{
                  background: "white",
                  padding: "3rem",
                  borderRadius: "5px",
                  marginLeft: "2rem"
                }}
              >
                <Header as="h2" icon textAlign="center">
                  <Icon circular name="heart" />
                  <Header.Content>Likes</Header.Content>
                </Header>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Modal basic open={this.state.modal} style={{ textAlign: "center" }}>
            <PhotoPicker
              preview
              onPick={data => this.setState({ image: data })}
            />
            {this.state.percentUploaded > 0 && (
              <Progress autoSuccess percent={this.state.percentUploaded} />
            )}
            <Button.Group>
              <Button
                centered
                disabled={this.state.image === ""}
                onClick={this.upload}
              >
                Upload
              </Button>
              <Button.Or />
              <Button
                centered
                onClick={() => this.setState({ modal: false, image: "" })}
              >
                Cancel
              </Button>
            </Button.Group>
          </Modal>
        </Wrapper>
      );
    }
  }
}

export default withContext(Profile);

const Wrapper = styled.div`
  padding: 5rem;
  padding-top: 10rem;
  padding-bottom: 15rem;
  @media (max-width: 800px) {
    padding: 1rem;
    padding-bottom: 15rem;
  }
`;

// const Container = styled.div`
//   background: white;
//   border-radius: 5px;
//   max-height: 65vh;
//   min-height: 65vh;
//   display: flex;
//   width: 80%;
//   margin: 0 auto;
//   overflow-y: scroll;
// `;
