import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors, assets } from "./css-config";
import { Auth, Analytics, API } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";

import Context from "./Context/Context";
import Footer from "./Footer";
import Header from "./Header";
import Logo from "./Logo";
import CookiePopup from "./CookiePopup";
import "./App.scss";

const App = props => {
  const [lang, setLang] = useState(
    process.env.REACT_APP_DAY === "bachelor" ? "de" : "en"
  );
  const [activeDept, setActiveDept] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [consentGiven, setConsentGiven] = useState(false);
  const [userGroup, setUserGroup] = useState([]);
  const [user, setUser] = useState(null);
  const [reference, setReference] = useState(false);

  const onReferenceSet = async ref => {
    setReference(ref);
  };

  const onSetUser = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setUser(user);
  };

  const onSetUserGroup = async () => {
    let user = await Auth.currentSession();
    let userGroups = user.accessToken.payload["cognito:groups"];
    if (userGroups === undefined) {
      setUserGroup([]);
    } else {
      setUserGroup(userGroups);
    }
  };

  const onLangChange = (e, data) => {
    setLang(data.value);
  };

  const onActiveDepChange = (e, id) => {
    setActiveItem(null);
    setActiveDept(id);
  };

  const onActiveItemChange = (e, id) => {
    setActiveItem(id);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    props.history.push("/");
    userHasAuthenticated(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    if (process.env.REACT_APP_STAGE === "development") {
      Analytics.disable();
    }
    const { cookies } = props;
    const consentCookie = cookies.get("consentCookie");
    if (consentCookie && consentCookie === "consentAll") {
      setConsentGiven(true);
    }
    if (consentCookie && consentCookie === "consentOnly") {
      setConsentGiven(true);
      Analytics.disable();
    }

    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        console.log("No current User");
      }
    }

    setIsAuthenticating(false);
  }

  const popup = () => {
    if (consentGiven === false) {
      return <CookiePopup cookies={props.cookies} />;
    }
    return null;
  };

  return (
    !isAuthenticating && (
      <Context.Provider
        value={{
          lang,
          onLangChange,
          isAuthenticated,
          userHasAuthenticated,
          handleLogout,
          activeDept,
          onActiveDepChange,
          activeItem,
          onActiveItemChange,
          userGroup,
          onSetUserGroup,
          user,
          onSetUser,
          onReferenceSet,
          reference
        }}
      >
        <Header />
        <Background>
          <Logo />
          {props.children}
          {popup()}
        </Background>
        <Footer />
      </Context.Provider>
    )
  );
};

export default withCookies(withRouter(App));

const Background = styled.div`
  box-sizing: border-box;
  background-image: linear-gradient(
      to right bottom,
      rgba(${colors.primaryDark}, 0.85),
      rgba(${colors.primaryLight}, 0.9)
    ),
    url(${assets.bgImageLarge});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  font-family: "Calibri", sans-serif;
`;
