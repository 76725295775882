import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, Header } from 'semantic-ui-react'

export class AdminHome extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Header>Admin Panel</Header>
          <Button onClick={() => this.props.history.push('/admin/events')}>Edit Events</Button>
          <Button onClick={() => this.props.history.push('/admin/users')}>Edit Users</Button>
          {/* <Button onClick={() => this.props.history.push('/admin/staff')}>Edit Staff</Button> */}
        </Container>
      </Wrapper>
    )
  }
}

export default AdminHome

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`

const Container = styled.div`
  margin: auto;
`