import React, { useState } from "react";
import { Message, Icon, Button, Checkbox, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function CookiePopup(props) {
  const [hidden, setHidden] = React.useState(false);
  const [noAnalytics, setNoAnalytics] = React.useState(true);

  const setCookie = (e, data) => {
    const { cookies } = props;
    if (data) {
      cookies.set("consentCookie", "consentOnly", { path: "/" });
    } else {
      cookies.set("consentCookie", "consentAll", { path: "/" });
    }
    setHidden(true);
  };

  const Left = styled.div`
    display: flex;
    padding: 1rem;
  `;

  const Right = styled.div``;

  const Container = styled.div`
    display: flex;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  `;

  return (
    <Message
      icon
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 5500,
        width: "100%"
      }}
      hidden={hidden}
    >
      <Container>
        <Left>
          <Icon
            size="big"
            name="info circle"
            style={{ paddingRight: "1rem" }}
          />
          <Message.Content>
            <Message.Header>Cookies</Message.Header>
            We use browser cookies to give you the best possible experience. You
            can learn more about how we use cookies in our{" "}
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Message.Content>
        </Left>
        <Right>
          <Button positive onClick={(e, data) => setCookie(e, false)}>
            Allow all
          </Button>
          {/* <Button>Cookie Preferences</Button> */}
          <Popup
            on="click"
            position="top right"
            pinned
            trigger={<Button content="Cookie Preferences" />}
          >
            <Popup.Content>
              <Checkbox
                toggle
                label="Analytics"
                onClick={() => setNoAnalytics(!noAnalytics)}
              />
              <Checkbox
                toggle
                disabled
                defaultChecked
                label="Basic Usage Cookies"
                style={{ marginTop: ".5rem" }}
              />
              <Button
                style={{ marginTop: ".5rem" }}
                onClick={(e, data) => setCookie(e, noAnalytics)}
              >
                Set Preferences
              </Button>
            </Popup.Content>
          </Popup>
        </Right>
      </Container>
    </Message>
  );
}
