import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 5rem 0;
  padding-top: 10rem;
`;

const Privacy = () => (
  <Wrapper>
    <Segment style={{ width: "80%", margin: "0 auto" }}>
      <div className="page">
        <div className="page__container">
          <h2 className="heading-secondary u-margin-bottom-m">Data Privacy</h2>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            I. Overview
          </h3>

          <p>
            The following gives a simple overview of what happens to your
            personal information when you visit our website. Personal
            information is any data with which you could be personally
            identified. Detailed information on the subject of data protection
            can be found in our privacy policy found below.
            <br />
            The data collected on this website are processed by the website
            operator. The operator's contact details can be found in the
            website's required legal notice.
            <br />
            Some data are collected when you provide it to us. This could, for
            example, be data you enter on an input form. Other data are
            collected automatically by our IT systems when you visit the
            website. These data are primarily technical data such as the browser
            and operating system you are using or when you accessed the page.
            These data are collected automatically as soon as you enter our
            website.
            <br />
            Part of the data is collected to ensure the proper functioning of
            the website. Other data can be used to analyze how visitors use the
            site.
            <br />
            You always have the right to request information about your stored
            data, its origin, its recipients, and the purpose of its collection
            at no charge. You also have the right to request that it be
            corrected, blocked, or deleted. You can contact us at any time using
            the address given in the legal notice if you have further questions
            about the issue of privacy and data protection. You may also, of
            course, file a complaint with the competent regulatory authorities.
            <br />
            When visiting our website, statistical analyses may be made of your
            surfing behavior. This happens primarily using cookies and
            analytics. The analysis of your surfing behavior is usually
            anonymous, i.e. we will not be able to identify you from this data.
            You can object to this analysis or prevent it by not using certain
            tools. Detailed information can be found in the following privacy
            policy. You can object to this analysis. We will inform you below
            about how to exercise your options in this regard.
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            II. General information and mandatory information
          </h3>
          <p>
            <strong>Data protection</strong>
          </p>
          <br />
          <p>
            The operators of this website take the protection of your personal
            data very seriously. We treat your personal data as confidential and
            in accordance with the statutory data protection regulations and
            this privacy policy. If you use this website, various pieces of
            personal data will be collected. Personal information is any data
            with which you could be personally identified. This privacy policy
            explains what information we collect and what we use it for. It also
            explains how and for what purpose this happens. Please note that
            data transmitted via the internet (e.g. via email communication) may
            be subject to security breaches. Complete protection of your data
            from third-party access is not possible.
          </p>
          <p className="heading-quaternary">
            <strong>
              Notice concerning the party responsible for this website
            </strong>
          </p>
          <p>
            The party responsible for processing data on this website is:
            <br />
            <br />
            Mannheim Business School gGmbH <br />
            L5, 6 <br />
            68161 Mannheim <br />
            Germany <br />
            E-Mail: info(at)mannheim-business-school.com <br />
            Tel.: +49 621 181 1281 <br />
            Fax: +49 621 181 1278 <br />
            Sitz Mannheim. Amtsgericht Mannheim HRB 10262. <br />
            Geschäftsführer: Prof. Dr. Jens Wüstemann <br />
            SteuerNr. 38107/03995
            <br />
            <br />
            For the content of the schools and departments, the specifc authors
            are responsible. If you have any questions concerning this content,
            please refer to the specific author.
          </p>
          <p className="heading-quaternary">
            <strong>
              Revocation of your consent to the processing of your data
            </strong>
          </p>
          <p>
            Many data processing operations are only possible with your express
            consent. You may revoke your consent at any time with future effect.
            An informal email making this request is sufficient. The data
            processed before we receive your request may still be legally
            processed.
          </p>
          <p className="heading-quaternary">
            <strong>
              Right to file complaints with regulatory authorities
            </strong>
          </p>
          <p>
            If there has been a breach of data protection legislation, the
            person affected may file a complaint with the competent regulatory
            authorities. The competent regulatory authority for matters related
            to data protection legislation is the data protection officer of the
            German state in which our company is headquartered. A list of data
            protection officers and their contact details can be found at the
            following link: www.bfdi.bund.de.
          </p>
          <p className="heading-quaternary">
            <strong>SSL or TLS encryption</strong>
          </p>
          <p>
            This site uses SSL or TLS encryption for security reasons and for
            the protection of the transmission of confidential content, such as
            the inquiries you send to us as the site operator. You can recognize
            an encrypted connection in your browser's address line when it
            changes from "http://" to "https://" and the lock icon is displayed
            in your browser's address bar. If SSL or TLS encryption is
            activated, the data you transfer to us cannot be read by third
            parties.
          </p>
          <p className="heading-quaternary">
            <strong>Information, blocking, deletion</strong>
          </p>
          <p>
            As permitted by law, you have the right to be provided at any time
            with information free of charge about any of your personal data that
            is stored as well as its origin, the recipient and the purpose for
            which it has been processed. You also have the right to have this
            data corrected, blocked or deleted. You can contact us at any time
            using the address given in our legal notice if you have further
            questions on the topic of personal data.
          </p>
          <p className="heading-quaternary">
            <strong>Opposition to promotional emails</strong>
          </p>
          <p>
            We hereby expressly prohibit the use of contact data published in
            the context of website legal notice requirements with regard to
            sending promotional and informational materials not expressly
            requested. The website operator reserves the right to take specific
            legal action if unsolicited advertising material, such as email
            spam, is received.
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            III. Data protection officer
          </h3>
          <p>
            Björn Schenk <br />
            L5, 6 <br />
            68131 Mannheim <br />
            Email: datenschutz@mannheim-business-school.com
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            IV. Data collection on our website
          </h3>
          <p className="heading-quaternary">
            <strong>General</strong>
          </p>
          <p>
            When you register on our platform using the signin form, we will
            collect the information you have provided (fist name, last name,
            email) in order to create a visitor-account on the platform. We will
            store and process this data on servers of our cloud service
            providers and send mailings as stated below ("Use of cloud
            services", "Mailings"). This registration process is necessary in
            order to provide an improved user experience. <br />
            Once the Online Experience Days have started (March 19, 2020), you
            will be able to log into your account. Thereby you will be able to
            access the information provided by the content providers. If you
            make use of the chat, please note that only your first name will be
            visible to all registered users of the platform. <br />
            You may delete your account at anytime before, during or after the
            Online Experience Days by sending an email. Otherwise, the data you
            have provided to register for our platform will be deleted from our
            servers within four weeks after the archive phase of the platform
            has ended, but no later than November 1, 2020. <br />
            If you are a member of one of our cooperating faculties, please
            contact us before signing up in order to authorize you as a member
            of the specific facutly. Your data will be processed in the same way
            as described above, but you will be registered for an
            editor-account. Please note that editor-accounts have no special
            access to other users data. The only privelege of these roles is to
            delete and answer other users messages in the main chat.
          </p>

          <p className="heading-quaternary">
            <strong>Cookies</strong>
          </p>
          <p>
            Some of our web pages use cookies. Cookies do not harm your computer
            and do not contain any viruses. Cookies help make our website more
            user-friendly, efficient, and secure. Cookies are small text files
            that are stored on your computer and saved by your browser. Most of
            the cookies we use are so-called "session cookies." They are
            automatically deleted after your visit. Other cookies remain in your
            device's memory until you delete them. These cookies make it
            possible to recognize your browser when you next visit the site. You
            can configure your browser to inform you about the use of cookies so
            that you can decide on a case-by-case basis whether to accept or
            reject a cookie. Alternatively, your browser can be configured to
            automatically accept cookies under certain conditions or to always
            reject them, or to automatically delete cookies when closing your
            browser. Disabling cookies may limit the functionality of this
            website. Cookies which are necessary to allow electronic
            communications or to provide certain functions you wish to use (such
            as the shopping cart) are stored pursuant to Art. 6 paragraph 1,
            letter f of DSGVO. The website operator has a legitimate interest in
            the storage of cookies to ensure an optimized service provided free
            of technical errors. If other cookies (such as those used to analyze
            your surfing behavior) are also stored, they will be treated
            separately in this privacy policy.
          </p>
          <p className="heading-quaternary">
            <strong>Server log files</strong>
          </p>
          <p>
            The website provider automatically collects and stores information
            that your browser automatically transmits to us in "server log
            files". These are: Browser type and browser version, operating
            system used, referrer URL, host name of the accessing computer, time
            of the server request, IP address. These data will not be combined
            with data from other sources. The basis for data processing is Art.
            6 (1) (f) DSGVO, which allows the processing of data to fulfill a
            contract or for measures preliminary to a contract.
          </p>
          <p className="heading-quaternary">
            <strong>Use of cloud services</strong>
          </p>
          <p>We use these cloud providers while we process your request:</p>
          <ul>
            <li>
              Amazon Web Services – Hosting, API, Database, Analytics,
              Authentication
            </li>
            <li>
              Benjamin Ebner Digital Media Solutions - Management of cloud
              services
            </li>
          </ul>
          <p>
            In this process, your data might be processed and stored on servers
            outside of the European Union. We have closed contracts governing
            the processing of commissioned data with all these companies. In
            cases where a transfer outside the EU is possible, we have closed
            contracts based on the EU-Model clauses.
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            V. Mailings
          </h3>
          <p className="heading-quaternary">
            <strong>Registration Email</strong>
          </p>
          <p>
            Should you register on our platform via the signup form, we will
            collect the data entered on the form, including the contact details
            you provide, to create your user account and inform you about
            updates of our platform. Furthermore, a contact in our AWS Account
            will be created and you will receive an E-Mail, confirming your
            account registration. We do not share this information without your
            permission. We will, therefore, process any data you enter onto the
            signup form only with your consent per Art. 6 (1)(a) DSGVO. You may
            revoke your consent at any time. An informal email making this
            request is sufficient. The data processed before we receive your
            request may still be legally processed. We will retain the data you
            provide on the signup form until you request its deletion, revoke
            your consent for its storage, or the purpose for its storage no
            longer pertains (e.g. after fulfilling your request). Any mandatory
            statutory provisions, especially those regarding mandatory data
            retention periods, remain unaffected by this provision.
          </p>
          <p className="heading-quaternary">
            <strong>Notification Emails</strong>
          </p>
          <p>
            This website uses AWS Pinpoint and AWS SES to send notification
            emails to inform you about ongoing activities of our platform. The
            supplier is Amazon Web Sevices Inc., 410 Terry Avenue North, Seattle
            WA 98109, United States. AWS Pinpoint is a service which organizes
            and analyzes the distribution of newsletters. The data you provide
            (e.g. your email address) to subscribe to our newsletter will be
            stored on AWS servers. Sending our newsletters with AWS enables us
            to analyze the behavior of recipients. Among other things, we can
            find out how many recipients have opened the email and how often
            various links contained therein are clicked. <br />
            Data processing is based on Art. 6 (1) (a) DSGVO. You may revoke
            your consent at any time by unsubscribing to the notification email.
            The data processed before we receive your request may still be
            legally processed. If you do not want your usage of the newsletter
            to be analyzed by AWS, you will have to unsubscribe from the
            newsletter. For this purpose, we provide a link in every newsletter
            we send. The data provided when registering for the newsletter will
            be used to distribute the newsletter until you cancel your
            subscription when said data will be deleted from our servers and
            those of Sendgrid. Data we have stored for other purposes (e.g.
            email addresses for the members area) remains unaffected.
          </p>
          <p>
            <p>
              In case of failover, this website might use Sendgrid to send
              notification emails to inform you about ongoing activities of our
              platform. The supplier is Sendgrid Inc., 1801 California St,
              Denver, CO 80202. Sendgrid is a service which organizes and
              analyzes the distribution of newsletters. The data you provide
              (e.g. your email address) to subscribe to our newsletter will be
              stored on Sendgrid servers. Sending our newsletters with Sendgrid
              enables us to analyze the behavior of recipients. Among other
              things, we can find out how many recipients have opened the email
              and how often various links contained therein are clicked. For
              more information on how data is analyzed by Sendgrid, please visit
              www.sendgrid.com. <br />
              Data processing is based on Art. 6 (1) (a) DSGVO. You may revoke
              your consent at any time by unsubscribing to the notification
              email. The data processed before we receive your request may still
              be legally processed. If you do not want your usage of the
              newsletter to be analyzed by Sendgrid, you will have to
              unsubscribe from the newsletter. For this purpose, we provide a
              link in every newsletter we send. The data provided when
              registering for the newsletter will be used to distribute the
              newsletter until you cancel your subscription when said data will
              be deleted from our servers and those of Sendgrid. Data we have
              stored for other purposes (e.g. email addresses for the members
              area) remains unaffected. For more information, see the privacy
              policy of Sendgrid at www.sendgrid.com.
            </p>
          </p>
          <h3 className="heading-tertiary u-margin-top-m u-margin-bottom-s">
            VI. Analytics and advertising
          </h3>
          <p className="heading-quaternary">
            <strong>eTracker</strong>
          </p>
          <p>
            Our website uses the etracker analytics service. The service is
            provided by etracker GmbH, Erste Brunnenstraße 1, 20459 Hamburg,
            Germany. This data can be used to create pseudonymous usage
            profiles. Cookies may be used for this purpose. Cookies are small
            text files stored in your local browser cache. These cookies enable
            the recognition of your browser. The data collected by the etracker
            technologies are not used to determine the personal identity of
            website visitors and are not compiled together with personal data
            related to the person referred to by the pseudonym unless expressly
            agreed to by the person concerned.
            <br />
            etracker cookies remain on your device until you delete them. <br />
            The storage of etracker cookies is based on Art. 6 (1) (f) DSGVO.
            The website operator has a legitimate interest in analyzing user
            behavior to optimize both its website and its advertising. <br />
            You can object to the collection and storage of your data at any
            time with future effect. To refuse the collection and storage of
            your visitor data for the future, you can use the etracker opt-out
            cookie at the following link. This means that in the future no
            visitor information will be collected and stored by etracker in your
            browser:{" "}
            <a href="http://www.etracker.de/privacy?sid=e277a0d5c166d82ae7906fd32129acf8&id=privacy&et=V23Jbb&languageId=2">
              www.etracker.de
            </a>
            <br />. Using this will set an opt-out-cookie called "cntcookie"
            from etracker. Please do not delete this cookie if you wish your
            refusal to consent to remain valid. For more information, see the
            etracker privacy policy:{" "}
            <a href="https://www.etracker.com/en/data-privacy/">
              www.etracker.com
            </a>
            .
          </p>
          <p className="heading-quaternary">
            <strong>Web push notifications</strong>
          </p>
          <p>
            If you enable wep push notifications in your browser, a service of
            that particular browser will be used to provide this feature. For
            sending push messages, only anonymous or pseudonymous data is
            transmitted. You can object to the receipt of notifications at any
            time through the settings of your browser. For information about
            opting out of web push notifications for each browser, see
            <a href="https://www.signalize.com/blog/google-chrome-push-notifications-abmeldenbmeldens-abmelden/">
              Google Chrome
            </a>
            ,
            <a href="https://www.signalize.com/blog/firefox-push-notifications-abmelden/">
              Mozilla Firefox
            </a>{" "}
            ,
            <a href="https://www.signalize.com/blog/safari-push-notifications-abmelden/">
              Apple Safari
            </a>{" "}
            and
            <a href="https://www.signalize.com/blog/opera-push-notifications-abmelden/">
              {" "}
              Opera
            </a>
          </p>
          <p className="heading-quaternary">
            <strong>
              Completion of an outsourced data processing contract
            </strong>
          </p>
          <p>
            We have entered into an agreement with etracker for the outsourcing
            of our data processing and fully implement the strict requirements
            of the German data protection authorities when using etracker.
          </p>
        </div>
      </div>
    </Segment>
  </Wrapper>
);

export default Privacy;
