import React, { Component } from "react";
import { DropTarget } from "react-dnd";
import MMMCard1 from "./MMMCard.1";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "semantic-ui-react";

const Types = {
  ITEM: "card"
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    highlighted: monitor.canDrop(),
    hovered: monitor.isOver(),
    item: monitor.getItem()
  };
}

class MMMBucket extends Component {
  state = {};
  render() {
    let normalStyle = {
      border: "4px dashed white",
      borderRadius: "5px"
    };
    let hoverStyle = {
      border: "4px dashed white",
      borderRadius: "5px",
      background: "rgba(151, 184, 98, .8)"
    };

    const { connectDropTarget, highlighted, hovered } = this.props;
    return connectDropTarget(
      <div className="cardlist-wrapper">
        <h3 style={{ color: "white", textAlign: "center" }}>Your Selection</h3>
        {this.props.cards && this.props.cards.length !== 0 ? (
          <>
            <div
              className={
                highlighted
                  ? "cardlist--bucket--highlighted"
                  : "cardlist--bucket"
              }
              style={hovered ? hoverStyle : normalStyle}
            >
              {this.props.cards.map(card => {
                return (
                  <MMMCard1
                    card={card}
                    onClick={() => this.props.onDelete(card.id)}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <div
            className="cardlist__placeholder"
            style={hovered ? hoverStyle : normalStyle}
          >
            {/* <FontAwesomeIcon
              size={"3x"}
              icon={"download"}
              className={highlighted ? "cardlist-icon--animated" : ""}
            /> */}
            <Icon
              name="download"
              size="large"
              className={highlighted ? "cardlist-icon--animated" : ""}
            />
            <h2 className="heading-secondary heading-secondary--white u-center-text">
              Drop here
            </h2>
          </div>
        )}
        <div className="mmm__disclaimer">
          <p>
            This is only an exemplary tool. The individual study plan of every
            student always must fit to the{" "}
            <a
              href="https://www2.uni-mannheim.de/studienbueros/pruefungen/pruefungsordungen/"
              target="_blank"
              rel="noopener noreferrer"
            >
              examination regulations
            </a>{" "}
            and the{" "}
            <a
              href="https://www.bwl.uni-mannheim.de/media/Fakultaeten/bwl/Dokumente/Studium/MMM/Modulkatalog_MMM_Englisch.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              module catalog
            </a>
            . In some Double Degree programs, you have to specialize in a
            certain area - please consult the homepage if you have further
            questions.
          </p>
        </div>
      </div>
    );
  }
}

export default DropTarget(Types.ITEM, {}, collect)(MMMBucket);
