import React, { Component } from 'react';
import {Icon, Header, Segment, Transition} from 'semantic-ui-react';
import { afterSignupInfo } from '../dummyContent';
import withContext from '../Context/withContext'

export class AfterSignupMessage extends Component {
  render() {
    if(this.props.authState === "confirmSignUp") {
      return (
        <Transition transitionOnMount animation='scale' duration={500}>
         <Segment style={{marginTop:'1.5rem'}}>
           <Header as='h2' icon textAlign='center' style={{cursor: "pointer"}}>
             <Icon name='check' circular />
             <Header.Content style={{marginBottom: '1rem'}}>
               {afterSignupInfo.title[this.props.state.lang]}  
            </Header.Content>
            <Header.Subheader>
              {afterSignupInfo.message[this.props.state.lang]}
            </Header.Subheader>
           </Header>
         </Segment>
        </Transition>
      )
    } else {
      return null
    }
    
  }
}

export default withContext(AfterSignupMessage)
