import React, { Component } from "react";
import { render } from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Menu } from "semantic-ui-react";

const SortableItem = SortableElement(({ value }) => (
  <Menu.Item>{value}</Menu.Item>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <Menu vertical>
      {items
        .sort((a, b) => a.sort - b.sort)
        .map((value, index) => (
          <SortableItem
            key={`item-${value.sort}`}
            index={index}
            value={value.title}
          />
        ))}
    </Menu>
  );
});

class SortableComponent extends Component {
  state = {
    items: [
      {
        title: "Sort 1",
        sort: 1
      },
      {
        title: "Sort 2",
        sort: 2
      },
      {
        title: "Sort 4",
        sort: 4
      },
      {
        title: "Sort 3",
        sort: 3
      },
      {
        title: "Sort 5",
        sort: 5
      }
    ]
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let oldArray = this.state.items;
    let draggedItem = this.state.items[oldIndex];
    if (newIndex > oldIndex) {
      let betweenVals = oldArray.slice(oldIndex + 1, newIndex + 1);
      betweenVals.map(bv => {
        bv.sort--;
        //Call API updateItem for each and subtract the sort by 1
      });
      draggedItem.sort = newIndex + 1; // Call updateItem on the item and update sort to newIndex +1
    } else {
      let betweenVals = oldArray.slice(newIndex, oldIndex);
      betweenVals.map(bv => {
        bv.sort++;
        //Call API for each and add the sort by 1
      });
      draggedItem.sort = newIndex + 1;
    }

    // let arrayWithoutDragged = oldArray.filter(item => {
    //   return item.sort !== draggedItem.sort
    // })
    // let betweenVals = arrayWithoutDragged.slice(0, newIndex)
    // let allBetween = oldArray.filter(item => {
    //   return item.sort > oldIndex && item.sort < newIndex
    // })

    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex) /// 1 2 3 4     1342
    }));
  };
  render() {
    return (
      <div style={{ paddingTop: "10rem" }}>
        <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
      </div>
    );
  }
}

export default SortableComponent;
