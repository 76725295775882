import React, { useRef, useEffect, useState } from "react";

import Agenda from "./Agenda";
import Features from "./Features";
import Landing from "./Landing";
import Title from "./Title";
import Bottom from "./Bottom";
import { basicInfo, bottomInfo } from "../dummyContent";
import { Loader } from "semantic-ui-react";
import { API, graphqlOperation } from "aws-amplify";
import { GET_SETTINGS } from "../graphql/queries";

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const Start = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const landingRef = useRef(null);
  const executeScroll = () => scrollToRef(landingRef);

  const getSettings = async () => {
    let eventSettings = await API.graphql(
      graphqlOperation(GET_SETTINGS, { eventName: process.env.REACT_APP_DAY })
    );
    setSettings(eventSettings.data.getSettings[0]);
    setLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  if (loading) {
    return <Loader active inverted size="massive" />;
  } else {
    return (
      <>
        <Title titles={basicInfo} settings={settings} />
        <Landing ref={landingRef} />
        <Features />
        <Agenda />
        <Bottom scrollToTop={executeScroll} text={bottomInfo} />
      </>
    );
  }
};

export default Start;
