import React from "react";
import { Header, Button, Icon, Message } from "semantic-ui-react";
import styled from "styled-components";
import withContext from "../Context/withContext";
import { withRouter } from "react-router-dom";

const Wrapper = styled.div`
  padding: 5rem 0;
  padding-top: 1rem;
  color: white;
`;

const MessageWrapper = styled.div`
  width: 30rem;
  margin: 0 auto;
`;

const changesText = {
  text: {
    en: `
    Due to the new dates of the Abitur, the Bachelor Day takes now place on 16. June 2020.
    `,
    de: `
    Der Bachelortag wurde auf Grund der neuen Abiturtermine auf den 16. Juni 2020 verschoben.
    `,
  },
  title: {
    en: "Please note",
    de: "Hinweis",
  },
};

const Title = ({ titles, state, settings, history }) => (
  <>
    <Wrapper>
      {process.env.REACT_APP_DAY === "bachelor" && (
        <MessageWrapper>
          <Message icon info>
            <Icon name="info circle" />
            <Message.Content>
              <Message.Header>{changesText.title[state.lang]}</Message.Header>
              {changesText.text[state.lang]}
            </Message.Content>
          </Message>
        </MessageWrapper>
      )}

      <Header
        style={{ fontSize: "3.5rem", color: "white" }}
        size="huge"
        textAlign="center"
      >
        {titles.eventName[state.lang]}
        <Header.Subheader
          style={{ color: "white", fontSize: "2rem", paddingTop: ".7rem" }}
        >
          {titles.eventHost[state.lang]}
        </Header.Subheader>
        <Header.Subheader style={{ color: "white", paddingTop: "1rem" }}>
          {titles.eventDate[state.lang]}
        </Header.Subheader>
      </Header>
      {Date.now() > new Date(settings.loginStartDate) &&
        Date.now() < new Date(settings.loginEndDate) && (
          <SigninButton>
            <Button
              icon
              labelPosition="right"
              size="large"
              onClick={() => history.push("/inside")}
            >
              Log in <Icon name="chevron circle right" />
            </Button>
          </SigninButton>
        )}
    </Wrapper>
  </>
);

export default withRouter(withContext(Title));

const SigninButton = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: -3rem;
`;
