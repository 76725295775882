import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';
import withContext from '../Context/withContext';

const FeatureCard = ({feature, state}) => (
  <Segment placeholder style={{width: '15rem', height: '10rem', padding: '1rem', margin: '2rem'}}>
    <Header icon style={{color: "rgb(35, 46, 88)"}}>
      <Icon name={feature.icon} style={{color: "rgb(35, 46, 88)"}}/>
      {feature.title}
      <Header.Subheader >
        {feature.desc[state.lang]}
      </Header.Subheader>
    </Header>
  </Segment>
)

export default withContext(FeatureCard)
