import React from "react";
import { EditorState, RichUtils, AtomicBlockUtils } from "draft-js";

const Image = props => {
  if (!!props.src) {
    return <img src={props.src} width="100%" />;
  }
  return null;
};

const VideoInsert = props => {
  if (!!props.src) {
    return (
      <video poster={props.poster} src={props.src} width="100%" controls />
    );
  }
};

const EmbedPDF = props => {
  if (!!props.src) {
    return (
      <>
        <p className="draftjs-small-text">
          PDF not displaying correctly?{" "}
          <a href={props.src} alt={"PDF"}>
            Download it here
          </a>
        </p>
        <embed src={props.src} width="100%" style={{ height: "50rem" }} />
      </>
    );
  }
};

const EmdedIframe = props => {
  if (!!props.src) {
    return (
      <>
        <iframe
          src={props.src}
          width="100%"
          height="400px"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </>
    );
  }
};

const Media = props => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src, cover } = entity.getData();
  const type = entity.getType();

  let media;

  if (type === "image") {
    media = <Image src={src} />;
  }

  if (type == "IMAGE") {
    media = <Image src={src} />;
  }

  if (type === "video") {
    media = (
      <VideoInsert
        src={src}
        poster={
          cover !== null
            ? cover
            : "https://assets.online-days.com/video/UniMA_Frame.jpg"
        }
      />
    );
  }

  if (type === "pdf") {
    media = <EmbedPDF src={src} />;
  }

  if (type === "iframe") {
    media = <EmdedIframe src={src} />;
  }

  return media;
};

export const mediaBlockRenderer = block => {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false
    };
  }

  return null;
};
