export const events = [
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Business",
      de: "BWL",
      id: 1,
    },
    type: "Stream",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Law",
      de: "Jura",
      id: 2,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T09:00:00+01:00"),
    endTime: new Date("2020-03-19T10:00:00+01:00"),
    department: {
      en: "Economics",
      de: "VWL",
      id: 3,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    department: {
      en: "Social Studies",
      de: "Sozialwissenschaften",
      id: 6,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Humanities",
      de: "Geisteswissenschaften",
      id: 4,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Business Informatics and Mathematics",
      de: "Wirtschaftsinformatik und Mathematik",
      id: 5,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Business Informatics and Mathematics",
      de: "Wirtschaftsinformatik und Mathematik",
      id: 5,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Humanities",
      de: "Philosophische Fakultät",
      id: 4,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Humanities",
      de: "Philosophische Fakultät",
      id: 4,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Business",
      de: "BWL",
      id: 1,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    department: {
      en: "Business",
      de: "BWL",
      id: 1,
    },
    type: "Chat",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T12:00:00+01:00"),
    endTime: new Date("2020-03-19T13:00:00+01:00"),
    department: {
      en: "Economics",
      de: "VWL",
      id: 3,
    },
    type: "Stream",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T13:00:00+01:00"),
    endTime: new Date("2020-03-19T14:00:00+01:00"),
    department: {
      en: "Economics",
      de: "VWL",
      id: 3,
    },
    type: "Stream",
  },
  {
    title: {
      en: "Exemplary Event",
      de: "Ein Beispiel-Event",
    },
    desc: {
      en:
        "The University of Mannheim offers first-rate bachelor’s, master’s and doctoral programs. Numerous university rankings confirm the high quality of the programs available, with Business Administration, Economics, Psychology, Sociology, Political Science, the Romance Languages, History, English and American Studies, German Studies, and Business Informatics being ranked in top positions on several occasions.",
      de:
        "Die Universität Mannheim bietet erstklassige Bachelor-, Master- und Promotions­programme. Zahlreiche Hochschul­rankings bestätigen die hohe Qualität des Studien­angebots. Die Disziplinen BWL, VWL, Psychologie, Soziologie, Politik­wissenschaft, Romanistik, Geschichte, Anglistik, Germanistik und Wirtschafts­informatik belegen regelmäßig Spitzenpositionen in nationalen und internationalen Vergleichen.",
    },
    startTime: new Date("2020-03-19T14:00:00+01:00"),
    endTime: new Date("2020-03-19T15:00:00+01:00"),
    department: {
      en: "Economics",
      de: "VWL",
      id: 3,
    },
    type: "Stream",
  },
];

export const options = {
  en: [
    { key: 1, text: "All", value: "" },
    { key: 7, text: "Department of Law", value: "5c580ddc60344800048471f9" },
    {
      key: 6,
      text: "Department of Economics",
      value: "5c5cabb19363f60004c67145",
    },
    { key: 2, text: "Business School", value: "5c548b623df54832f894645c" },
    {
      key: 5,
      text: "School of Social Sciences",
      value: "5c64057689535f00047q3445",
    },
    { key: 3, text: "School of Humanities", value: "5c5944e511f43b0004fee636" },
    {
      key: 4,
      text: "School of Business Informatics and Mathematics",
      value: "5c64057689535f00047f0adb",
    },
    { key: 8, text: "General Informations", value: "5c548b623df54832f893753b" },
  ],
  de: [
    { key: 1, text: "Alle", value: "" },
    {
      key: 7,
      text: "Abteilung Rechtswissenschaft",
      value: "5c580ddc60344800048471f9",
    },
    {
      key: 6,
      text: "Abteilung Volkswirtschaftslehre",
      value: "5c5cabb19363f60004c67145",
    },
    {
      key: 2,
      text: "Fakultät für Betriebswirtschaftslehre",
      value: "5c548b623df54832f894645c",
    },
    {
      key: 5,
      text: "Fakultät für Sozialwissenschaften",
      value: "5c64057689535f00047q3445",
    },
    {
      key: 3,
      text: "Philosophische Fakultät",
      value: "5c5944e511f43b0004fee636",
    },
    {
      key: 4,
      text: "Fakultät für Wirtschaftsinformatik und -mathematik",
      value: "5c64057689535f00047f0adb",
    },
    { key: 8, text: "Zentrale Inhalte", value: "5c548b623df54832f893753b" },
  ],
};

export const types = [
  { key: 1, text: "All", value: "" },
  { key: 2, text: "Chat", value: "Chat" },
  { key: 3, text: "Stream", value: "Stream" },
];

export const times = [
  { key: 1, text: "All", value: "" },
  { key: 2, text: "08:00", value: "08:00" },
  { key: 3, text: "09:00", value: "09:00" },
  { key: 4, text: "10:00", value: "10:00" },
  { key: 5, text: "11:00", value: "11:00" },
  { key: 6, text: "12:00", value: "12:00" },
  { key: 7, text: "13:00", value: "13:00" },
  { key: 8, text: "14:00", value: "14:00" },
  { key: 9, text: "15:00", value: "15:00" },
  { key: 10, text: "16:00", value: "16:00" },
  { key: 11, text: "17:00", value: "17:00" },
  { key: 12, text: "18:00", value: "18:00" },
  { key: 13, text: "19:00", value: "19:00" },
];

export const bottomInfo = {
  en: "What are you waiting for? Sign up now!",
  de: "Registriere dich jetzt für unsere Online Experience Days!",
};

export const errorMessage = {
  en: "The page you were looking for cannot be found",
  de: "Die Seite die Sie suchen kann leider nicht gefunden werden",
};

export const basicInfo =
  process.env.REACT_APP_DAY === "master"
    ? {
        eventHost: {
          en: "Master's Day",
          de: "Mastertag",
        },
        eventDate: {
          en: "19 March 2020",
          de: "19. März 2020",
        },
        eventName: {
          en: "Online Experience Days",
          de: "Online Experience Days",
        },
      }
    : {
        eventHost: {
          en: "Bachelor's Day",
          de: "Bachelortag",
        },
        eventDate: {
          en: "16 June 2020",
          de: "16. Juni 2020",
        },
        eventName: {
          en: "Online Experience Days",
          de: "Online Experience Days",
        },
      };

export const features = [
  {
    title: "Chat",
    icon: "chat",
    desc: {
      en: "Ask all your questions in our Chats",
      de: "Stelle alle deine Fragen in unseren Chats",
    },
  },
  {
    title: "Livestream",
    icon: "video camera",
    desc: {
      en: "Tune in to our Livestream for the latest updates",
      de: "Sieh dir Präsentationen in unserem Livestream an",
    },
  },
  {
    title: "Likes",
    icon: "heart",
    desc: {
      en: "Save all relevant information to your personal profile",
      de: "Speichere die für dich relevanten Informationen in deinem Profil",
    },
  },
  {
    title: "Video",
    icon: "film",
    desc: {
      en: "Take a look at our specially produced videos",
      de: "Erfahre in vielen Videos mehr über die Studiengänge",
    },
  },
];

export const footerInfo = {
  privacy: {
    en: "Data Privacy",
    de: "Datenschutz",
  },
  imprint: {
    en: "Legal Notice",
    de: "Impressum",
  },
  contact: {
    en: "Contact",
    de: "Kontakt",
  },
};

export const afterSignupInfo = {
  title: {
    en: "Welcome",
    de: "Willkommen",
  },
  message: {
    en:
      "We have sent you a confirmation link via E-Mail. Please click the link to finish your registration.",
    de:
      "Wir haben dir einen Link zur Bestätigung deines Accounts geschickt. Klicke ihn, um deine Registrierung abzuschließen.",
  },
};

export const programs = {
  en: [
    { key: 1, text: "All", value: "Alle" },
    { key: 13, value: "Betriebswirtschaft", text: "Business Administration" },
    { key: 11, value: "Volkswirtschaft", text: "Economics" },
    { key: 12, value: "Pädagogik", text: "Education" },
    { key: 2, value: "Geschichte", text: "History" },
    {
      key: 10,
      value: "Sprache und Literatur",
      text: "Language and Literature",
    },
    { key: 8, value: "Jura", text: "Law" },
    {
      key: 3,
      value: "Mathematik und Informatik",
      text: "Mathematics and Informatics",
    },
    {
      key: 4,
      value: "Medien und Kommunikation",
      text: "Media and Communication",
    },
    { key: 5, value: "Philosophie", text: "Philosophy" },
    { key: 6, value: "Politik", text: "Political Science" },
    { key: 7, value: "Psychologie", text: "Psychology" },
    { key: 9, value: "Soziologie", text: "Sociology" },
  ],
  de: [
    { key: 1, text: "Alle", value: "Alle" },
    { key: 13, value: "Betriebswirtschaft", text: "Betriebswirtschaft" },
    { key: 2, value: "Geschichte", text: "Geschichte" },
    { key: 8, value: "Jura", text: "Jura" },
    {
      key: 3,
      value: "Mathematik und Informatik",
      text: "Mathematik und Informatik",
    },
    {
      key: 4,
      value: "Medien und Kommunikation",
      text: "Medien und Kommunikation",
    },
    { key: 12, value: "Pädagogik", text: "Lehramt" },
    { key: 5, value: "Philosophie", text: "Philosophie" },
    { key: 6, value: "Politik", text: "Politik" },
    { key: 7, value: "Psychologie", text: "Psychologie" },
    { key: 9, value: "Soziologie", text: "Soziologie" },
    { key: 10, value: "Sprache und Literatur", text: "Sprache und Literatur" },
    { key: 11, value: "Volkswirtschaft", text: "Volkswirtschaft" },
  ],
};

export const languageTranslateEN = {
  en: "English",
  de: "Englisch",
};

export const languageTranslateDE = {
  en: "German",
  de: "Deutsch",
};

export const timezone = {
  en: "CET",
  de: "MEZ",
};
