import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MMMMessage extends Component {
  state = {};
  render() {
    let successStyle = {
      background: "rgb(151, 184, 98)"
    };
    let failStyle = {
      background: "rgb(195, 47, 53)"
    };
    return (
      <div
        className={"mmm-message"}
        style={this.props.success ? successStyle : failStyle}
      >
        <Icon
          className="mmm-message__icon"
          name={this.props.success ? "check circle" : "times"}
          inverted
          size="large"
        />
        <div className="mmm-message__info">
          <h2 style={{ color: "white", textAlign: "center" }}>
            {this.props.title}
          </h2>
          <p style={{ color: "white", textAlign: "center" }}>
            {this.props.text}
          </p>
        </div>
      </div>
    );
  }
}

export default MMMMessage;
