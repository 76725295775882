import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

import Home from "./Home/Home";
import Chat from "./Chat/Chat";
import Stream from "./Stream/Stream";
import Profile from "./Profile/Profile";
import Information from "./Information/Information";
import { withAuthenticator } from "aws-amplify-react";
import { Loader } from "semantic-ui-react";
import withContext from "./Context/withContext";
import Signin from "./Start/SigninForm";

import UserContext from "./Context/userContext";
import SigninForm from "./Start/SigninForm";

export class LoginArea extends Component {
  state = {
    userGroups: [],
    loading: true
  };

  async componentDidMount() {
    // this.setState({loading: true})
    await this.props.state.onSetUserGroup();
    await this.props.state.onSetUser();
    this.setState({ loading: false });
  }

  async componentWillUnmount() {
    this.setState({ userGroups: [] });
  }

  render() {
    if (this.state.loading) {
      return <Loader active />;
    } else {
      return (
        <UserContext.Provider value={{ groups: this.state.userGroups }}>
          <Switch>
            <Route path={`${this.props.match.url}`} exact component={Home} />
            <Route
              path={`${this.props.match.url}/information`}
              exact
              component={Home}
            />
            <Route
              path={`${this.props.match.url}/profile`}
              exact
              component={Profile}
            />
            <Route
              path={`${this.props.match.url}/chat/:chatId`}
              exact
              component={Chat}
            />
            <Route
              path={`${this.props.match.url}/stream/:streamId`}
              exact
              component={Stream}
            />
          </Switch>
        </UserContext.Provider>
      );
    }
  }
}

export default withAuthenticator(withContext(withRouter(LoginArea)), false, [
  <SigninForm />
]);
