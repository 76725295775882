import React from "react";
import { Dropdown } from "semantic-ui-react";

class HeaderStyleDropdown extends React.Component {
  onToggle = (event) => {
    let value = event.target.value;
    this.props.onToggle(value);
  };

  render() {
    return (
      <select
        value={this.props.active}
        onChange={this.onToggle}
        style={{
          marginLeft: "1rem",
          height: "2.5rem",
          borderRadius: "5px",
          padding: ".5rem",
        }}
      >
        <option value="unstyled">Paragraph</option>
        {this.props.headerOptions.map((heading) => {
          return <option value={heading.style}>{heading.label}</option>;
        })}
      </select>
      // <Dropdown onChange={this.onToggle}>
      //   <Dropdown.Item value="">Header Level</Dropdown.Item>
      //   {this.props.headerOptions.map(heading => (
      //     <Dropdown.Item value={heading.style}>
      //       {heading.label}
      //     </Dropdown.Item>
      //   ))

      //   }
      // </Dropdown>
    );
  }
}
export default HeaderStyleDropdown;
