import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GET_EVENT_BY_ID } from "../graphql/queries";
import { Label, Icon, Comment } from "semantic-ui-react";

export class OriginEventInfo extends Component {
  state = {
    event: {},
    loading: true
  };

  async componentDidMount() {
    let response = await API.graphql(
      graphqlOperation(GET_EVENT_BY_ID, { entityId: this.props.id })
    );
    this.setState({
      event: response.data.getEventById.title.en,
      loading: false
    });
  }

  async componentDidUpdate() {
    let response = await API.graphql(
      graphqlOperation(GET_EVENT_BY_ID, { entityId: this.props.id })
    );
    this.setState({
      event: response.data.getEventById.title.en,
      loading: false
    });
  }

  render() {
    if (this.state.loading) {
      return <div></div>;
    } else {
      return (
        <Comment.Metadata style={{ display: "block" }}>
          <Label
            style={{
              marginLeft: "-.7rem",
              marginTop: ".3rem"
            }}
            size={"tiny"}
            color={"red"}
          >
            <Icon name="chat" />
            Chat: {this.state.event}
          </Label>
        </Comment.Metadata>
      );
    }
  }
}

export default OriginEventInfo;
