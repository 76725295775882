import React, { Component } from "react";
import { DragSource } from "react-dnd";
import { Icon } from "semantic-ui-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Types = {
  ITEM: "card"
};

const itemSource = {
  beginDrag(props) {
    return props.card;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    return props.handleDrop(props.card.id);
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class MMMCard extends Component {
  state = {
    show: false
  };

  show = () => {
    this.setState(prevState => {
      return {
        show: !prevState.show
      };
    });
  };

  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(
      <div
        className={
          (this.props.card.prio === "essential"
            ? "MMMCard MMMCard--essential "
            : "MMMCard ") +
          (this.props.card.prio === "must"
            ? "MMMCard MMMCard--must"
            : "MMMCard")
        }
      >
        <div className="MMMCard__header" onClick={this.show}>
          {/* <FontAwesomeIcon
            icon={this.state.show ? "chevron-up" : "chevron-down"}
            className="MMMCard__header-icon"
          /> */}
          <Icon
            name={this.state.show ? "chevron up" : "chevron down"}
            className="MMMCard__header-icon"
            style={{ display: "inline", paddingTop: ".7rem" }}
          />
          <h3 className="heading-tertiary">{this.props.card.title}</h3>
        </div>

        {this.state.show && (
          <div className="MMMCard__show">
            <p>{this.props.card.desc}</p>
          </div>
        )}
      </div>
    );
  }
}

export default DragSource(Types.ITEM, itemSource, collect)(MMMCard);
