import React from 'react';
import { Card } from 'semantic-ui-react';

const FilterResults = (props) => (
  <Card.Group 
    centered textAlign="left" style={style}>
    {props.renderData()}
  </Card.Group>
)

export default FilterResults


const style = {
  backgroundColor: 'rgba(250,250,250,.5)', 
  maxHeight: '100%', 
  paddingTop: '2rem'
}