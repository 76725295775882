export const UPDATE_ITEM_EDITOR = `
  mutation UpdateItem ($entityId: ID, $editorState: String, $language: String){
    updateItemEditor(entityId: $entityId, editorState: $editorState, language: $language){
      editorState {
        en
        de
      }
    }
  }
`;

export const UPDATE_STARTPAGE_EDITOR = `
  mutation UpdateStartpage ($entityId: ID, $startpage: String, $language: String){
    updateStartpage(entityId: $entityId, startpage: $startpage, language: $language){
      title {
        en
        de
      }
    }
  }
`;

export const CREATE_EMPTY_ITEM = `
  mutation createItem($moduleId: ID, $title: LanguageInputType) {
    createItem(moduleId: $moduleId, title: $title) {
      entityId
    }
  }
`;

export const SYNC_ITEM = `
mutation createItemWithEditor($moduleId: ID, $title: LanguageInputType, $editorState: LanguageInputType) {
  createItemWithEditor(moduleId: $moduleId, title: $title, editorState: $editorState) {
    entityId
  }
}
`;

export const DELETE_ITEM = `
  mutation deleteItem($entityId: ID) {
    deleteItem(entityId: $entityId) {
      title {
        en
        de
      }
    }
  }
`;

export const UPDATE_ITEM_TITLE = `
  mutation updateItem($entityId: ID, $title: LanguageInputType) {
    updateItem(entityId: $entityId, title: $title) {
      title {
        en
        de
      }
    }
  }
`;

export const CREATE_MODULE = `
  mutation CreateModule ($deptId: ID, $title: LanguageInputType, $day: String) {
    createModule(deptId: $deptId, title: $title, day: $day) {
      title {
        en
        de
      }
    }
  }
`;

export const CREATE_MODULE_WITH_ID = `
  mutation CreateModule ($deptId: ID, $title: LanguageInputType, $entityId: ID) {
    createModuleWithID(deptId: $deptId, title: $title, entityId: $entityId) {
      title {
        en
        de
      }
    }
  }
`;

export const DELETE_MODULE = `
  mutation DeleteModule ($entityId: ID) {
    deleteModule(entityId: $entityId) {
      entityId
    }
  }
`;

export const UPDATE_MODULE = `
  mutation UpdateModule ($entityId: ID, $title: LanguageInputType) {
    updateModule(entityId: $entityId, title: $title) {
      entityId
    }
  }
`;

export const SYNC_DEPTARTMENTS = `
mutation syncDepartments($title: LanguageInputType, $startpage: LanguageInputType, $entityId: ID) {
  createDepartment(title: $title, startpage: $startpage, entityId: $entityId) {
    title {
      en
      de
    }
  }
}
`;

export const CREATE_EVENT = `
  mutation CreateEvent($input: EventInputType) {
    createEvent(input: $input) {
      title {
        de
        en
      }
    }
  }
`;

export const DELETE_EVENT = `
  mutation DeleteEvent($entityId: ID) {
    deleteEvent(entityId: $entityId) {
      title {
        en
        de
      }
    }
  }
`;

export const UPDATE_EVENT = `
  mutation UpdateEvent($entityId: ID, $input: EventInputType) {
    updateEvent(entityId: $entityId, input: $input) {
      title {
        en
        de
      }
    }
  }
`;

export const CREATE_MESSAGE = `
  mutation CreateMesssage($eventId: ID, $input: MessageInputType) {
    createMessage(eventId: $eventId, input: $input) {
      body
      data
    }
  }
`;

export const DELETE_MESSAGE = `
  mutation DeleteMessage($entityId: ID) {
    deleteMessage(entityId: $entityId) {
      data
    }
  }
`;

export const CREATE_REPLY = `
  mutation CreateMesssage($messageId: ID, $input: MessageInputType) {
    createReply(messageId: $messageId, input: $input) {
      body
      data
      entityId
    }
  }
`;

export const TOGGLE_EVENT_ACTIVATION = `
  mutation Active ($entityId: ID, $active: Boolean) {
    updateEventActivation(entityId: $entityId, active: $active) {
      entityId
      active
    }
  }
`;

export const SYNC_LEGACY_USERS = `
  mutation CreateUserCopy($input: UserInput) {
    createUserCopy(input: $input) {
      email
    }
  }

`;

export const CHANGE_USER_IMAGE = `
  mutation ChangeUserImage($entityId: ID, $image: String) {
    changeUserImage(entityId: $entityId, image: $image) {
      image
    }
  }
`;

export const CHANGE_USER_DETAILS = `
  mutation ChangeDetails($entityId: ID, $input: UserDetailsInput) {
    changeUserDetails(entityId: $entityId, input: $input) {
      email
    }
  }
`;

export const ADD_MODERATOR = `
  mutation AddModerator($eventId: ID, $userId: ID) {
    addModeratorToEvent(eventId: $eventId, userId: $userId) {
      moderators {
        email
      }
    }
  }
`;

export const ADD_USER_TO_POOL = `
  mutation AddUserToPool($userId: ID, $groupName: ID) {
    addUserToPool(userId: $userId, groupName: $groupName) {
      entityId
    }
  }
`;

export const REMOVE_USER_FROM_POOL = `
  mutation RemoveUser($userId: ID, $groupName: ID) {
    removeUserFromPool(userId: $userId, groupName: $groupName) {
      entityId
    }
  }
`;

export const REMOVE_MODERATOR_FROM_EVENT = `
  mutation removeModerator($userId: ID, $eventId: ID) {
    removeModerator(userId: $userId, eventId: $eventId){
      entityId
    }
  }
`;

export const FLAG_MESSAGE = `
  mutation Flag($entityId: ID, $flag: Boolean) {
    flagMessage(entityId: $entityId, flag: $flag) {
      body
      flag
      data
    }
  }
`;

export const ADD_LIKE_TO_USER = `
  mutation AddLike($userId: ID, $input: LikeInputType) {
    addLikeToUser(userId: $userId, input: $input) {
      email
    }
  }
`;

export const REMOVE_LIKE = `
  mutation removeLike($userId: ID, $index: Int) {
    removeLike(userId: $userId, index: $index) {
      email
    }
  }
`;

export const REMOVE_MESSAGE_LIKE = `
  mutation removeMessageLike($userId: ID, $index: Int) {
    removeMessageLike(userId: $userId, index: $index) {
      email
    }
  }
`;

export const SET_SORT = `
  mutation setSort($entityId: ID, $sort: Int) {
    setSort(entityId: $entityId, sort: $sort) {
      entityId
      sort
    }
  }
`;

export const SET_STREAM = `
  mutation setStream($entityId: ID, $stream: String, $comments: Boolean) {
    updateStream(entityId: $entityId, stream: $stream, comments: $comments) {
      entityId
      comments
    }
  }
`;

export const ADD_ONLINE_STATUS = `
  mutation AddOnlineStatus($eventId: ID, $count: Int) {
    addOnlineStatus(eventId: $eventId, count: $count) {
      title {
        de
        en
      }
      entityId
      online
    }
  }
`;

export const CHANGE_REPLY_STATUS = `
  mutation ChangeReplyStatus($entityId: ID, $replied: Boolean) {
    changeReplyStatus(entityId: $entityId, replied: $replied) {
      entityId
      data
      replied
    }
  }
`;

export const PUT_ERROR = `
  mutation PutError($errorInfo: String, $errorLocation: String){
    putError(errorInfo: $errorInfo, errorLocation: $errorLocation) {
      errorInfo
    }
  }
`;

export const ADD_MESSAGE_LIKE = `
  mutation addMessageLike($userId: ID, $input: MessageInputType) {
    addMessageLikeToUser(userId: $userId, input: $input) {
      entityId
      messageLikes {
        entityId
        body
      }
    }
  }
`;

export const START_CONFERENCE = `
  mutation startConference($eventId: ID, $userId: String, $userType: String, $eventName: String) {
    startConference(eventId: $eventId, userId: $userId, userType: $userType, eventName: $eventName) {
      url
      error
    }
  }
`;
